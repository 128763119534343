import React, { useEffect, useRef, useState } from "react";
import { Button, Box, Typography, Chip } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { IMAGE_BASE } from "../../config";
import BuildCartState from "./BuildCartState";
import { gsap } from "gsap";

const BulkOrderNavigation = ({
  bulkData,
  selectedBrand,
  setSelectedBrand,
  selectedFilters,
  setSelectedFilters,
}) => {
  const brands = bulkData?.Brand || [];
  const [isFilterOpen, setIsFilterOpen] = useState(false); // Controls the filter box visibility
  const [categorizedData, setCategorizedData] = useState({
    Parts: [],
    Accessories: [],
    Maintenance: [],
    Bikes: [],
  });

  const filterRef = useRef(null); // Reference for the filter dropdown
  const progressBarRef = useRef(null); // Reference for the progress bar

  // GSAP Animations: Animate filter dropdown visibility
  useEffect(() => {
    if (isFilterOpen) {
      gsap.to(filterRef.current, {
        duration: 0.5,
        opacity: 1,
        y: 0,
        ease: "power3.out",
        display: "flex",
      });
    } else {
      gsap.to(filterRef.current, {
        duration: 0.3,
        opacity: 0,
        y: -20,
        ease: "power3.in",
        display: "none",
      });
    }
  }, [isFilterOpen]);

   // GSAP Animations: Animate progress bar
   useEffect(() => {
    if (progressBarRef.current) {
      gsap.fromTo(
        progressBarRef.current.children,
        { scaleX: 0, transformOrigin: "left center", opacity: 1 },
        {
          scaleX: 1,
          duration: 1,
          stagger: 0.2, // Adds delay between segments
          ease: "power3.out",
        }
      );
    }
  }, [categorizedData]);

  // Categorize data into parts, accessories, maintenance, and bikes
  useEffect(() => {
    if (bulkData?.SubCategories) {
      const categories = {
        Parts: [],
        Accessories: [],
        Maintenance: [],
        Bikes: [],
      };

      // Categorize the filter data
      bulkData?.SubCategories?.forEach((item) => {
        if (item?.verticalID === 1) {
          categories.Parts.push(item);
        } else if (item?.verticalID === 2) {
          categories.Accessories.push(item);
        } else if (item?.verticalID === 3) {
          categories.Maintenance.push(item);
        } else if (item?.verticalID === 4) {
          categories.Bikes.push(item);
        }
      });

      // Set the categorized data
      setCategorizedData(categories);
    }
  }, [bulkData?.SubCategories]); // Only run this effect when bulkData?.Subcategorie changes

  // Toggles filter visibility
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  // Handles selecting/deselecting filters
  const handleFilterSelect = (filter) => {
    // Check if the filter is already selected by its `sc_id`
    const isSelected = selectedFilters.some(
      (item) => item.sc_id === filter.sc_id
    );

    if (isSelected) {
      // Remove filter from selectedFilters if already selected
      setSelectedFilters((prev) =>
        prev.filter((item) => item.sc_id !== filter.sc_id)
      );
    } else {
      // Add the filter to selectedFilters
      setSelectedFilters((prev) => [...prev, filter]);
    }
  };

  const cartTotal = 3000;
  const MOQPrice = 5000;
  const discountPrice = 10000;

  // Define the data for each state
  const MOQState = {
    type: "MOQ",
    state: cartTotal >= MOQPrice ? "done" : "processing",
    level1:
      cartTotal >= MOQPrice
        ? "MOQ Met for this order"
        : "Minimum Order Quantity",
    level2:
      cartTotal >= MOQPrice
        ? ""
        : `Add items worth ₹${MOQPrice - cartTotal} to meet MOQ`,
  };

  const DiscountState = {
    type: "Discount",
    state: cartTotal >= discountPrice ? "done" : "processing",
    level1:
      cartTotal >= discountPrice
        ? "Congratulations, you saved ₹2,000 in your cart value"
        : "Unlock Amazing Discounts",
    level2:
      cartTotal >= discountPrice
        ? ""
        : `Add items worth ₹${
            discountPrice - cartTotal
          } to Save 10% on your order`,
  };

  // Determine the progress for MOQ and discount
  const BeforeMOQ = Math.min((cartTotal / MOQPrice) * 3, 3);
  const MOQProgress = Math.min((cartTotal / discountPrice) * 3, 3); // Divide into 3 sections
  const DiscountProgress = Math.min(
    ((cartTotal - discountPrice) / (discountPrice * 2)) * 3,
    3
  ); // Divide into 3 sections

  // Helper function to get the bar color
  const getBarColor = (index, progress, color) => {
    if (color === "gray") {
      return index < progress ? "bg-blue" : "bg-blue-200";
    } else if (color === "peach") {
      return index < progress ? "bg-peach" : "bg-red-200";
    } else {
      return index < progress ? "bg-green" : "bg-green-200";
    }
  };

  return (
    <div className="w-full flex flex-col justify-between items-center py-4 bg-white-1">
      <div className="w-full flex items-center justify-between mb-4 pb-4 border-b border-gray-200 px-8">
        <div className="flex flex-col items-start justify-center">
          <span className="text-blue tracking-wide mb-3 font-bold">
            Shop By Brand
          </span>
          <div className="flex space-x-4">
            {/* Add "All" option */}
            <Button
              key="all-brands"
              variant="outlined"
              onClick={() => setSelectedBrand("All")} // Set selected brand to "All"
              style={{
                borderColor:
                  selectedBrand === "All" ? "green" : "var(--color-blue)",
                color: selectedBrand === "All" ? "green" : "var(--color-blue)",
                backgroundColor:
                  selectedBrand === "All"
                    ? "rgba(0, 128, 0, 0.1)"
                    : "transparent",
                borderRadius: "20px",
                textTransform: "capitalize",
                height: "28px",
                fontSize: "13px",
                whiteSpace: "nowrap",
                letterSpacing: "0.5px",
              }}
            >
              All
            </Button>

            {/* Render brand options */}
            {brands?.map((data) => (
              <Button
                key={data?.manufacture_name}
                variant="outlined"
                onClick={() => setSelectedBrand(data?.manufacture_name)} // Set selected brand
                style={{
                  borderColor:
                    selectedBrand === data?.manufacture_name
                      ? "green"
                      : "var(--color-blue)",
                  color:
                    selectedBrand === data?.manufacture_name
                      ? "green"
                      : "var(--color-blue)",
                  backgroundColor:
                    selectedBrand === data?.manufacture_name
                      ? "rgba(0, 128, 0, 0.1)"
                      : "transparent",
                  borderRadius: "20px",
                  textTransform: "capitalize",
                  height: "28px",
                  fontSize: "13px",
                  whiteSpace: "nowrap",
                  letterSpacing: "0.5px",
                }}
              >
                <img
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${data?.manufacture_images}`}
                  alt={data?.manufacture_name}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                    padding: "1px",
                  }}
                />
              </Button>
            ))}
          </div>
        </div>

        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "500px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              variant="outlined"
              onClick={toggleFilter}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderColor: "var(--color-blue)",
                color: "var(--color-blue)",
                height: "35px",
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
                paddingY: "0",
                gap: 0.5,
                minWidth: "fit-content !important",
              }}
            >
              <FilterAltIcon
                className="text-blue"
                style={{ fontSize: "18px" }}
              />

              {selectedFilters.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    height: "35px",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  Filtered By
                  <Box
                    sx={{
                      backgroundColor: "var(--color-blue)",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "bold",
                      borderRadius: "50%",
                      width: "18px",
                      height: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {selectedFilters?.length}
                  </Box>
                </Box>
              ) : (
                <Box>Filters</Box>
              )}
            </Button>

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexWrap: "wrap",
                marginTop: "2px",
              }}
            >
              {selectedFilters?.map((filter, index) => (
                <Chip
                  key={index}
                  label={filter?.SubcategorieName}
                  onDelete={() => handleFilterSelect(filter)} // Remove on delete
                  sx={{
                    backgroundColor: "#f0f0f0",
                    fontWeight: "bold",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                />
              ))}
            </Box>
          </Box>

          {isFilterOpen && (
            <Box
            ref={filterRef}
              sx={{
                position: "absolute",
                top: "calc(100% + 10px)",
                right: 0,
                zIndex: 1,
                width: "550px",
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
                padding: 2,
                border: "1px solid #ddd",
                borderRadius: "8px",
                backgroundColor: "#ffffff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {Object.keys(categorizedData)?.map((category, index) => {
                const categoryItems = categorizedData[category];
                if (!categoryItems?.length) return null; // Skip rendering if no items for this category

                return (
                  <Box key={index} sx={{ flex: 1 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", marginBottom: 1 }}
                      color={
                        category === "Parts"
                          ? "var(--color-blue)"
                          : category === "Accessories"
                          ? "var(--color-green)"
                          : category === "Maintenance"
                          ? "var(--color-peach)"
                          : "var(--color-purple)"
                      }
                    >
                      {category}
                    </Typography>

                    {categoryItems?.map((item, idx) => (
                      <Typography
                        key={idx}
                        variant="body2"
                        sx={{
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          color: selectedFilters.some(
                            (filter) => filter.sc_id === item.sc_id
                          )
                            ? "var(--color-blue)"
                            : "gray",
                          fontWeight: selectedFilters.some(
                            (filter) => filter.sc_id === item.sc_id
                          )
                            ? "700"
                            : "500",
                          "&:hover": { textDecoration: "underline" },
                        }}
                        onClick={() => handleFilterSelect(item)}
                      >
                        {item?.SubcategorieName}{" "}
                        {/* Render the SubcategorieName */}
                      </Typography>
                    ))}
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </div>
      <div className="w-full h-[100px] flex flex-col items-start px-8">
        <span className="text-black-1 font-bold text-[16px] mb-3">
          Build Your Cart
        </span>
        <div ref={progressBarRef} className="relative w-full">
          {/* Lines Section */}
          <div className="flex w-full items-center">
            {/* Red Section */}
            <div className="w-1/3 flex items-center relative">
              {/* Red Bars */}
              {[...Array(3)].map((_, index) => (
                <div
                  key={`moq-bar-${index}`}
                  className={`h-[6px] w-1/3 ${getBarColor(
                    index,
                    BeforeMOQ,
                    "peach"
                  )}`}
                  style={{
                    clipPath: "polygon(2% 0, 100% 0, 98% 100%, 0 100%)",
                  }}
                ></div>
              ))}
            </div>

            {/* Gray Section */}
            <div className="w-1/3 flex items-center">
              {/* Label for MOQ Met */}
              <div className="w-[6px] h-[6px] relative">
                <BuildCartState {...MOQState} />
              </div>

              {/* Gray Bars */}
              {[...Array(3)].map((_, index) => (
                <div
                  key={`moq-bar-${index}`}
                  className={`h-[6px] w-1/3 ${getBarColor(
                    index,
                    MOQProgress,
                    "gray"
                  )}`}
                  style={{
                    clipPath: "polygon(2% 0, 100% 0, 98% 100%, 0 100%)",
                  }}
                ></div>
              ))}
            </div>

            {/* Green Section */}
            <div className="w-1/3 flex items-center relative">
              <div className="w-[6px] h-[6px] relative">
                <BuildCartState {...DiscountState} />
              </div>

              {/* Green Bars */}
              {[...Array(3)].map((_, index) => (
                <div
                  key={`discount-bar-${index}`}
                  className={`h-[6px] w-1/3 ${getBarColor(
                    index,
                    DiscountProgress,
                    "green"
                  )}`}
                  style={{
                    clipPath: "polygon(2% 0, 100% 0, 98% 100%, 0 100%)",
                  }}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkOrderNavigation;
