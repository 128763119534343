import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  calculateUpdatedDiscountPercentage,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import { IMAGE_BASE } from "../../config";
import { useDispatch } from "react-redux";
import {
  addProductInCollectionAsync,
} from "../../features/admin/adminSlice";
import { getCollectionAsync } from "../../features/listingPage/listingPageSlice";

const CollectionTable = ({
  prod,
  paginatedInventory,
  searchQuery,
  setSearchQuery,
  currentCollectionId,
  handleOpen,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const [inventory, setInventory] = useState(paginatedInventory || []);


  // Reload table data when `prod` or `paginatedInventory` changes
  useEffect(() => {
    setInventory(paginatedInventory);
  }, [prod, paginatedInventory]);



  const handleSelectProduct = (prod_ID) => {
    handleOpen();
    let collectionData = {
      collections_Id: currentCollectionId,
      prod_ID: prod_ID,
    };
    dispatch(addProductInCollectionAsync(collectionData)).then(() => {
      dispatch(getCollectionAsync(currentCollectionId));
      handleClose();
    });
  };
  return (
    <div className="mb-6">
      {/* Table Header Section */}
      <div className="w-full bg-black text-white bg-gray-800 flex flex-col lg:flex-row items-center justify-between px-4 py-3 rounded-t-md gap-3">
        <span className="text-lg font-semibold text-center lg:text-left">
          Collection Table
        </span>
        <div className="h-[38px] bg-white-1 rounded-full flex items-center justify-between p-1 w-full lg:w-auto">
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value.trimStart())}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px",
                backgroundColor: "white",
                fontWeight: "600",
                fontSize: "14px",
                fontFamily: "inherit",
                letterSpacing: "1px",
                width: "100%",
                maxWidth: "250px",
                height: "30px",
                "& fieldset": {
                  border: "none", // Removes the border
                },
              },
              "& .MuiOutlinedInput-input": {
                padding: "4px 8px", // Adjust padding for better alignment
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontFamily: "inherit",
              },
            }}
          />
          <SearchIcon
            className="bg-green rounded-full p-1 ml-2 cursor-pointer"
            style={{ fontSize: "30px" }}
          />
        </div>
      </div>

      {/* Table */}
      <TableContainer className="bg-white rounded-b-md shadow-md">
        <Table>
          {/* Table Head */}
          <TableHead>
            <TableRow className="">
              <TableCell
                align="center"
                className="font-semibold"
                sx={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "700",
                  letterSpacing: "0.5px",
                }}
              >
                <CheckBoxOutlineBlankIcon style={{ fontSize: "28px" }} />
              </TableCell>
              <TableCell
                align="center"
                className="font-semibold"
                sx={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "15px",
                  letterSpacing: "0.5px",
                }}
              >
                Product Img
              </TableCell>
              <TableCell
                align="center"
                className="font-semibold"
                sx={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "15px",
                  letterSpacing: "0.5px",
                }}
              >
                Product Title
              </TableCell>
              <TableCell
                align="center"
                className="font-semibold"
                sx={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "15px",
                  letterSpacing: "0.5px",
                }}
              >
                MRP
              </TableCell>
              <TableCell
                align="center"
                className="font-semibold"
                sx={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "15px",
                  letterSpacing: "0.5px",
                }}
              >
                Dealer Price
              </TableCell>
              <TableCell
                align="center"
                className="font-semibold"
                sx={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "15px",
                  letterSpacing: "0.5px",
                }}
              >
                Dealer Margin
              </TableCell>
              <TableCell
                align="center"
                className="font-semibold"
                sx={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "15px",
                  letterSpacing: "0.5px",
                }}
              >
                In Stock
              </TableCell>
              {/* <TableCell
                align="center"
                className="font-semibold"
                sx={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "15px",
                  letterSpacing: "0.5px",
                }}
              >
                Orders(Dec)
              </TableCell> */}
              <TableCell
                align="center"
                className="font-semibold"
                sx={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "15px",
                  letterSpacing: "0.5px",
                }}
              >
                Total Order
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {inventory?.map((product) => (
              <TableRow
                key={product?.product?.prod_ID}
                className="hover:bg-gray-100"
              >
                <TableCell align="center">
                  <input
                    type="checkbox"
                    className="w-[18px] h-[18px] cursor-pointer"
                    checked={prod?.some(
                      (selected) =>
                        selected?.prod_ID === product?.product?.prod_ID
                    )}
                    onChange={() =>
                      handleSelectProduct(product?.product?.prod_ID)
                    }
                  />
                </TableCell>
                <TableCell>
                  <img
                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                      product?.product?.image && product?.product?.image
                    }`}
                    alt={"N/A"}
                    className="w-[100px] h-[80px] object-contain border-[1px] border-black-1/10 rounded-md"
                  />
                </TableCell>
                <TableCell align="center" className="max-w-[200px]">
                  {product?.product?.prod_name || "N/A"}
                </TableCell>
                <TableCell align="center">
                  {(product?.skus &&
                    product?.skus[0]?.MRP_price &&
                    formatWithCommasWithDecimal(
                      product?.skus && product?.skus[0]?.MRP_price
                    )) ||
                    "N/A"}
                </TableCell>
                <TableCell align="center">
                  {(product?.skus &&
                    product?.skus[0]?.NDP_price &&
                    formatWithCommasWithDecimal(
                        parseInt(product?.skus && product?.skus[0]?.NDP_price)
                    )) ||
                    "N/A"}
                </TableCell>
                <TableCell align="center">
                  {parseInt(
                    calculateUpdatedDiscountPercentage(
                      product?.skus && product?.skus[0]?.MRP_price,
                      parseInt(product?.skus && product?.skus[0]?.NDP_price),
                      product?.product && product?.product?.GST
                    )
                  ) || "N/A"}
                  %
                </TableCell>
                <TableCell align="center">
                  {product?.skus && product?.skus[0]?.qty >= 0
                    ? product?.skus[0]?.qty
                    : "N/A"}
                </TableCell>
                {/* <TableCell align="center">{product?.order || "N/A"}</TableCell> */}
                <TableCell align="center">
                  {product?.sale_quantity >= 0 ? product?.sale_quantity : "N/A"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CollectionTable;
