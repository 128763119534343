import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  TextField,
  Box,
  Typography,
  InputAdornment,
  Divider,
} from "@mui/material";
import { gsap } from "gsap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Confetti from "react-confetti";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getBulkOrderUserOTPAsync,
  verifyBulkOrderOTPAsync,
} from "../../features/bulkOrder/bulkOrderSlice";
import { useDispatch } from "react-redux";
import PhoneIcon from "@mui/icons-material/Phone";
import KeyIcon from "@mui/icons-material/Key";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import promises1 from "../../img/banners/promise-1.png";
import promises2 from "../../img/banners/promise-2.png";
import promises3 from "../../img/banners/promise-3.png";
import promises4 from "../../img/banners/promise-4.png";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";

const MobileNumberAuth = ({ onAuthenticate }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1); // Step 1: Mobile number input, Step 2: OTP input
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const dialogRef = useRef(null);
  const mobileInputRef = useRef(null);
  const otpInputRef = useRef(null);

  // Automatically focus the mobile number field when the component loads
  useEffect(() => {
    if (mobileInputRef.current) {
      mobileInputRef.current.focus();
    }
  }, []);

  // GSAP Animation after the DOM is fully loaded
  useLayoutEffect(() => {
    if (dialogRef.current) {
      gsap.fromTo(
        dialogRef.current,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 1.5, ease: "power3.out" }
      );
    }
  }, []); // Runs only on the initial mount

  const handleMobileSubmit = async () => {
    if (phoneNumber?.length === 10) {
      setIsLoading(true);
      setError("");
      try {
        const response = await dispatch(getBulkOrderUserOTPAsync(phoneNumber));
        if (response?.payload?.message === "OTP sent successfully") {
          setStep(2); // Proceed to OTP verification
          setError("");
          toast.success(response?.payload?.message);
          if (otpInputRef.current) {
            otpInputRef.current.focus();
          }
        } else {
          setError(response?.payload?.message);
          toast.error(response?.payload?.message);
        }
      } catch (error) {
        toast.error("Failed to send OTP. Please try again.");
      } finally {
        setIsLoading(false);
      }
    } else {
      setError("Please enter a valid 10-digit mobile number.");
    }
  };

  const handleOtpSubmit = async () => {
    try {
      setIsLoading(true);
      setError("");

      let otpData = {
        PhoneNumber: phoneNumber,
        OTP: otp,
      };
      const isValid = await dispatch(verifyBulkOrderOTPAsync(otpData)); // Call the OTP verification API
      if (isValid?.payload?.message === "OTP verified successfully") {
        setIsAuthenticated(true); // Trigger confetti
        onAuthenticate(); // Call parent function to proceed
        setError("");
        toast.success(isValid?.payload?.message);
      } else {
        setError(isValid?.payload?.message);
        toast.error(isValid?.payload?.message);
      }
    } catch (error) {
      toast.error("Failed to verify OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open fullScreen>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url('https://png.pngtree.com/background/20250105/original/pngtree-bicycle-spare-parts-and-accessories-picture-image_15450424.jpg')`,
          backgroundSize: "cover",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          backgroundBlendMode: "overlay",
          padding: 4,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            zIndex: 1,
          }}
        />
        {/* CYCLECIRCLE LOGO*/}
        <Box
          sx={{ position: "absolute", top: "20px", left: "20px", zIndex: 2 }}
        >
          <LazyLoadImage
            src="https://beta.cyclecircle.one/static/media/Cycle-Circle-Logo-Orange-1.18b0a8525b53bce0ebd4.png"
            alt="Cyclecircle Logo"
            effect="blur"
            style={{ width: 80 }}
          />
        </Box>

        {/* Left Side Content */}
        <Box
          sx={{
            width: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              flex: 1,
              width: "100%",
              maxWidth: "450px",
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", marginBottom: 2 }}
            >
              Your Ultimate Gateway to Seamless Bulk Ordering
            </Typography>
            <Typography
              sx={{
                marginBottom: 2,
                fontSize: "15px",
                fontWeight: "500",
                letterSpacing: "1px",
                fontFamily: "Raleway",
              }}
            >
              <CheckCircleIcon
                style={{ fontSize: "20px" }}
                className="text-green mr-1"
              />{" "}
              Enjoy Unmatched Discount Slabs
            </Typography>
            <Typography
              sx={{
                marginBottom: 2,
                fontSize: "15px",
                fontWeight: "500",
                letterSpacing: "1px",
                fontFamily: "Raleway",
              }}
            >
              <CheckCircleIcon
                style={{ fontSize: "20px" }}
                className="text-green mr-1"
              />{" "}
              Bulk Customization Options
            </Typography>
            <Typography
              sx={{
                marginBottom: 2,
                fontSize: "15px",
                fontWeight: "500",
                letterSpacing: "1px",
                fontFamily: "Raleway",
              }}
            >
              <CheckCircleIcon
                style={{ fontSize: "20px" }}
                className="text-green mr-1"
              />{" "}
              Explore a Wide Range of Renowned Brands
            </Typography>
            <Typography
              sx={{
                marginBottom: 2,
                fontSize: "15px",
                fontWeight: "500",
                letterSpacing: "1px",
                fontFamily: "Raleway",
              }}
            >
              <CheckCircleIcon
                style={{ fontSize: "20px" }}
                className="text-green mr-1"
              />{" "}
              Find Everything for Your Business Needs
            </Typography>
            <Box
              sx={{
                width: "100%", // Make it responsive
                maxWidth: "450px",
                backgroundColor: "white",
                borderRadius: "6px",
                padding: "10px", // Increase padding for better spacing
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add subtle shadow for better appearance
                gap: "10px",
                height: "content-fit",
                marginTop: "20px",
              }}
            >
              {[
                // Dynamically map for consistency and scalability
                { img: promises1, text: "Free Delivery" },
                { img: promises4, text: "Quick Tracking Info" },
                { img: promises2, text: "Hassle Free Returns" },
                { img: promises3, text: "Express Delivery" },
              ].map((promise, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "25%",
                    height: "50%",
                    padding: "0 10px",
                  }}
                >
                  <Box
                    component="img"
                    src={promise.img}
                    alt={`promise-${index + 1}`}
                    sx={{
                      width: "30px",
                      height: "30px",
                      objectFit: "contain",
                      marginBottom: "8px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      height: "50%",
                      fontWeight: "600",
                      color: "#333",
                      textAlign: "center",
                    }}
                  >
                    {promise.text}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        {isAuthenticated && <Confetti />}
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            zIndex: 2,
          }}
        >
          <Box
            ref={dialogRef}
            sx={{
              maxWidth: 400,
              width: 400,
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              borderRadius: 4,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              padding: 3,
              margin: "auto",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                color: "white",
                fontWeight: "bold",
                marginBottom: 3,
                textDecoration: "underline",
                textUnderlineOffset: "5px",
                letterSpacing: "1px",
              }}
            >
              Login
            </Typography>

            {step === 1 ? (
              <Box>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  variant="outlined"
                  value={phoneNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 10) {
                      setPhoneNumber(value);
                    }
                  }}
                  inputRef={mobileInputRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon sx={{ color: "white" }} />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { color: "white" },
                  }}
                  error={!!error}
                  helperText={error}
                  sx={{
                    backgroundColor: "transparent",
                    outline: "none",
                    marginBottom: 2,
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                      "& input": {
                        backgroundColor: "transparent",
                      },
                    },
                    "& ::selection": {
                      backgroundColor: "transparent",
                      color: "white",
                    },
                  }}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleMobileSubmit}
                  disabled={isLoading}
                  sx={{
                    background: "linear-gradient(to right, #9c27b0, #7b1fa2)",
                    height: 45,
                    borderRadius: "25px",
                    fontWeight: "bold",
                    "&:hover": {
                      background: "linear-gradient(to right, #7b1fa2, #6a1b9a)",
                    },
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size={24} sx={{ color: "#fff" }} />
                  ) : (
                    "Send OTP"
                  )}
                </Button>
              </Box>
            ) : (
              <Box>
                <TextField
                  fullWidth
                  label="OTP"
                  variant="outlined"
                  value={otp}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 6) {
                      setOtp(value);
                    }
                  }}
                  inputRef={otpInputRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon sx={{ color: "white" }} />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { color: "white" },
                  }}
                  error={!!error}
                  helperText={error}
                  sx={{
                    backgroundColor: "transparent",
                    outline: "none",
                    marginBottom: 2,
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                      "& input": {
                        backgroundColor: "transparent",
                      },
                    },
                    "& ::selection": {
                      backgroundColor: "transparent",
                      color: "white",
                    },
                  }}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleOtpSubmit}
                  disabled={isLoading}
                  sx={{
                    borderRadius:"25px",
                    background: "linear-gradient(to right, #9c27b0, #7b1fa2)", // Purple gradient
                    height: 45,
                    fontWeight: "bold",
                    "&:hover": {
                      background: "linear-gradient(to right, #7b1fa2, #6a1b9a)", // Darker purple on hover
                    },
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size={24} sx={{ color: "#fff" }} />
                  ) : (
                    "Verify OTP"
                  )}
                </Button>
                <Button
                  fullWidth
                  variant="text"
                  onClick={() => setStep(1)}
                  sx={{
                    marginTop: 2,
                    color: "#9c27b0",
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                >
                  Change Mobile Number
                </Button>
              </Box>
            )}
            <Box
              sx={{
                marginTop: 3,
                textAlign: "center",
                color: "white",
                fontSize: 14,
              }}
            >
              <Divider
                sx={{
                  borderColor: "rgba(255, 255, 255, 0.4)",
                  marginBottom: 2,
                }}
              />
              <Typography sx={{ marginBottom: 1, fontSize: "14px" }}>
                Having trouble? Feel free to reach us.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    color: "white",
                  }}
                >
                  <PhoneIcon sx={{ color: "white", fontSize: 18 }} />
                  <Typography sx={{ fontSize: "12px" }}>
                    +91-883947882
                  </Typography>
                </Box>
                <Typography sx={{ marginX: "5px", color: "gray" }}>
                  |
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    color: "white",
                  }}
                >
                  <EmailIcon sx={{ color: "white", fontSize: 18 }} />
                  <Typography sx={{ fontSize: "12px" }}>
                    support@cyclecircle.one
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: 12,
                  textDecoration: "underline",
                  textUnderlineOffset: "4px",
                  cursor: "pointer",
                }}
              >
                <Link to="/support">
                  Privacy Policy & Terms of Service apply.
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MobileNumberAuth;
