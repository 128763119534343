import React, { useEffect, useMemo, useState } from "react";
import CollectionName from "./CollectionName";
import SelectedItems from "./SelectedItems";
import CollectionTable from "./CollectionTable";
import CollectionPagination from "./CollectionPagination";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  fetchAllProductInventoryAsync,
  getCollectionsAsync,
  resetCollectionId,
  selectAllCollectionProduct,
} from "../../features/admin/adminSlice";
import { useSelector } from "react-redux";
import { resetCollectionData } from "../../features/listingPage/listingPageSlice";

const CreateCollection = ({
  setTab,
  userDetail,
  dispatch,
  handleOpen,
  handleClose,
  currentCollection,
  setCurrentCollection,
  currentCollectionId,
  setCurrentCollectionId,
  currentCollectionDetail,
  openCollection,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20; // Rows displayed per page
  const collectionProduct = useSelector(selectAllCollectionProduct);
  const [searchQuery, setSearchQuery] = useState("");
  const [paginatedInventory, setPaginatedInventory] = useState(null);

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(fetchAllProductInventoryAsync()).then(() => {
        handleClose();
      });
    }
  }, [dispatch, userDetail?.UserID]);

   // Use useMemo to stabilize filteredInventory
const filteredInventory = useMemo(() => {
    return collectionProduct
      ?.filter((product) =>
        product?.product?.prod_name
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase().trim())
      )
      ?.sort((a, b) =>
        a?.product?.prod_name?.localeCompare(b?.product?.prod_name)
      );
  }, [collectionProduct, searchQuery]);

  // Update paginatedInventory when filteredInventory is updated
  useEffect(() => {
    if (filteredInventory) {
      setPaginatedInventory(
        filteredInventory.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
      );
    }
  }, [filteredInventory, currentPage, rowsPerPage]);


  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const handlePageSelectChange = (event) => {
    setCurrentPage(event.target.value);
  };

  // Reset the collection ID and tab
  const tabRefresh = () => {
    setTab(null);
    dispatch(getCollectionsAsync());
    setCurrentCollectionId(null);
    dispatch(resetCollectionId());
    setCurrentCollection("");
    dispatch(resetCollectionData());
  };

  return (
    <>
      <div className="w-full max-md:px-2 max-md:py-3 px-4 md:px-6 lg:px-8 py-5 flex-grow">
        <span
          className="w-fit mb-4 flex items-center cursor-pointer text-black-1/90 text-[14px] font-bold tracking-wide"
          onClick={tabRefresh} // Use the updated tabRefresh function
        >
          <ArrowBackIosNewIcon
            className="text-black-1/90"
            style={{ fontSize: "16px" }}
          />
          &nbsp;Back
        </span>

        <CollectionName
          userDetail={userDetail}
          handleOpen={handleOpen}
          handleClose={handleClose}
          currentCollection={currentCollection}
          setCurrentCollection={setCurrentCollection}
          currentCollectionId={currentCollectionId}
          setCurrentCollectionId={setCurrentCollectionId}
          openCollection={openCollection}
        />

        <SelectedItems product={currentCollectionDetail?.Data?.product} />

        <CollectionTable
          prod={currentCollectionDetail?.Data?.product}
          paginatedInventory={
            Array.isArray(paginatedInventory) ? paginatedInventory : []
          }
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          currentCollectionId={currentCollectionId}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
      </div>

      <CollectionPagination
        filteredInventory={filteredInventory}
        currentPage={currentPage}
        handlePageSelectChange={handlePageSelectChange}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
      />
    </>
  );
};

export default CreateCollection;
