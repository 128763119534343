import { combineReducers } from "redux";
import listingPageFilterReducer from "../slices/listingPageFilterSlice";
import priceRangeReducer from "../slices/priceRangeSlice";
import userReducer from "../slices/userSlice";
import headerReducer from "../slices/headerSlice";
import mobileReducer from "../slices/bannerSlice";
import hotDealsReducer from "../slices/hotDealsSlice";
import breadCrumbsReducer from "../slices/breadCrumbsSlice";
import colorReducer from "../slices/colorSlice";
import productReducer from "../../features/listingPage/listingPageSlice";
import authReducer from "../../features/auth/authSlice";
import productDetailReducer from "../../features/productDesc/productDescSlice";
import filterReducer from "../slices/allFilterSlice";
import wishlistReducer from "../../features/wishlist/wishlistSlice";
import selectReducer from "../slices/selectVarient";
import reviewReducer from "../../features/productDesc/reviewSlice";
import cartReducer from "../../features/cartPage/cartPageSlice";
import addressReducer from "../../features/cartPage/addressSlice";
import searchReducer from "../../features/search/searchSlice";
import supportReducer from "../slices/supportSlice";
import {
  navigationSlice,
  bannerSlice,
  promiseSlice,
  ourBrandSlice,
  inclusiveBrandSlice,
} from "../../features/HomePage/homeSlice";
import PromotionReducer from "../../features/HomePage/homeSlice";
import selectedSubcategoryReducer from "../slices/subCategorySlice";
import orderReducer from "../../features/cartPage/OrderSlice";
import adminReducer from "../../features/admin/adminSlice";
import walletReducer from "../../features/cartPage/walletSlice";
import creditReducer from "../../features/credit/creditSlice";
import bulkReducer from "../../features/bulkOrder/bulkOrderSlice";

export const rootReducer = combineReducers({
  listingPageFilter: listingPageFilterReducer,
  priceRange: priceRangeReducer,
  user: userReducer,
  header: headerReducer,
  isMobile: mobileReducer,
  hotDeals: hotDealsReducer,
  breadcrumbs: breadCrumbsReducer,
  color: colorReducer,
  product: productReducer,
  productDetail: productDetailReducer,
  navigation: navigationSlice.reducer,
  banner: bannerSlice.reducer,
  promise: promiseSlice.reducer,
  ourBrand: ourBrandSlice.reducer,
  inclusiveBrand: inclusiveBrandSlice.reducer,
  promotion: PromotionReducer,
  allFilter: filterReducer,
  selectedSubcategory: selectedSubcategoryReducer,
  auth: authReducer,
  wishlist: wishlistReducer,
  selectVarient: selectReducer,
  review: reviewReducer,
  cart: cartReducer,
  address: addressReducer,
  order: orderReducer,
  admin: adminReducer,
  wallet: walletReducer,
  search: searchReducer,
  selectedSupport: supportReducer,
  credit: creditReducer,
  bulk:bulkReducer,
});
