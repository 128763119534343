import { createSlice } from '@reduxjs/toolkit';

export const priceRangeSlice = createSlice({
  name: 'priceRange',
  initialState: {
    minValue: 500,
    maxValue: 30000,
    isDoorToDoorChecked:true,
  },
  reducers: {
    setMinValue: (state, action) => {
      state.minValue = action.payload;
    },
    setMaxValue: (state, action) => {
      state.maxValue = action.payload;
    },
    setIsDoorToDoorChecked: (state,action)=>{
      state.isDoorToDoorChecked = action.payload;
    }
  },
});

export const { setMinValue, setMaxValue,setIsDoorToDoorChecked } = priceRangeSlice.actions;

export default priceRangeSlice.reducer;
