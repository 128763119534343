import React, { useEffect, useState } from "react";
import "../../ProductDescription/AboutBrands.css";
import { useParams } from "react-router";
import Funn from "../../ProductDescription/BrandsDetails/Funn";
import Granite from "../../ProductDescription/BrandsDetails/Granite";
import Moon from "../../ProductDescription/BrandsDetails/Moon";
import Cratoni from "../../ProductDescription/BrandsDetails/Cratoni";
import Proride from "../../ProductDescription/BrandsDetails/Proride";
import BikePro from "../../ProductDescription/BrandsDetails/BikePro";
import Dass from "../../ProductDescription/BrandsDetails/Dass";
import SovereignSaddles from "../../ProductDescription/BrandsDetails/SovereignSaddles";
import MasterChains from "../../ProductDescription/BrandsDetails/MasterChains";
import Katour from "../../ProductDescription/BrandsDetails/Katour";
import GarrariBikes from "../../ProductDescription/BrandsDetails/GarrariBikes";

function BrandsData() {
  const [componentToRender, setComponentToRender] = useState(null);
  const brandName = useParams();

  useEffect(() => {
    switch (brandName?.brand) {
      case "funn":
        setComponentToRender(<Funn />);
        break;
      case "cratoni":
        setComponentToRender(<Cratoni />);
        break;
      case "granite":
        setComponentToRender(<Granite />);
        break;
      case "moon sports":
        setComponentToRender(<Moon />);
        break;
      case "proride":
        setComponentToRender(<Proride />);
        break;
      case "bikepro":
        setComponentToRender(<BikePro />);
        break;
      case "dass":
        setComponentToRender(<Dass />);
        break;
      case "sovereign":
        setComponentToRender(<SovereignSaddles />);
        break;
      case "master":
        setComponentToRender(<MasterChains />);
        break;
        case "katour":
        setComponentToRender(<Katour />);
        break;
        case "garrari bikes":
        setComponentToRender(<GarrariBikes />);
        break;
      default:
        return;
    }
  }, [brandName]);
  return (
    <div className="about_containers" style={{paddingTop:"0"}}>
      {/* <h3 className="about_heading">About Brands</h3> */}
      {componentToRender}
    </div>
  );
}

export default BrandsData;
