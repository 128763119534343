import { API_BASE_URL } from "../../config";

export function getWallet(userID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}wallet/balance/${userID}`);
    const data = await response.json();

    resolve({ data });
  });
}

export function debitWallet(walletData) {
  const UserID = walletData.UserID;
  const amount = walletData.amount;
  const orderNo = walletData.orderNo;
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}wallet/debit/${UserID}/${amount}/${orderNo}`
    );
    const data = await response.json();

    resolve({ data });
  });
}
export function fetchCoupon() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}wallet/fetch_coupons`);
    const data = await response.json();

    resolve({ data });
  });
}

export function validCouponForUser(couponData) {
  const userID = couponData.userID;
  const coupon = couponData.coupon;
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}wallet/coupon/${userID}/${coupon}`
    );
    const data = await response.json();

    resolve({ data });
  });
}

export function ccHundred(userID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}wallet/operation_team/${userID}`
    );
    const data = await response.json();

    resolve({ data });
  });
}

export function tenPercentageDiscount(userID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}wallet/ten_percent/${userID}`);
    const data = await response.json();

    resolve({ data });
  });
}

export function premiumUser(userID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}wallet/premium_user/${userID}`
    );
    const data = await response.json();

    resolve({ data });
  });
}
export function applyCoupon(couponData) {
  // console.log(couponData);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}wallet/apply_coupon`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(couponData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getWalletDetail(userID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}wallet/details/${userID}`);
    const data = await response.json();

    resolve({ data });
  });
}
