import React from 'react';
import "./GarrariBikes.css";

function GarrariBikes() {
  return (
    <div className="garrari-container">
      <div className="garrari-brand-info">
        <p>
          <strong>Gararri Bikes</strong> is where passion meets precision in the world of cycling. 
          Renowned for top-quality bikes and exceptional service, we strive to provide cyclists with 
          the ultimate riding experience.
        </p>
      </div>
      <div className="garrari-highlights">
        <h2>Why Choose Gararri Bikes?</h2>
        <ul>
          <li><strong>Uncompromising Quality:</strong> From cutting-edge designs to meticulous craftsmanship, our bikes are engineered for performance and durability.</li>
          <li><strong>Diverse Range of Models:</strong> Whether you're a road cyclist, mountain biking enthusiast, daily commuter, or eco-conscious rider, we have the perfect bike for you.</li>
          <li><strong>Innovative Technology:</strong> Features like smart connectivity, electric-assist options, and advanced engineering make your ride exceptional.</li>
          <li><strong>Personalized Customer Service:</strong> Our knowledgeable team helps you choose the ideal bike for your needs and preferences.</li>
          <li><strong>Easy Online Shopping Experience:</strong> Hassle-free browsing and secure transactions with efficient delivery.</li>
          <li><strong>Sustainable and Eco-Friendly Practices:</strong> We contribute to a greener planet with eco-friendly models and packaging options.</li>
          <li><strong>Community and Events:</strong> Join a vibrant community of cyclists and participate in exciting events.</li>
          <li><strong>Warranty and After-Sales Support:</strong> Enjoy peace of mind with our warranty and dedicated support team.</li>
          <li><strong>Reviews and Testimonials:</strong> Explore positive feedback from satisfied customers who love Gararri Bikes.</li>
          <li><strong>Embrace the Gararri Lifestyle:</strong> Celebrate the joy of cycling, adventure, and freedom with Gararri Bikes.</li>
        </ul>
      </div>
    </div>
  );
}

export default GarrariBikes;