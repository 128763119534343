import React, { useState } from "react";
import MobileNumberAuth from "./MobileNumberAuth";
import BulkOrders from "./BulkOrders";

const BulkOrderWithAuth = () => {
  const [authenticated, setAuthenticated] = useState(false);

  const handleAuthentication = () => {
    setAuthenticated(true); // User authenticated, show the BulkOrders component
  };

  return (
    <>
      {!authenticated ? (
        <MobileNumberAuth onAuthenticate={handleAuthentication} />
      ) : (
        <BulkOrders />
      )}
    </>
  );
};

export default BulkOrderWithAuth;
