import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";
import {
  Button,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
    createCouponAsync,
  fetchAllCouponAsync,
  selectAllCoupons,
  toggleCouponAsync,
} from "../../features/admin/adminSlice";
import toast from "react-hot-toast";
const AdminCoupons = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const userDetail = useSelector(selectUser);
  const coupons = useSelector(selectAllCoupons);

  const [couponCode, setCouponCode] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [maxUsage, setMaxUsage] = useState("");

  const handleSubmit = (e) => {
    handleOpen();
    e.preventDefault();
    let couponData = {
        code:couponCode,
        discount_percentage:discountPercentage,
        max_usage:maxUsage
    }
    dispatch(createCouponAsync(couponData)).then(()=>{
        dispatch(fetchAllCouponAsync());
        handleClose();
        toast.success("Create coupon successfully")
    })
  };

  useEffect(() => {
    handleOpen();
    dispatch(fetchAllCouponAsync()).then(()=>{
        handleClose();
    })
  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  const handleToggleCoupon = (id) => {
    handleOpen();
    dispatch(toggleCouponAsync(id))
      .then(() => {
        dispatch(fetchAllCouponAsync());
      })
      .then(() => {
        toast.success("Update coupon successfully")
        handleClose();
      });
  };

  return (
    <div className="w-full min-h-screen bg-gray-50 relative flex flex-col">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="w-full h-14 bg-white-1 flex items-center justify-between sticky top-0 py-1 px-5
       shadow-md z-10 max-sm:hidden"
      >
        <span className="text-lg font-bold tracking-wider text-gray-800">
          Coupons
        </span>
        <img
          loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="w-10 h-10 rounded-full border border-gray-300"
        />
      </div>
      <>
        <div className="w-full max-md:px-2 max-md:py-3 px-4 md:px-6 lg:px-8 py-5 flex-grow">
          <form onSubmit={handleSubmit} className="mb-6">
            <h3 className="text-lg font-bold text-gray-700 mb-4">
              Create Coupon
            </h3>
            <div className="flex flex-col gap-4 w-full lg:w-[400px]">
              {/* Coupon Code */}
              <TextField
                variant="outlined"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                placeholder="Enter the coupon code"
                label="Coupon Code"
                className="flex-1"
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    fontWeight: "600",
                    background: "white",
                    fontSize: "14px",
                    fontFamily: "inherit",
                    letterSpacing: "1px",
                    height: "40px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "4px 8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "14px",
                    fontFamily: "inherit",
                  },
                }}
              />

              {/* Discount Percentage */}
              <TextField
                variant="outlined"
                type="number"
                value={discountPercentage}
                onChange={(e) => setDiscountPercentage(e.target.value)}
                placeholder="Enter discount percentage"
                label="Discount Percentage"
                className="flex-1"
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    fontWeight: "600",
                    background: "white",
                    fontSize: "14px",
                    fontFamily: "inherit",
                    letterSpacing: "1px",
                    height: "40px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "4px 8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "14px",
                    fontFamily: "inherit",
                  },
                }}
              />

              {/* Max Usage */}
              <TextField
                variant="outlined"
                type="number"
                value={maxUsage}
                required
                onChange={(e) => setMaxUsage(e.target.value)}
                placeholder="Enter max usage"
                label="Max Usage"
                className="flex-1"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    fontWeight: "600",
                    background: "white",
                    fontSize: "14px",
                    fontFamily: "inherit",
                    letterSpacing: "1px",
                    height: "40px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "4px 8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "14px",
                    fontFamily: "inherit",
                  },
                }}
              />

              {/* Save Button */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  height: "40px",
                  fontSize: "15px",
                  letterSpacing: "2px",
                  fontFamily: "inherit",
                  fontWeight: "600",
                  backgroundColor: "var(--color-green)",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "var(--color-green-hover)",
                  },
                }}
              >
                Save
              </Button>
            </div>
          </form>
          <div className="w-full overflow-x-auto pt-[100px] sm:px-5">
            <h2 className="text-[16px] tracking-wide p-3 text-black-1/80 bg-white-1 rounded-t-md">
              All Users
            </h2>
            <TableContainer component={Paper} className="shadow-md">
              <Table>
                <TableHead className="bg-gray-100">
                  <TableRow>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Coupon Code
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Discount Percentage
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Max. Usage
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Expire Date
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Upto Amount
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Active/Inactive
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coupons.map((coupon, index) => (
                    <TableRow key={index} hover>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{coupon?.code}</TableCell>
                      <TableCell align="center">
                        {coupon?.discount_percentage
                          ? `${parseFloat(coupon.discount_percentage)}%`
                          : "0%"}
                      </TableCell>

                      <TableCell align="center">
                        {coupon?.max_usage || "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        {coupon?.Expire || "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        {coupon?.UpTo || "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        <div className="flex items-center justify-center">
                          <b
                            className={`mr-2 ${
                              coupon?.Active === "false"
                                ? "text-green"
                                : "text-gray-600"
                            }`}
                          >
                            Inactive
                          </b>
                          <Switch
                            checked={coupon?.Active === "true"}
                            onChange={() => handleToggleCoupon(coupon?.id)}
                            sx={{
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: "#283248",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                {
                                  backgroundColor: "#283248",
                                },
                            }}
                          />
                          <b
                            className={`ml-2 ${
                              coupon?.Active === "true"
                                ? "text-green"
                                : "text-gray-600"
                            }`}
                          >
                            Active
                          </b>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </>
    </div>
  );
};

export default AdminCoupons;
