import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchBulkOrderData,
  getBulkOrderUserOTP,
  verifyBulkOrderOTP,
} from "./bulkOrderAPI";

const initialState = {
  bulk: [],
  status: "idle",
};

export const fetchBulkOrderDataAsync = createAsyncThunk(
  "bulk/fetchBulkOrderData",
  async () => {
    const response = await fetchBulkOrderData();
    return response?.data;
  }
);

export const getBulkOrderUserOTPAsync = createAsyncThunk(
  "bulk/getBulkOrderUserOTP",
  async (phoneNumber) => {
    const response = await getBulkOrderUserOTP(phoneNumber);
    return response?.data;
  }
);

export const verifyBulkOrderOTPAsync = createAsyncThunk(
  "bulk/verifyBulkOrderOTP",
  async (otpData) => {
    const response = await verifyBulkOrderOTP(otpData);
    return response?.data;
  }
);

export const bulkSlice = createSlice({
  name: "bulk",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBulkOrderDataAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBulkOrderDataAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.bulk = action.payload;
      })
      .addCase(getBulkOrderUserOTPAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBulkOrderUserOTPAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(verifyBulkOrderOTPAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyBulkOrderOTPAsync.fulfilled, (state, action) => {
        state.status = "idle";
      });
  },
});

export const selectBulkOrderData = (state) => state.bulk.bulk;

export default bulkSlice.reducer;
