import { API_BASE_URL } from "../../config";
export function getCollections() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}collections`);
    const data = await response.json();

    resolve({ data });
  });
}

export function createCollection(collectionData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}collections`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(collectionData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function editCollection(collectionData) {
  const collection_id = collectionData?.collection_id;
  const collection_name = collectionData?.collection_name;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}collections/${collection_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ collection_name }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function toggleCollection(collection_id) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}collections/${collection_id}`);
    const data = await response.json();

    resolve({ data });
  });
}

export function removeProductFromCollection(item_id) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}collections-items/${item_id}`);
    const data = await response.json();
    resolve({ data });
  });
}

export function fetchAllProductInventory() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}collection_product`);
    const data = await response.json();

    resolve({ data });
  });
}

export function addProductInCollection(collectionData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}collections-items`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(collectionData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// ADMIN COUPON

export function createCoupon(couponData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}list/create_coupons`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(couponData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchAllCoupon() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/fetch_coupons`);
    const data = await response.json();

    resolve({ data });
  });
}

export function toggleCoupon(id) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/toggle_coupons/${id}`);
    const data = await response.json();

    resolve({ data });
  });
}

export function fetchAlldealerProductSale() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/dealer_product_sale`);
    const data = await response.json();

    resolve({ data });
  });
}
export function fetchAlldealerSKUSale() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/dealer_sku`);
    const data = await response.json();

    resolve({ data });
  });
}

export function updateDeliveredStatus(statusData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}list/update_delivered_status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(statusData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// Product & SKU

export function fetchAllProductAndSKU() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/active-status`);
    const data = await response.json();

    resolve({ data });
  });
}


export function toggleProductStatus(prod_ID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/product_toggle/${prod_ID}`);
    const data = await response.json();

    resolve({ data });
  });
}

export function toggleSKUStatus(SKU_ID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/sku_toggle/${SKU_ID}`);
    const data = await response.json();

    resolve({ data });
  });
}

// Admin BUlk Order

export function fetchAllBulkOrderUser() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}bulkOrder_user/fetch`);
    const data = await response.json();

    resolve({ data });
  });
}

export function addUserForBulkOrder(bulkData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}bulkOrder_user/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bulkData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
