import React, { useState, useEffect, useRef } from "react";
import "./BrandSelect.css";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { useSelector } from "react-redux";
import { selectAllAges } from "../../../../features/listingPage/listingPageSlice";

function AgeSelect({ onSelect, closeAgeSelect, previouslySelectedAge }) {
  const ages = useSelector(selectAllAges);
  console.log(ages);
  const [selectedAges, setSelectedAges] = useState(
    previouslySelectedAge || []
  );
  const [searchQuery, setSearchQuery] = useState("");

  const handleCheckboxChange = (age) => {
    setSelectedAges((prevselectedAges) =>
      prevselectedAges.includes(age)
        ? prevselectedAges.filter((b) => b !== age)
        : [...prevselectedAges, age]
    );
  };

  const handleMobileCheckboxChange = (age) => {
    setSelectedAges((prevselectedAges) => {
      const updatedAges = prevselectedAges.includes(age)
        ? prevselectedAges.filter((b) => b !== age)
        : [...prevselectedAges, age];
      onSelect({ ages: updatedAges });
      return updatedAges;
    });
  };

  const handleApplyClick = () => {
    onSelect({ ages: selectedAges });
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Create a ref to the component
  const ageSelectRef = useRef(null);

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const handleClickOutside = (event) => {
    if (
      ageSelectRef.current &&
      !ageSelectRef.current.contains(event.target)
    ) {
      // Click occurred outside of the BrandSelect component
      closeAgeSelect();
    }
  };

  const filteredAges = ages?.filter((data) =>
    data.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div ref={ageSelectRef} className="brand-select-container">
      <div className="brand-select-btn-container">
        <span>{selectedAges.length} selected</span>
        <div className="brand-select-btn-contain">
          <button className="brand-select-btn-apply" onClick={handleApplyClick}>
            Apply
          </button>
          <button
            className="brand-select-btn-clear"
            onClick={() => setSelectedAges([])}
          >
            Clear
          </button>
        </div>
      </div>
      <div className="brand-select-search-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
        <SearchSharpIcon className="brand-select-search-icon" />
      </div>
      <div className="brand-select-option-container">
        {filteredAges.map((data, index) => (
          <div
            className={`brand-name-checkbox${
              selectedAges.includes(data) ? "-selected" : ""
            }`}
            key={index}
          >
            <input
              type="checkbox"
              checked={selectedAges.includes(data)}
              onChange={() => handleCheckboxChange(data)}
              // onClick={handleApplyClick}
            />
            <span>{data}</span>
          </div>
        ))}
      </div>
      <div className="mobile-brand-select-option-container">
        {filteredAges.map((data, index) => (
          <div
            className={`brand-name-checkbox${
              selectedAges.includes(data) ? "-selected" : ""
            }`}
            key={index}
          >
            <input
              type="checkbox"
              checked={selectedAges.includes(data)}
              onChange={() => handleMobileCheckboxChange(data)}
              // onClick={handleApplyClick}
            />
            <span className="brand-name-span-d">{data}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AgeSelect;
