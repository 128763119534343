import React from "react";
import { useMediaQuery } from "react-responsive";
import BulkOrderWithAuth from "../components/BulkOrder/BulkOrderWithAuth";

const BulkOrder = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" });
  return (
    <>
      {isDesktopOrLaptop && (
        <BulkOrderWithAuth/>
      )}
      {isTabletOrMobile && (
        <BulkOrderWithAuth/>
      )}
    </>
  );
};

export default BulkOrder;
