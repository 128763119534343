import React, { useEffect, useRef, useState } from "react";
import "./MobileImgSlider.css";
import { IMAGE_BASE } from "../../config";
import { useSelector } from "react-redux";
import 'react-lazy-load-image-component/src/effects/blur.css';

function MobileImgSlider({ selectProduct }) {
  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );
  const [sliderState, setSliderState] = useState({
    slideIndex: 1,
    images: [],
    width: 0,
    start: 0,
    change: 9,
    hovering: false,
    bigImage: "",
  });
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const slideRef = useRef();

  useEffect(() => {
    if (selectProduct && selectProduct?.length > 0) {
      const selectedImages = selectProduct[0]?.SKU_Images?.find(
        (product) => product?.SKU_ID === selectedVariant?.SKU_ID
      );
      let imagesArray = [];
      if (selectedImages) {
        imagesArray = selectedImages?.images.map(
          (imageUrl) =>
            `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${imageUrl}`
        );
      } else if (selectProduct[0]?.images) {
        imagesArray = selectProduct[0]?.images[0]?.map(
          (imageUrl) =>
            `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${imageUrl}`
        );
      }
      setSliderState((prevState) => ({
        ...prevState,
        images: imagesArray,
        bigImage: imagesArray[0],
        slideIndex: 1,
      }));
    }
  }, [selectProduct, selectedVariant]);

  useEffect(() => {
    if (!slideRef.current) return;

    const scrollWidth = slideRef.current.scrollWidth;
    const childrenElementCount = slideRef.current.childElementCount;
    const width = scrollWidth / childrenElementCount;

    setSliderState((prevState) => ({
      ...prevState,
      width: width,
    }));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!sliderState.hovering) {
        plusSlides(1);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [sliderState.hovering, sliderState.slideIndex]);

  function plusSlides(n) {
    if (sliderState.images.length === 0) {
      // Handle the case when images are not yet loaded
      return;
    }

    const newIndex =
      ((sliderState.slideIndex - 1 + n + sliderState.images.length) %
        sliderState.images.length) +
      1;
    setSliderState((prevState) => ({
      ...prevState,
      slideIndex: newIndex,
      bigImage: sliderState.images[newIndex - 1],
    }));
  }

  function handleDragStart(e) {
    setSliderState((prevState) => ({
      ...prevState,
      start: e.clientX || e.touches[0].clientX,
    }));
  }

  function handleDragOver(e) {
    const touch = e.clientX || e.touches[0].clientX;
    setSliderState((prevState) => ({
      ...prevState,
      change: prevState.start - touch,
    }));
  }

  function handleDragEnd() {
    if (sliderState.change > 0) {
      slideRef.current.scrollLeft += sliderState.width;
    } else {
      slideRef.current.scrollLeft -= sliderState.width;
    }
  }

  useEffect(() => {
    if (!slideRef.current || !sliderState.width) return;

    const numOfThumb = Math.round(
      slideRef.current.offsetWidth / sliderState.width
    );
    slideRef.current.scrollLeft =
      sliderState.slideIndex > numOfThumb
        ? (sliderState.slideIndex - 1) * sliderState.width
        : 0;
  }, [sliderState.width, sliderState.slideIndex]);

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
  }
  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }
  function handleTouchEnd() {
    if (touchStart - touchEnd > 150) {
      plusSlides(1);
    } else if (touchStart - touchEnd < -150) {
      plusSlides(-1);
    }
  }
  return (
    <div className="product-page-img">
      <div className="big-images">
        <div className="mobile-mySlider-div">
          <div
            className="mobile-mySlides"
            onMouseEnter={() =>
              setSliderState((prevState) => ({ ...prevState, hovering: true }))
            }
            onMouseLeave={() =>
              setSliderState((prevState) => ({ ...prevState, hovering: false }))
            }
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img loading="lazy"
              className="mobile-big-image"
              src={sliderState.bigImage}
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        className="slider-img"
        draggable={true}
        ref={slideRef}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        {sliderState.images.map((image, index) => (
          <div
            key={index}
            className={`slider-box ${
              index + 1 === sliderState.slideIndex ? "active" : ""
            }`}
            onClick={() => {
              setSliderState((prevState) => ({
                ...prevState,
                slideIndex: index + 1,
                bigImage: image,
              }));
            }}
          >
            <img loading="lazy" src={image} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default MobileImgSlider;
