import React, { useEffect, useState, useMemo } from "react";
import "./Navigations.css";
import { Fade } from "react-reveal";
import { selectAllNavigations } from "../../features/HomePage/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_BASE } from "../../config";
import { useNavigate } from "react-router";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import {
  fetchAllCategoryProductsAsync,
  fetchAllSubCategoriesAsync,
  fetchAllSubCategoryProductsAsync,
} from "../../features/listingPage/listingPageSlice";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Navigations({ handleNavigations, showNavigation }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const verticalDetail = useSelector(selectAllNavigations);
  const [verticalColor, setVerticalColor] = useState(null);

  useEffect(() => {
    if (showNavigation === 1) {
      setVerticalColor("var(--color-blue)");
    } else if (showNavigation === 2) {
      setVerticalColor("var(--color-green)");
    } else if (showNavigation === 3) {
      setVerticalColor("var(--color-peach)");
    }
    else if (showNavigation === 4) {
      setVerticalColor("var(--color-bike)");
    } else {
      setVerticalColor(null);
    }
  }, [showNavigation]);

  const handleBrandNavigate = (value) => {
    const lowercaseValue = value.toLowerCase();
    navigate(`/listing-page/${lowercaseValue}`);
    handleNavigations(null);
  };

  const handelCategoryNavigate = (catID, catName) => {
    navigate(`/listing-page/${catID}/${catName}`);
    dispatch(setSelectedSubcategory(null));
    // dispatch(fetchAllCategoryProductsAsync(catID));
    handleNavigations(null);
  };

  const handelSubCategoryNavigate = (catID, catName, subCatID, subCatName) => {
    dispatch(fetchAllSubCategoriesAsync(catID)).then(() => {
      dispatch(setSelectedSubcategory(subCatID));
      navigate(`/listing-page/${catID}/${catName}`);
      // dispatch(fetchAllSubCategoryProductsAsync(subCatID));
      handleNavigations(null);
    });
  };

  const memoizedCategories = useMemo(() => {
    return (
      verticalDetail?.categories &&
      verticalDetail?.categories.map((category, index) => (
        <div className="navigations-each-categories-container" key={index}>
          <div className="navigations-each-categories-banners">
            <LazyLoadImage
              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_CATEGORY_IMAGE/${
                category?.image && category?.image
              }`}
              alt=""
              className="navigations-each-categories-img"
              effect="blur"
              wrapperProps={{
                // If you need to, you can tweak the effect transition using the wrapper style.
                style: { transitionDelay: "1s" },
              }}
              onClick={() =>
                handelCategoryNavigate(
                  category?.CategoryID,
                  category?.categoryName
                )
              }
            />
          </div>
          <div className="navigations-each-categories-name">
            <span
              className="navigations-each-categories-name-span"
              style={{ color: verticalColor }}
              onClick={() =>
                handelCategoryNavigate(
                  category?.CategoryID,
                  category?.categoryName
                )
              }
            >
              {category.categoryName && category?.categoryName}
            </span>
          </div>
          <div className="navigations-each-categories-sub-categories-container">
            {category?.subcategories &&
              category?.subcategories.map((subCategory, index) => (
                <div
                  className="navigations-each-categories-each-sub-categories"
                  key={index}
                >
                  <span
                    className="navigations-each-categories-each-sub-categories-span"
                    onClick={() =>
                      handelSubCategoryNavigate(
                        category.CategoryID,
                        category.categoryName,
                        subCategory?.sc_id,
                        subCategory?.SubcategorieName
                      )
                    }
                  >
                    {subCategory?.SubcategorieName &&
                      subCategory?.SubcategorieName}
                  </span>
                </div>
              ))}
          </div>
        </div>
      ))
    );
  }, [verticalDetail, verticalColor]);

  const memoizedManufacturers = useMemo(() => {
    return (
      verticalDetail?.manufacturers &&
      verticalDetail?.manufacturers.map((brand, index) => (
        <div className="navigations-each-brands-container" key={index}>
          <LazyLoadImage
            src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
              brand?.manufacture_images && brand?.manufacture_images
            }`}
            alt=""
            className="navigations-each-brands-logo"
            onClick={() =>
              handleBrandNavigate(
                brand?.manufacture_name && brand?.manufacture_name
              )
            }
            effect="blur"
            wrapperProps={{
              // If you need to, you can tweak the effect transition using the wrapper style.
              style: { transitionDelay: "1s" },
            }}
          />
        </div>
      ))
    );
  }, [verticalDetail]);

  return (
    <div className="navigations-container">
      <Fade bottom>
        <div className="navigations-main-container">{memoizedCategories}</div>
      </Fade>
      <Fade bottom>
        <div className="navigations-brands-container">
          {memoizedManufacturers}
        </div>
      </Fade>
    </div>
  );
}

export default Navigations;
