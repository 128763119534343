import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchKeySpecificationForBikesAsync,
  selectKeySpecification,
} from "../../features/productDesc/productDescSlice";

const ImportantKeys = ({ selectProduct }) => {
  const dispatch = useDispatch();
  const specification = useSelector(selectKeySpecification)?.data;

  useEffect(() => {
    // Ensure `selectProduct` exists and has the required `prod_ID`
    if (
      Array.isArray(selectProduct) &&
      selectProduct.length > 0 &&
      selectProduct[0]?.prod_ID
    ) {
      dispatch(fetchKeySpecificationForBikesAsync(selectProduct[0].prod_ID));
    }
  }, [dispatch, selectProduct]);

  // Safely extract `specialData` from `specification`
  const specialData = specification?.specialData;

  // Check if `specialData` is valid and an array
  const isValidSpecialData =
    Array.isArray(specialData) && specialData.length > 0;

  // Utility function to extract a value by key (case insensitive)
  const getSpecValue = (key, defaultValue = "N/A") => {
    if (isValidSpecialData) {
      const spec = specialData.find(
        (item) => item?.key?.toLowerCase() === key.toLowerCase()
      );
      return spec?.value || defaultValue;
    }
    return defaultValue;
  };

  // Extract specifications with fallbacks
  const age = getSpecValue("age", "N/A");
  const height = getSpecValue("height", "N/A");
  const wheel = getSpecValue("wheel", "N/A");
  const frame = getSpecValue("frame", "N/A");

  // Return null if `selectProduct` is invalid or data is not ready
  if (!selectProduct || !Array.isArray(selectProduct) || selectProduct.length === 0)
    return null;

  // Hide the component if API data is missing or invalid
  if (!specification || !isValidSpecialData) {
    return null;
  }

  return (
    <div className="w-full my-2 max-sm:px-2 flex items-center justify-start">
      <div className="w-full md:w-[90%] mt-2 mb-4 flex justify-center items-center border border-gray-300 rounded-lg p-4 shadow-sm">
        <div className="flex-1 text-center">
          <p className="text-xs font-medium text-blue">Age</p>
          <p className="text-lg max-sm:text-base font-bold text-gray-900 whitespace-nowrap">{age}</p>
        </div>
        <div className="border-l border-gray-300 h-12 mx-4 max-sm:mx-3"></div>
        <div className="flex-1 text-center">
          <p className="text-xs font-medium text-blue">Height</p>
          <p className="text-lg max-sm:text-base font-bold text-gray-900 whitespace-nowrap">
            {height}
          </p>
        </div>
        <div className="border-l border-gray-300 h-12 mx-4 max-sm:mx-3"></div>
        <div className="flex-1 text-center">
          <p className="text-xs font-medium text-blue">Wheel</p>
          <p className="text-lg max-sm:text-base font-bold text-gray-900 whitespace-nowrap">{wheel}</p>
        </div>
        <div className="border-l border-gray-300 h-12 mx-4 max-sm:mx-3"></div>
        <div className="flex-1 text-center">
          <p className="text-xs font-medium text-blue">Frame</p>
          <p className="text-lg max-sm:text-base font-bold text-gray-900 whitespace-nowrap">{frame}</p>
        </div>
      </div>
    </div>
  );
};

export default ImportantKeys;
