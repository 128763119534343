import React, { useRef, useEffect, useState } from "react";
import "./BigSearch.css";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  fetchAllTopSearchAsync,
  selectAllTopSearch,
  selectAllRecommendSearch,
} from "../../features/search/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_BASE } from "../../config";
import {
  fetchAllCategoryProductsAsync,
  fetchAllSubCategoriesAsync,
  fetchThroughSearchAsync,
} from "../../features/listingPage/listingPageSlice";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import { useNavigate } from "react-router";
import { setSearchTerm } from "../../store/slices/headerSlice";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import { fetchAllSubCategoryProductsAsync } from "../../features/listingPage/listingPageSlice";
import partsIcon from "../../img/navigation/parts.png";
import accessoriesIcon from "../../img/navigation/accessories.png";
import maintenanceIcon from "../../img/navigation/clothing.png";
import bikesIcon from "../../assets/Bike.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

function BigSearch({
  searchTerm,
  recentSearches,
  storeRecentSearches,
  setRecentSearches,
  handleClickInput,
  setSearchTerm,
  setShowVerticalContainer,
  setShowSearchInput,
  setShowBigSearch,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recommendSearch = useSelector(selectAllRecommendSearch);
  const [recommendProduct, setRecommentProduct] = useState(
    (recommendSearch && recommendSearch?.product) || []
  );
  const [recommendCategories, setRecommentCategories] = useState(
    (recommendSearch && recommendSearch?.categories) || []
  );
  const [recommendSubCategories, setRecommentSubCategories] = useState(
    (recommendSearch && recommendSearch?.subcategories) || []
  );
  const [recommendManufacture, setRecommentManufacture] = useState(
    (recommendSearch && recommendSearch?.manufacture) || []
  );

  const [allAddTerms, setAllAddTerms] = useState([
    ...recommendCategories,
    ...recommendProduct,
    ...recommendSubCategories,
    ...recommendManufacture,
  ]);

  useEffect(() => {
    if (recommendSearch) {
      setRecommentProduct(recommendSearch.product || []);
      setRecommentCategories(recommendSearch.categories || []);
      setRecommentSubCategories(recommendSearch.subcategories || []);
      setRecommentManufacture(recommendSearch.manufacture || []);

      setAllAddTerms([
        ...recommendCategories,
        ...recommendProduct,
        ...recommendSubCategories,
        ...recommendManufacture,
      ]);
    }
  }, [
    recommendSearch,
    recommendCategories,
    recommendManufacture,
    recommendProduct,
    recommendSubCategories,
  ]);

  // Filter terms into three separate arrays based on their verticalId
  const bikesTerms = allAddTerms.filter((term) => term.verticalID === 4);
  const partsTerms = allAddTerms.filter((term) => term.verticalID === 1);
  const accessoriesTerms = allAddTerms.filter((term) => term.verticalID === 2);
  const maintenanceTerms = allAddTerms.filter((term) => term.verticalID === 3);

  const topSearch = useSelector(selectAllTopSearch);

  useEffect(() => {
    dispatch(fetchAllTopSearchAsync());
  }, [dispatch]);

  // Function to determine image link based on term category
  const getImageLink = (term) => {
    if (term.type === "product") {
      return `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${term.image}`;
    } else if (term.type === "category") {
      return `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_CATEGORY_IMAGE/${term.image}`;
    } else if (term.type === "subcategory") {
      return `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_SUBCATEGORY_IMAGE/${term.image}`;
    } else if (term.type === "manufacturer") {
      return `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${term.image}`;
    } else {
      return ""; // Default image link if category is not recognized
    }
  };

  const renderItemLimitedWithImage = (item, index) => {
    return (
      <div
        className="big-search-searched-each-item"
        key={index}
        onClick={() => handleNavigate(item)}
      >
        <LazyLoadImage
          src={getImageLink(item)}
          alt="search-img"
          className="big-search-searched-each-img border-[1px]"
          effect="blur"
          wrapperProps={{
            // If you need to, you can tweak the effect transition using the wrapper style.
            style: { transitionDelay: "1s" },
          }}
        />
        <span className="big-search-searched-each-span">
          {renderHighlightedText(item?.name, item?.type)}
        </span>
      </div>
    );
  };

  const handleTopSearch = (search) => {
    dispatch(fetchAllSubCategoriesAsync(search?.CategoryID)).then(() => {
      navigate(`/listing-page/${search?.CategoryID}/${search?.category_name}`);
      dispatch(setSelectedSubcategory(search?.sc_id));
      // dispatch(fetchAllSubCategoryProductsAsync(recommend.id));
      setShowVerticalContainer(true);
      setShowSearchInput(false);
      setShowBigSearch(false);
    });
  };

  const handleNavigate = (recommend) => {
    if (recommend?.type === "product") {
      navigate(`/product/${recommend?.id}`);
      setSearchTerm("");
      setShowVerticalContainer(true);
      setShowSearchInput(false);
      setShowBigSearch(false);
    } else if (recommend?.type === "category") {
      navigate(`/listing-page/${recommend?.id}/${recommend?.name}`);
      // dispatch(fetchAllCategoryProductsAsync(recommend.id));
      dispatch(setSelectedSubcategory(null));
      setSearchTerm("");
      setShowVerticalContainer(true);
      setShowSearchInput(false);
      setShowBigSearch(false);
    } else if (recommend?.type === "manufacturer") {
      navigate(`/listing-page/${recommend?.name}`);
      setSearchTerm("");
      setShowVerticalContainer(true);
      setShowSearchInput(false);
      setShowBigSearch(false);
    } else if (recommend?.type === "subcategory") {
      dispatch(fetchAllSubCategoriesAsync(recommend?.CategoryID)).then(() => {
        navigate(
          `/listing-page/${recommend?.CategoryID}/${recommend?.category_name}`
        );
        dispatch(setSelectedSubcategory(recommend?.id));
        // dispatch(fetchAllSubCategoryProductsAsync(recommend.id));
        setShowVerticalContainer(true);
        setShowSearchInput(false);
        setShowBigSearch(false);
      });
    }
  };

  const handleClearHistory = () => {
    localStorage.removeItem("recentSearches");
    setRecentSearches([]);
  };

  const handleDeleteTerm = (termToDelete) => {
    const updatedSearches = recentSearches.filter(
      (term) => term !== termToDelete
    );
    storeRecentSearches(updatedSearches);
    setRecentSearches(updatedSearches);
  };

  const renderHighlightedText = (name, type) => {
    if (!searchTerm || !name) {
      return name?.length > 10 ? name.slice(0, 22) + "..." : name;
    }

    let truncatedName = name;
    if (name?.length > 10) {
      truncatedName = name.slice(0, 25) + "...";
    }

    const parts = truncatedName.split(new RegExp(`(${searchTerm})`, "gi"));
    return parts.map((part, index) => (
      <span
        key={index}
        style={
          part.toLowerCase() === searchTerm.toLowerCase()
            ? { color: "black" }
            : { color: "gray" }
        }
      >
        {part}
      </span>
    ));
  };

  const handleSearch = (searchTerm) => {
    dispatch(fetchThroughSearchAsync(searchTerm.trim()));
    dispatch(
      setBreadcrumb({
        categoryID: 0,
        categoryName: "",
        subCategoryID: 0,
        subCategoryName: "",
        ProductID: 0,
        productName: "",
      })
    );
    navigate(`/search/${searchTerm}`);
    handleClickInput("false");
    setSearchTerm("");
    setShowVerticalContainer(true);
    setShowSearchInput(false);
    setShowBigSearch(false);
  };

  useEffect(() => {
    if (searchTerm.length === 0) {
      setAllAddTerms([]);
      setRecommentManufacture([]);
    }
  }, [searchTerm]);

  return (
    <div className="big-search-container">
      <div className="big-search-top-vertical-container">
        <div className="big-search-top-vertical-main-container">
        <div className="big-search-top-vertical-parts-container">
            <div className="big-search-top-vertical-each-container">
              <LazyLoadImage
                src={bikesIcon}
                alt=""
                className="big-search-top-vertical-each-img"
                style={{ background: "#fac2ea" }}
                effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
              />
              <span className="big-search-top-vertical-each-span">Bikes</span>
            </div>
            <div className="big-search-searched-items-div">
              {bikesTerms &&
                bikesTerms
                  .slice(0, 5)
                  .map((term, index) =>
                    renderItemLimitedWithImage(term, index)
                  )}
            </div>
            <div className="big-search-search-items-total-count">
              <span
                className="big-search-search-items-total-count-span"
                style={{ color: "var(--color-bike)" }}
                onClick={() => handleSearch(searchTerm)}
              >
                {bikesTerms && bikesTerms?.length > 5
                  ? "+" + (bikesTerms?.length - 5) + " more products"
                  : ""}
              </span>
            </div>
          </div>
          <div className="big-search-top-vertical-parts-container">
            <div className="big-search-top-vertical-each-container">
              <LazyLoadImage
                src={partsIcon}
                alt=""
                className="big-search-top-vertical-each-img"
                style={{ background: "rgb(175, 187, 212)" }}
                effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
              />
              <span className="big-search-top-vertical-each-span">Parts</span>
            </div>
            <div className="big-search-searched-items-div">
              {partsTerms &&
                partsTerms
                  .slice(0, 5)
                  .map((term, index) =>
                    renderItemLimitedWithImage(term, index)
                  )}
            </div>
            <div className="big-search-search-items-total-count">
              <span
                className="big-search-search-items-total-count-span"
                style={{ color: "var(--color-blue)" }}
                onClick={() => handleSearch(searchTerm)}
              >
                {partsTerms && partsTerms.length > 5
                  ? "+" + (partsTerms.length - 5) + " more products"
                  : ""}
              </span>
            </div>
          </div>
          <div className="big-search-top-vertical-parts-container">
            <div className="big-search-top-vertical-each-container">
              <LazyLoadImage
                src={accessoriesIcon}
                alt=""
                className="big-search-top-vertical-each-img"
                style={{ background: "rgb(168, 213, 180)" }}
                effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
              />
              <span className="big-search-top-vertical-each-span">
                Accessories
              </span>
            </div>
            <div className="big-search-searched-items-div">
              {accessoriesTerms &&
                accessoriesTerms
                  .slice(0, 5)
                  .map((term, index) =>
                    renderItemLimitedWithImage(term, index)
                  )}
            </div>
            <div className="big-search-search-items-total-count">
              <span
                className="big-search-search-items-total-count-span"
                style={{ color: "var(--color-green)" }}
                onClick={() => handleSearch(searchTerm)}
              >
                {accessoriesTerms && accessoriesTerms?.length > 5
                  ? "+" + (accessoriesTerms?.length - 5) + " more items"
                  : ""}
              </span>
            </div>
          </div>
          <div className="big-search-top-vertical-parts-container">
            <div className="big-search-top-vertical-each-container">
              <LazyLoadImage
                src={maintenanceIcon}
                alt=""
                className="big-search-top-vertical-each-img"
                style={{ background: "rgb(216, 170, 169)" }}
                effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
              />
              <span className="big-search-top-vertical-each-span">
                Maintenance
              </span>
            </div>
            <div className="big-search-searched-items-div">
              {maintenanceTerms &&
                maintenanceTerms
                  .slice(0, 5)
                  .map((term, index) =>
                    renderItemLimitedWithImage(term, index)
                  )}
            </div>
            <div className="big-search-search-items-total-count">
              <span
                className="big-search-search-items-total-count-span"
                style={{ color: "var(--color-peach)" }}
                onClick={() => handleSearch(searchTerm)}
              >
                {accessoriesTerms && maintenanceTerms?.length > 5
                  ? "+" + (maintenanceTerms?.length - 5) + " more items"
                  : ""}
              </span>
            </div>
          </div>
        </div>
        {recommendManufacture.length > 0 && (
          <div className="big-search-top-vertical-brand-container">
            {recommendManufacture &&
              recommendManufacture.slice(0, 4).map((brand, index) => (
                <div className="big-search-top-vertical-each-brand">
                  <LazyLoadImage
                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${brand.image}`}
                    alt="brand logo"
                    className="big-search-top-vertical-each-brand-img"
                    onClick={() => handleNavigate(brand)}
                    effect="blur"
                    wrapperProps={{
                      // If you need to, you can tweak the effect transition using the wrapper style.
                      style: { transitionDelay: "1s" },
                    }}
                  />
                </div>
              ))}
          </div>
        )}
      </div>

      <div className="big-search-trending-search-container">
        <div className="big-search-trending-search-heading-div">
          <WhatshotOutlinedIcon className="big-search-trending-search-heading-img" />
          <span className="big-search-trending-search-heading-span">
            Trending Searches
          </span>
        </div>
        <div className="big-search-trending-search-trend-div">
          {topSearch &&
            topSearch.slice(0, 10).map((search, index) => (
              <div
                className="big-search-trending-each-search-trend-div"
                onClick={() => handleTopSearch(search)}
              >
                {search?.SubcategorieName && search?.SubcategorieName}
              </div>
            ))}
        </div>
        {recentSearches.length > 0 && (
          <div className="big-search-recent-search-container">
            <div className="big-search-recent-search-heading-div">
              <div className="big-search-recent-search-left-div">
                <ScheduleOutlinedIcon className="big-search-recent-search-left-img" />
                <span className="big-search-recent-search-left-span">
                  Recent Searches
                </span>
              </div>
              <div
                className="big-search-recent-search-right-clear-history"
                onClick={handleClearHistory}
              >
                <DeleteForeverOutlinedIcon id="big-search-recent-search-all-delete-icon" />
              </div>
            </div>

            <div className="big-search--main-recent-search">
              {recentSearches &&
                recentSearches.map((term, index) => (
                  <div
                    className="big-search-each-recent-search"
                    onClick={() => handleSearch(term)}
                  >
                    <span
                      key={index}
                      className="big-search-each-recent-search-span"
                    >
                      {term}
                    </span>
                    <CloseIcon onClick={() => handleDeleteTerm(term)} />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  setSearchTerm,
};
export default connect(mapStateToProps, mapDispatchToProps)(BigSearch);
