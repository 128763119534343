import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";
import {
  Button,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  addUserForBulkOrderAsync,
  fetchAllBulkOrderUserAsync,
  selectAllBulkOrderUser,
} from "../../features/admin/adminSlice";
import toast from "react-hot-toast";
import SearchIcon from "@mui/icons-material/Search";

const AdminBulkOrder = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const userDetail = useSelector(selectUser);
  const bulkOrderUser = useSelector(selectAllBulkOrderUser)?.users || [];
  const [searchQuery, setSearchQuery] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("true");

  const handleSubmit = (e) => {
    handleOpen();
    e.preventDefault();
    let bulkData = {
      PhoneNumber: phoneNumber,
      Name: name,
      Active: status,
    };
    dispatch(addUserForBulkOrderAsync(bulkData)).then(() => {
      dispatch(fetchAllBulkOrderUserAsync());
      handleClose();
      toast.success("Add User to Bulk Order successfully");
    });
  };

  useEffect(() => {
    handleOpen();
    dispatch(fetchAllBulkOrderUserAsync()).then(() => {
      handleClose();
    });
  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleToggleBulkOrder = (data) => {
    let bulkData = {
      PhoneNumber: data?.PhoneNumber,
      Name: data?.Name,
      Active: data?.Active === "true" ? "false" : "true",
    };
    handleOpen();
    dispatch(addUserForBulkOrderAsync(bulkData))
      .then(() => {
        dispatch(fetchAllBulkOrderUserAsync());
      })
      .then(() => {
        toast.success("Update Bulk order User Access");
        handleClose();
      });
  };

  // Filter users based on the search query
  const filteredUsers = bulkOrderUser.filter((user) =>
    user?.PhoneNumber?.toLowerCase().includes(searchQuery)
  );

  return (
    <div className="w-full min-h-screen bg-gray-50 relative flex flex-col">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="w-full h-14 bg-white-1 flex items-center justify-between sticky top-0 py-1 px-5
       shadow-md z-10 max-sm:hidden"
      >
        <span className="text-lg font-bold tracking-wider text-gray-800">
          Bulk Order
        </span>
        <img
          loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="w-10 h-10 rounded-full border border-gray-300"
        />
      </div>
      <>
        <div className="w-full max-md:px-2 max-md:py-3 px-4 md:px-6 lg:px-8 py-5 flex-grow">
          <form onSubmit={handleSubmit} className="mb-6">
            <h3 className="text-lg font-bold text-gray-700 mb-4">
              Create Bulk Order User
            </h3>
            <div className="flex flex-col gap-4 w-full lg:w-[400px]">
              {/* Phone Number */}
              <TextField
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => {
                  // Ensure only digits are entered and limit to 10 digits
                  const value = e.target.value.replace(/\D/g, "").slice(0, 10);
                  setPhoneNumber(value);
                }}
                placeholder="Enter Phone Number"
                label="Phone Number"
                type="text"
                className="flex-1"
                required
                inputProps={{
                  maxLength: 10, // Ensure max length is 10
                  inputMode: "numeric", // Opens numeric keyboard on mobile
                  pattern: "\\d*", // Ensures only digits can be entered
                  style: { MozAppearance: "textfield" }, // Removes arrows in Firefox
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    fontWeight: "600",
                    background: "white",
                    fontSize: "14px",
                    fontFamily: "inherit",
                    letterSpacing: "1px",
                    height: "40px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "4px 8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "14px",
                    fontFamily: "inherit",
                  },
                }}
              />

              {/* User Name */}
              <TextField
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter User Name"
                label="User Name"
                className="flex-1"
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    fontWeight: "600",
                    background: "white",
                    fontSize: "14px",
                    fontFamily: "inherit",
                    letterSpacing: "1px",
                    height: "40px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "4px 8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "14px",
                    fontFamily: "inherit",
                  },
                }}
              />

              {/* Status Toggle */}
              <div className="flex items-center">
                <span className="mr-4 text-gray-700 font-medium">
                  Active Status
                </span>
                <Switch
                  checked={status === "true"}
                  onChange={(e) =>
                    setStatus(e.target.checked ? "true" : "false")
                  }
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#283248",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#283248",
                    },
                  }}
                />
                <span className="ml-2 font-medium text-gray-600">
                  {status === "true" ? "Active" : "Inactive"}
                </span>
              </div>

              {/* Save Button */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  height: "40px",
                  fontSize: "15px",
                  letterSpacing: "2px",
                  fontFamily: "inherit",
                  fontWeight: "600",
                  backgroundColor: "var(--color-green)",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "var(--color-green-hover)",
                  },
                }}
              >
                Generate
              </Button>
            </div>
          </form>

          <div className="w-full overflow-x-auto pt-[50px] sm:px-5">
            <h2 className="text-[16px] tracking-wide p-3 text-black-1/80 bg-white-1 rounded-t-md">
              All Users
            </h2>
            <TableContainer component={Paper} className="shadow-md">
              <Table>
                <TableHead className="bg-gray-100">
                  <TableRow>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      User ID
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Phone Number
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "10px",
                        }}
                      >
                        <SearchIcon
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "8px",
                            transform: "translateY(-50%)",
                            fontSize: "16px",
                            color: "#ccc",
                          }}
                        />
                        <input
                          type="text"
                          placeholder="Phone Number"
                          value={searchQuery}
                          onChange={(event) => {
                            const value = event.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
                            setSearchQuery(value.slice(0, 10)); // Limit the input to 10 digits
                          }}
                          style={{
                            padding: "4px 8px 4px 28px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            height: "25px",
                            width:"150px",
                            color: "black",
                            outline: "none",
                            fontSize: "12px",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers &&
                    filteredUsers?.map((user, index) => (
                      <TableRow key={index} hover>
                        <TableCell align="center">{user?.ID}</TableCell>
                        <TableCell align="center">
                          {user?.PhoneNumber}
                        </TableCell>

                        <TableCell align="center">
                          {user?.Name || "N/A"}
                        </TableCell>

                        <TableCell align="center">
                          <div className="flex items-center justify-center">
                            <b
                              className={`mr-2 ${
                                user?.Active === "false"
                                  ? "text-green"
                                  : "text-gray-600"
                              }`}
                            >
                              Inactive
                            </b>
                            <Switch
                              checked={user?.Active === "true"}
                              onChange={() => handleToggleBulkOrder(user)}
                              sx={{
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                  color: "#283248",
                                },
                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                  {
                                    backgroundColor: "#283248",
                                  },
                              }}
                            />
                            <b
                              className={`ml-2 ${
                                user?.Active === "true"
                                  ? "text-green"
                                  : "text-gray-600"
                              }`}
                            >
                              Active
                            </b>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </>
    </div>
  );
};

export default AdminBulkOrder;
