import React, { useEffect, useState } from "react";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import NoCartItem from "../Carts/NoCartItem";
import { IMAGE_BASE } from "../../config";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  calculateDealerPrice,
  calculateOfferPrice,
  calculateUpdatedDiscountPercentage,
} from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  fetchAllPreOrderUserDetailsAsync,
  removeAllFromPreorderCartsAsync,
  selectAllPreordersUser,
} from "../../features/cartPage/cartPageSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import Fulfilled from "../../dComponent/Fullfilled";
import { FormattedNumber } from "../../utils/numberAnimation";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function AdminPreOrder() {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const userPreBook = useSelector(selectAllPreordersUser);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(userPreBook);

  const [openOrder, setOpenOrder] = useState(null);
  const [fFilled, setFFilled] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(fetchAllPreOrderUserDetailsAsync(userDetail?.UserID)).then(
        () => {
          handleClose();
        }
      );
    }
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    // Filter users whenever the search query changes
    if (userPreBook) {
      setFilteredUsers(
        userPreBook &&
          userPreBook?.filter(
            (user) =>
              user?.FirstName.toLowerCase()?.includes(
                searchQuery?.toLowerCase()
              ) ||
              user?.LastName.toLowerCase()?.includes(
                searchQuery?.toLowerCase()
              ) ||
              user?.PhoneNumber?.includes(searchQuery)
          )
      );
    }
  }, [searchQuery, userPreBook]);

  const wantToFulfilled = (id) => {
    handleOpen();
    dispatch(removeAllFromPreorderCartsAsync(fFilled))?.then(() => {
      dispatch(fetchAllPreOrderUserDetailsAsync(userDetail?.UserID));
      setFFilled(null);
      handleClose();
    });
  };
  const handleFulfilled = (id) => {
    setFFilled(id);
  };

  const calculateTotalAmount = (user) => {
    let totalAmount = 0;

    if (user && user?.Preorders) {
      for (const preorder of user?.Preorders) {
        // Choose the price: offer price if available, otherwise NDP price
        const price = preorder?.Offer_price
          ? calculateOfferPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.Offer_price),
              preorder?.GST
            )
          : calculateDealerPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.NDP_price),
              preorder?.GST
            );

        // Calculate the amount for this preorder item
        const amount = price * preorder?.quantity;

        // Add to the total amount
        totalAmount += amount;
      }
    }

    return totalAmount;
  };

  const calculateOverallAmount = () => {
    let overallAmount = 0;

    if (userPreBook && userPreBook?.length > 0) {
      for (const user of userPreBook) {
        if (user && user?.Preorders) {
          for (const preorder of user?.Preorders) {
            // Choose the price: offer price if available, otherwise NDP price
            const price = preorder?.Offer_price
              ? calculateOfferPrice(
                  parseInt(preorder?.MRP_price),
                  parseInt(preorder?.Offer_price),
                  preorder?.GST
                )
              : calculateDealerPrice(
                  parseInt(preorder?.MRP_price),
                  parseInt(preorder?.NDP_price),
                  preorder?.GST
                );

            // Calculate the amount for this preorder item
            const amount = price * preorder?.quantity;

            // Add to the total amount
            overallAmount += amount;
          }
        }
      }
    }
    return overallAmount;
  };

  return (
    <div className="w-full h-screen relative overflow-auto">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="w-full h-14 bg-white-1 flex items-center justify-between sticky top-0 right-0 py-1 px-5
       shadow-md z-10 opacity-90 max-sm:hidden"
      >
        <span className="text-lg font-bold tracking-wider text-gray-800">
          Pre-Orders
        </span>
        <img
          loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="w-10 h-10 rounded-full border border-gray-300"
        />
      </div>
      <div className="w-full">
        <div className="w-full flex max-sm:flex-col items-center justify-center sm:justify-between">
          <div className="w-full sm:w-[200px] h-[90px] flex flex-col justify-between sm:mt-10 sm:ml-5 bg-white-1 rounded-md shadow-sm shadow-black-1/20 px-3 py-4">
            <span className="text-[16px] text-[#c1bebe]">Total Pre-Orders</span>
            <div className="w-full flex items-center justify-between">
              <span className="flex items-center text-[20px] text-green">
                {userPreBook && Array.isArray(userPreBook)
                  ? userPreBook?.length
                  : 0}
              </span>
              <WorkHistoryOutlinedIcon
                className="text-gray-300"
                style={{ fontSize: "24px" }}
              />
            </div>
          </div>
          <div className="w-full sm:w-[250px] h-[45px] sm:h-[35px] flex items-center bg-white-1 rounded-full shadow-sm shadow-black-1/20 px-2 max-sm:mt-5 sm:mr-5">
            <SearchIcon
              className="text-black-1/70"
              style={{ fontSize: "22px" }}
            />
            <input
              type="text"
              placeholder="Search by name or phone"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full h-full border-none outline-none p-0 pl-1 rounded-full text-[14px] tracking-wide text-black-1/80"
            />
          </div>
        </div>
        {filteredUsers && Array.isArray(filteredUsers) ? (
          <div className="w-full overflow-x-auto pt-[100px] sm:px-5">
            <div className="w-full flex items-center justify-between bg-white-1 rounded-t-md p-3">
              <span className="text-[16px] max-sm:text-[14px] tracking-wide text-black-1/80 bg-white-1">
                Recent Pre-Orders
              </span>
              <span className="text-[14px] max-sm:text-[12px] tracking-wide text-black-1/80 bg-white-1 font-medium">
                Overall Amount:
                <b style={{ fontSize: "16px" }}>
                  {" "}
                  <FormattedNumber value={calculateOverallAmount()} />
                </b>
              </span>
            </div>
            <TableContainer component={Paper} className="shadow-md">
              <Table>
                <TableHead className="bg-gray-100">
                  <TableRow>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      User Name
                    </TableCell>

                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Phone Number
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Order
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Fulfillment Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers &&
                    Array.isArray(filteredUsers) &&
                    filteredUsers?.map((user, index) => (
                      <>
                        <TableRow
                          key={index}
                          style={{
                            boxShadow:
                              user?.UserID === openOrder &&
                              "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {user?.FirstName}&nbsp;{user?.LastName}
                          </TableCell>
                          <TableCell align="center">
                            {user?.PhoneNumber}
                          </TableCell>
                          <TableCell align="center">{user?.Email}</TableCell>
                          <TableCell align="center">
                            Total Amount:
                            <FormattedNumber
                              value={calculateTotalAmount(user)}
                            />
                            {openOrder === user?.UserID ? (
                              <KeyboardArrowDownOutlinedIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => setOpenOrder(null)}
                              />
                            ) : (
                              <ChevronRightOutlinedIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => setOpenOrder(user?.UserID)}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              background:
                                "linear-gradient(45deg, #ba4747 30%, #ff6b6b 90%)",
                              color: "white",
                              cursor: "pointer",
                              letterSpacing: "1px",
                              transition: "background 0.3s ease-in-out",
                              "&:hover": {
                                fontSize: "16px",
                              },
                            }}
                            onClick={() => handleFulfilled(user?.UserID)}
                          >
                            Fulfilled
                          </TableCell>
                          {fFilled !== null && (
                            <Fulfilled
                              fFilled={fFilled}
                              handleFulfilled={handleFulfilled}
                              wantToFulfilled={wantToFulfilled}
                              user={user}
                            />
                          )}
                        </TableRow>
                        {openOrder === user?.UserID && (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <div className="w-full bg-[#f7f5f5]">
                                {user?.Preorders &&
                                  user?.Preorders.length > 0 && (
                                    <div className="w-full flex flex-col bg-white bg-white-1">
                                      <div className="w-full flex justify-between text-gray-700 font-semibold mb-4">
                                        <span className="w-1/4">Product</span>
                                        <span className="w-1/4 text-center">
                                          Qty
                                        </span>
                                        <span className="w-1/4 text-center">
                                          Price per unit
                                        </span>
                                        <span className="w-1/4 text-right">
                                          Total Price <b>(Incl. GST)</b>
                                        </span>
                                      </div>
                                      <div className="space-y-4">
                                        {user?.Preorders?.map((data, index) => (
                                          <div
                                            className="w-full h-[150px] flex items-center justify-between bg-gray-100 p-4"
                                            key={index}
                                          >
                                            <div className="h-full flex items-center space-x-4 w-1/4">
                                              <div className="w-16 h-20 rounded-md bg-white-1 overflow-hidden">
                                                <LazyLoadImage
                                                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                                                    data?.image && data.image
                                                  }`}
                                                  alt="product-img"
                                                  effect="blur"
                                                  className="w-16 h-20 object-contain p-2"
                                                />
                                              </div>
                                              <div className="flex flex-col">
                                                {data?.manufacture_images ? (
                                                  <LazyLoadImage
                                                    effect="blur"
                                                    className="w-auto h-5 mb-1"
                                                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${data?.manufacture_images}`}
                                                    alt="brand-img"
                                                  />
                                                ) : (
                                                  <h3 className="text-sm text-peach italic">
                                                    {data?.manufacture_name}
                                                  </h3>
                                                )}
                                                <span className="text-sm text-black-1/80 ">
                                                  {data?.prod_name?.slice(
                                                    0,
                                                    40
                                                  )}
                                                </span>
                                                <div className="flex space-x-2 mt-2">
                                                  {data?.Size_Name && (
                                                    <span className="text-xs bg-blue/70 px-2 py-1 rounded">
                                                      {data?.Size_Name}
                                                    </span>
                                                  )}
                                                  {data?.Colour_Name && (
                                                    <span className="text-xs bg-blue/70 px-2 py-1 rounded">
                                                      {data?.Colour_Name}
                                                    </span>
                                                  )}
                                                  {data?.Varient_Name && (
                                                    <span className="text-xs bg-blue/70 px-2 py-1 rounded">
                                                      {data?.Varient_Name}
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>

                                            {/* Quantity */}
                                            <div className="text-center w-1/4">
                                              <span className="text-lg text-gray-700 text-center">
                                                {data?.quantity}
                                              </span>
                                            </div>

                                            {/* Pricing */}
                                            <div className="w-1/4 text-center">
                                              <div className="text-[14px] text-black-1/80">
                                                Dealer Price-&nbsp;
                                                {data?.NDP_price && (
                                                  <span className="text-xs text-black-1/90">
                                                    <FormattedNumber
                                                      value={parseInt(
                                                        data?.NDP_price
                                                      )}
                                                    />
                                                  </span>
                                                )}
                                                {data?.Offer_price && (
                                                  <span className="text-xs text-green-500 font-semibold">
                                                    <FormattedNumber
                                                      value={parseInt(
                                                        data?.Offer_price
                                                      )}
                                                    />
                                                  </span>
                                                )}
                                              </div>
                                              {data?.MRP_price && (
                                                <span className="block text-xs text-black-1/60">
                                                  MRP:{" "}
                                                  <FormattedNumber
                                                    value={parseInt(
                                                      data?.MRP_price
                                                    )}
                                                  />
                                                </span>
                                              )}
                                            </div>

                                            {/* Total Price */}
                                            <div className="w-1/4 flex items-center text-right">
                                              <div className="w-full flex flex-col items-end">
                                                <div className="text-lg font-semibold text-gray-800">
                                                  <FormattedNumber
                                                    value={
                                                      (data?.Offer_price
                                                        ? calculateOfferPrice(
                                                            parseInt(
                                                              data?.MRP_price
                                                            ),
                                                            parseInt(
                                                              data?.Offer_price
                                                            ),
                                                            data?.GST
                                                          )
                                                        : calculateDealerPrice(
                                                            parseInt(
                                                              data?.MRP_price
                                                            ),
                                                            parseInt(
                                                              data?.NDP_price
                                                            ),
                                                            data?.GST
                                                          )) * data?.quantity
                                                    }
                                                  />
                                                </div>
                                                {calculateUpdatedDiscountPercentage(
                                                  data?.MRP_price,
                                                  data?.NDP_price,
                                                  data?.GST
                                                ) > 0 && (
                                                  <div className="flex items-center text-xs text-green font-sans">
                                                    <LocalOfferOutlinedIcon
                                                      className="mr-1 text-green"
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                    />
                                                    <span>
                                                      {calculateUpdatedDiscountPercentage(
                                                        data?.MRP_price,
                                                        data?.NDP_price,
                                                        data?.GST
                                                      )}
                                                      % Dealer Margin
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <NoCartItem text={"Pre-Book"} />
        )}
      </div>
    </div>
  );
}

export default AdminPreOrder;
