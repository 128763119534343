import { API_BASE_URL } from "../../config";
export function getOrderStatus(orderNumber) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}order_status/${orderNumber}`);
    const data = await response.json();

    resolve({ data });
  });
}

export function getInventory(itemNo) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}get_inventory/${itemNo}`);
    const data = await response.json();

    resolve({ data });
  });
}

export function getAllInventory() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}get_inventory`);
    const data = await response.json();

    resolve({ data });
  });
}

export function getAllOrder(userID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}user_orders/${userID}`);
    const data = await response.json();
    resolve({ data });
  });
}

export function createOrder(orderData) {
  // console.log(orderData);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}create_order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function createDealerOrder(dealerOrderData) {
  // console.log(dealerOrderData);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}dealer/order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dealerOrderData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function freezeQuantity(quantityData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}freeze_quantity`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(quantityData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function cancelOrder(orderData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}cancel_order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function viewOrderDetail(order_ID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}view_order/${order_ID}`);
    const data = await response.json();

    resolve({ data });
  });
}

export function createPayOrder(orderData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}create_order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify(orderData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// ADMIN WITHOUT PAYMENT ORDER CREATE
export function updatePaymentStatus(transactionId) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}list/update_payment_status/${transactionId}`
    );
    const data = await response.json();
    resolve({ data });
  });
}
