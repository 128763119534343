import React, { useEffect } from "react";
import "./SubCategories.css";
import { IMAGE_BASE } from "../../../config";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedSubcategory,
  selectedSelectedSubcategory,
} from "../../../store/slices/subCategorySlice";
import {
  fetchAllSubCategoryProductsAsync,
  fetchAllCategoryProductsAsync,
  selectAllCategoryProducts,
  fetchAllSubCategoriesAsync,
  selectAllSubCategories,
  fetchThroughBrandAsync,
  setSubCategories,
} from "../../../features/listingPage/listingPageSlice";
import { setBreadcrumb } from "../../../store/slices/breadCrumbsSlice";
import BrandBanner from "../SubCategories/BrandBanner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function SubCategories({ categoryName, CategoryID,setCurrentPage }) {
  const dispatch = useDispatch();
  const brandname = useParams();
  const subCategories = useSelector(selectAllSubCategories);
  const selectedSubcategory = useSelector(selectedSelectedSubcategory);
  const listProduct = useSelector(selectAllCategoryProducts);
  const listingProduct = listProduct?.Data;
  const totalProductLength = listingProduct?.product?.length || 0;

  useEffect(() => {
    if (CategoryID && brandname.brand === undefined) {
      dispatch(fetchAllSubCategoriesAsync(CategoryID));
    }
  }, [dispatch, CategoryID, brandname.brand]);

  // Set selected subCategory
  const handleApplyClick = (subCategoryID, subCategoryName) => {
    dispatch(
      setSelectedSubcategory(
        selectedSubcategory === subCategoryID ? null : subCategoryID
      )
    );
    // Set subcategory in breadcrumb
    dispatch(
      setBreadcrumb({
        categoryID: CategoryID,
        categoryName: categoryName,
        subCategoryID: subCategoryID,
        subCategoryName: subCategoryID ? subCategoryName : "",
        productName: "",
        ProductID: null,
      })
    );
    setCurrentPage(1);
  };

  // Run when coming to another page and sub-category is already selected previously
  const fetchDataBasedOnSelection = () => {
    if (brandname?.brand) {
      dispatch(fetchThroughBrandAsync(brandname.brand)).then(() => {
        dispatch(setSubCategories([]));
        dispatch(setSelectedSubcategory(null));
        dispatch(
          setBreadcrumb({
            categoryID: null,
            categoryName: brandname.brand,
            subCategoryID: null,
            subCategoryName: "",
            productName: "",
            ProductID: null,
          })
        );
      });
    } else if (
      selectedSubcategory ||
      (subCategories && subCategories.length > 0)
    ) {
      const isSubCategorySelected = subCategories?.some(
        (item) => item.sc_id === selectedSubcategory
      );

      if (isSubCategorySelected) {
        dispatch(fetchAllSubCategoryProductsAsync(selectedSubcategory));
      }
      if (!selectedSubcategory) {
        // dispatch(fetchAllSubCategoryProductsAsync(selectedSubcategory));
        dispatch(fetchAllCategoryProductsAsync(CategoryID));
      }
    }
  };

  useEffect(() => {
    fetchDataBasedOnSelection();
  }, [selectedSubcategory, brandname?.brand]);


  return (
    <div className="subCategories-container">
      {brandname && brandname.brand && (
        <div className="brands-banner-container">
          <BrandBanner
            brandName={brandname && brandname?.brand}
            about={listingProduct && listingProduct?.Manufacture_About}
            banners={listingProduct && listingProduct?.Manufacture_Banners}
          />
        </div>
      )}
      {subCategories && subCategories.length > 0 && (
        <>
          <span className="listingpages-store-heading-span">
            What's in store:
          </span>
          <div className="subCategories-main-container">
            {brandname.brand
              ? null
              : subCategories.map((item) => (
                  <div
                    className={`subCategories-each-subcategory-container ${
                      selectedSubcategory === item.sc_id ? "selected-img" : ""
                    }`}
                    key={item.sc_id}
                    onClick={() =>
                      handleApplyClick(
                        selectedSubcategory === item.sc_id ? null : item.sc_id,
                        item.SubcategorieName
                      )
                    }
                  >
                    <LazyLoadImage
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_SUBCATEGORY_IMAGE/${item.image}`}
                      alt="sub-cat-logo"
                      className="subCategories-each-subcategory-img"
                      effect="blur"
                      wrapperProps={{
                        // If you need to, you can tweak the effect transition using the wrapper style.
                        style: { transitionDelay: "1s" },
                      }}
                      style={{display:"flex"}}
                    />
                    <span className="subCategories-each-subcategory-name">
                      {item.SubcategorieName}
                    </span>
                  </div>
                ))}
          </div>
        </>
      )}
    </div>
  );
}

export default SubCategories;
