import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EastIcon from "@mui/icons-material/East";
import BadgeGold from "../../assets/Badge-Gold.png";
import BadgeSilver from "../../assets/Badge-Silver.png";
import BadgePlatinum from "../../assets/Badge-Platinum.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  calculateAmountToPay,
  calculateGarrariTotalAmount,
  calculateShippingCost,
  calculateSpentPercentage,
  calculateTotalAmount,
  calculateTotalAmountWithOutGST,
  formatDateAfter30Days,
  getFormattedDueDate,
  getGarrariBikeQuantity,
} from "../../utils/utils";
import ccLogo from "../../img/banners/Coupon-CC-Logo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Button, TextField, useMediaQuery } from "@mui/material";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { validCouponForUser } from "../../features/cartPage/walletAPI";
import { FormattedNumber } from "../../utils/numberAnimation";
import { useSelector } from "react-redux";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      props.isExpanded ? (
        <RemoveIcon style={{ fontSize: "18px", color: "black" }} />
      ) : (
        <AddIcon style={{ fontSize: "18px", color: "black" }} />
      )
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  padding: "0 5px",
  "& .MuiTypography-root": {
    fontSize: "14px",
    fontWeight: "700",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const PaymentAccordion = ({
  userDetail,
  walletAmount,
  useWalletAmount,
  setUseWalletAmount,
  errors,
  setErrors,
  couponList,
  validCoupon,
  setValidCoupon,
  selectedCoupon,
  setSelectedCoupon,
  cart,
  creditData,
  useCreditAmount,
  setUseCreditAmount,
  setGeneratePaymentUrl,
  setOpenPaymentModel,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [expanded, setExpanded] = useState("panel1");
  const [dueDate, setDueDate] = useState(null);
  const [pendingOrder, setPendingOrder] = useState(null);
  console.log(userDetail);
  const [totalCartValue, setTotalCartValue] = useState(0);
  const [bikesCartValue, setBikesCartValue] = useState(0);
  const [onlyBikesUser, setOnlyBikesUser] = useState(
    (userDetail?.applicable === "JOINCCNOW" ? true : false) || false
  );

  console.log(calculateGarrariTotalAmount(cart));

  useEffect(() => {
    let cartValue = calculateTotalAmount(cart);
    let normalShippingCost = calculateShippingCost(cart);
    let bikesShippingCost = getGarrariBikeQuantity(cart) * 550;
    let bikeCartVal = calculateGarrariTotalAmount(cart);
    setTotalCartValue(cartValue + bikesShippingCost + normalShippingCost);
    setBikesCartValue(bikeCartVal + bikesShippingCost);
  }, [cart]);

  useEffect(() => {
    if (creditData?.transactionHistory?.length > 0) {
      const pending = creditData?.transactionHistory
        ?.filter((order) => order?.transactionType === "SPEND")
        ?.sort(
          (a, b) => new Date(a?.transactionDate) - new Date(b?.transactionDate)
        )[0];

      setPendingOrder(pending);

      if (pending) {
        const dueDate = getFormattedDueDate(pending?.transactionDate);
        setDueDate(dueDate);
      }
    }
  }, [creditData?.transactionHistory]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [walletInput, setWalletInput] = useState();
  const [couponInput, setCouponInput] = useState("");
  const [creditInput, setCreditInput] = useState();

  const handleCheckCoupon = async () => {
    const foundCoupon = couponList?.find(
      (coupon) => coupon.code === couponInput
    );
    if (foundCoupon) {
      const pass = await checkValidCoupon();
      if (pass) {
        setValidCoupon(foundCoupon);
        setErrors((prevErrors) => ({
          ...prevErrors,
          Coupon: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          Coupon: "Already used twice(max. limit = 2)",
        }));
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        Coupon: "Coupon code is invalid or expired",
      }));
    }
  };

  const checkValidCoupon = async () => {
    let couponData = {
      userID: userDetail?.UserID,
      coupon: couponInput,
    };

    try {
      const response = await validCouponForUser(couponData);
      if (response && response?.data && response?.data?.message) {
        // console.log(response?.data.message);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error validating coupon:", error);
      return false;
    }
  };

  const handleApplyCoupon = (code) => {
    if (selectedCoupon?.couponCode === code) {
      setSelectedCoupon({
        couponCode: "",
        couponDiscountPercentage: 0,
        couponDiscountTaken: 0,
      });
    } else {
      const amount = parseInt(calculateTotalAmountWithOutGST(cart));
      const save = (amount * validCoupon?.discount_percentage) / 100;
      setSelectedCoupon({
        couponCode: validCoupon?.code,
        couponDiscountPercentage: validCoupon?.discount_percentage,
        couponDiscountTaken: parseInt(save),
      });
      setErrors((prevErrors) => ({
        ...prevErrors,
        Coupon: "",
      }));
    }
  };

  const handleSave = (discount) => {
    const amount = parseInt(calculateTotalAmountWithOutGST(cart));
    // console.log(discount, amount);
    return (amount * discount) / 100;
  };

  //   update Credit use input
  const handleCreditInputChange = (event) => {
    const inputValue = parseInt(event.target.value);
    const availableCredit = parseInt(
      creditData?.creditDetails?.availableCredit
    );

    // Ensure inputValue is a valid number
    if (!isNaN(inputValue)) {
      // Find the minimum between availableCredit and totalCartValue
      const maxAllowedValue = Math.min(
        availableCredit,
        onlyBikesUser ? bikesCartValue : totalCartValue
      );

      if (inputValue > maxAllowedValue) {
        setCreditInput(maxAllowedValue);
      } else {
        setCreditInput(inputValue);
      }
    } else {
      setCreditInput(0);
    }
  };

  //   using credit amount
  const handleCreditUse = () => {
    if (isNaN(creditInput)) {
      return;
    } else {
      setExpanded(null);
      setUseCreditAmount(parseInt(creditInput));
    }
  };

  //   Update Wallet use input
  const handleWalletInputChange = (event) => {
    const inputValue = parseInt(event.target.value);

    // Ensure inputValue is a valid number
    if (!isNaN(inputValue)) {
      if (
        inputValue > walletAmount ||
        inputValue >
          parseInt(
            calculateAmountToPay(
              cart,
              selectedCoupon?.couponDiscountTaken,
              useWalletAmount,
              useCreditAmount,
              setUseWalletAmount,
              setUseCreditAmount,
              isDoorToDoorChecked
            )
          )
      ) {
        return;
      } else {
        setWalletInput(inputValue);
      }
    } else {
      setWalletInput(0);
    }
  };
  //   using wallet amount
  const handleWalletUse = () => {
    if (isNaN(walletInput)) {
      return;
    } else {
      setExpanded(null);
      setUseWalletAmount(parseInt(walletInput));
    }
  };

  const isDoorToDoorChecked = useSelector(
    (state) => state.priceRange.isDoorToDoorChecked
  );

  const getBadgeIcon = () => {
    switch (creditData?.creditDetails?.creditLimitCategory_Name) {
      case "Gold":
        return (
          <img
            loading="lazy"
            src={BadgeGold}
            alt="badge"
            width={40}
            height={40}
          />
        );
      case "Silver":
        return (
          <img
            loading="lazy"
            src={BadgeSilver}
            alt="badge"
            width={40}
            height={40}
          />
        );
      case "Diamond":
        return (
          <img
            loading="lazy"
            src={BadgePlatinum}
            alt="badge"
            width={40}
            height={40}
          />
        );
      default:
        return (
          <img
            loading="lazy"
            src={BadgeGold}
            alt="badge"
            width={40}
            height={40}
          />
        );
    }
  };

  return (
    <div className="w-full flex flex-col p-5 px-0 max-sm:px-5 lg:px-[10vw] sm:py-0">
      {creditData?.creditDetails && onlyBikesUser ? (
        bikesCartValue > 50000 ? (
          <div className="my-1 bg-white-1 shadow-md rounded-md p-1">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className="shadow-md rounded-md"
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                isExpanded={expanded === "panel1"}
                sx={{
                  height: "50px",
                }}
              >
                <Typography className="w-full flex items-center justify-between">
                  <div className="flex flex-col items-start justify-center">
                    <span className="text-[14px] text-black-1">
                      Buy Now & Pay Later
                    </span>
                    <span className="text-[11px] text-green tracking-wide">
                      Congratulation you unlocked credit facility
                    </span>
                  </div>
                  <div className="flex flex-col items-end justify-center">
                    <span className="text-[11px] text-black-1">
                      use Credit <br />
                    </span>
                    <span className="text-[13px] text-peach tracking-wide">
                      <FormattedNumber value={parseInt(useCreditAmount)} />
                    </span>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="w-full flex-col">
                    <div className="w-full flex items-center justify-between bg-gray-100 rounded-md p-2 mb-3">
                      <div className="flex flex-col justify-between">
                        <div className="flex items-center">
                          {getBadgeIcon()}
                          <div className="flex flex-col ml-2">
                            <span className="text-[13px] text-black-1/80">
                              Credit Balance
                            </span>
                            <span className="text-[14px] text-black-1 font-bold">
                              <FormattedNumber
                                value={parseInt(
                                  creditData?.creditDetails?.availableCredit
                                )}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col mt-5">
                          {parseInt(creditData?.creditDetails?.spentAmount) !==
                            0 && (
                            <span className="text-[10.5px] text-black-1/80">
                              Due Date:&nbsp; {getFormattedDueDate(Date.now())}
                            </span>
                          )}

                          {parseInt(creditData?.creditDetails?.spentAmount) !==
                          0 ? (
                            <span className="text-[10.5px] text-green">
                              You will get 2% cashback by paying before{" "}
                              {formatDateAfter30Days(Date.now())}
                            </span>
                          ) : (
                            <span className="text-[10.5px] text-green">
                              You have no outstanding dues.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        <div className="w-[60px] h-[60px] mb-2">
                          <CircularProgressbar
                            value={calculateSpentPercentage(
                              creditData?.creditDetails?.Credit_Limit,
                              creditData?.creditDetails?.spentAmount
                            )}
                            text={`${calculateSpentPercentage(
                              creditData?.creditDetails?.Credit_Limit,
                              creditData?.creditDetails?.spentAmount
                            )}%`}
                            styles={buildStyles({
                              strokeLinecap: "round",
                              textSize: "25px",
                              pathTransitionDuration: 0.5,
                              pathColor: "#283248",
                              textColor: "#283248",
                              trailColor: "#d6d6d6",
                            })}
                          />
                        </div>
                        <span className="text-[12px] text-blue">
                          Credit Balance Status
                        </span>
                      </div>
                    </div>
                    <div className="w-full  rounded-md flex items-center justify-between pb-2 relative">
                      <TextField
                        type="text"
                        variant="outlined"
                        placeholder="Enter Amount to pay using Credit Line"
                        value={creditInput}
                        onChange={handleCreditInputChange}
                        InputProps={{
                          inputProps: {
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          },
                        }}
                        onKeyPress={(event) => {
                          if (!/^\d$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        sx={{
                          width: "80%",
                          "& .MuiOutlinedInput-root": {
                            fontSize: "14px",
                            padding: "6px",
                            height: "34px",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 0.1)",
                            },
                          },
                          "& input": {
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "rgba(0, 0, 0, 0.8)",
                          },
                          "&:hover": {
                            outline: "none",
                          },
                        }}
                      />
                      <Button
                        variant="contained"
                        sx={{
                          height: "34px",
                          background: "#283248",
                          borderRadius: "4px",
                          fontSize: "13px",
                          fontWeight: "500",
                          letterSpacing: "0.5px",
                          color: "white",
                          padding: "5px 10px",
                          cursor: "pointer",
                          textTransform: "none",
                          whiteSpace: "nowrap",
                          marginLeft: "8px",
                          "&:hover": {
                            background: "#283248",
                          },
                        }}
                        onClick={handleCreditUse}
                      >
                        Use Credit
                      </Button>
                    </div>
                    <div className="w-full flex mt-1">
                      <InfoOutlinedIcon
                        className="text-blue mr-2"
                        style={{ fontSize: "16px" }}
                      />
                      <span className="text-[10.5px] text-blue ">
                        This credit facility is available exclusively on Garrari
                        Bikes purchase. Limited time offer to avail. By paying
                        through Bikes credit, you agree to the{" "}
                        <Link
                          to="#"
                          className="text-[goldenrod] underline underline-offset-2 cursor-pointer"
                        >
                          Bikes Terms & Conditions
                        </Link>{" "}
                        and for further information{" "}
                        <Link
                          to="#"
                          className="text-[goldenrod] underline underline-offset-2 cursor-pointer"
                        >
                          Learn more
                        </Link>
                      </span>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : (
          <div className="my-1 bg-white-1 shadow-md rounded-md p-1">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className="shadow-md rounded-md"
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                isExpanded={expanded === "panel1"}
                sx={{
                  height: "50px",
                }}
              >
                <Typography className="w-full flex items-center justify-between">
                  <div className="flex flex-col items-start justify-center">
                    <span className="text-[14px] text-black-1">
                      Buy Now & Pay Later
                    </span>
                    <span className="text-[11px] text-peach tracking-wide">
                      Add Bikes worth ₹50,000 to unlock credit facility
                    </span>
                  </div>
                  <div className="flex flex-col items-end justify-center">
                    <span className="text-[11px] text-black-1">
                      use Credit <br />
                    </span>
                    <span className="text-[13px] text-peach tracking-wide">
                      <FormattedNumber value={parseInt(useCreditAmount)} />
                    </span>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="opacity-20 pointer-events-none">
                <Typography>
                  <div className="w-full flex-col">
                    <div className="w-full flex items-center justify-between bg-gray-100 rounded-md p-2 mb-3">
                      <div className="flex flex-col justify-between">
                        <div className="flex items-center">
                          {getBadgeIcon()}
                          <div className="flex flex-col ml-2">
                            <span className="text-[13px] text-black-1/80">
                              Credit Balance
                            </span>
                            <span className="text-[14px] text-black-1 font-bold">
                              <FormattedNumber
                                value={parseInt(
                                  creditData?.creditDetails?.availableCredit
                                )}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col mt-5">
                          {parseInt(creditData?.creditDetails?.spentAmount) !==
                            0 && (
                            <span className="text-[10.5px] text-black-1/80">
                              Due Date:&nbsp; {getFormattedDueDate(Date.now())}
                            </span>
                          )}

                          {parseInt(creditData?.creditDetails?.spentAmount) !==
                          0 ? (
                            <span className="text-[10.5px] text-green">
                              You will get 2% cashback by paying before{" "}
                              {formatDateAfter30Days(Date.now())}
                            </span>
                          ) : (
                            <span className="text-[10.5px] text-green">
                              You have no outstanding dues.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        <div className="w-[60px] h-[60px] mb-2">
                          <CircularProgressbar
                            value={calculateSpentPercentage(
                              creditData?.creditDetails?.Credit_Limit,
                              creditData?.creditDetails?.spentAmount
                            )}
                            text={`${calculateSpentPercentage(
                              creditData?.creditDetails?.Credit_Limit,
                              creditData?.creditDetails?.spentAmount
                            )}%`}
                            styles={buildStyles({
                              strokeLinecap: "round",
                              textSize: "25px",
                              pathTransitionDuration: 0.5,
                              pathColor: "#283248",
                              textColor: "#283248",
                              trailColor: "#d6d6d6",
                            })}
                          />
                        </div>
                        <span className="text-[12px] text-blue">
                          Credit Balance Status
                        </span>
                      </div>
                    </div>
                    <div className="w-full  rounded-md flex items-center justify-between pb-2 relative">
                      <TextField
                        type="text"
                        variant="outlined"
                        placeholder="Enter Amount to pay using Credit Line"
                        value={creditInput}
                        onChange={handleCreditInputChange}
                        InputProps={{
                          inputProps: {
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          },
                        }}
                        onKeyPress={(event) => {
                          if (!/^\d$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        sx={{
                          width: "80%",
                          "& .MuiOutlinedInput-root": {
                            fontSize: "14px",
                            padding: "6px",
                            height: "34px",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 0.1)",
                            },
                          },
                          "& input": {
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "rgba(0, 0, 0, 0.8)",
                          },
                          "&:hover": {
                            outline: "none",
                          },
                        }}
                      />
                      <Button
                        variant="contained"
                        sx={{
                          height: "34px",
                          background: "#283248",
                          borderRadius: "4px",
                          fontSize: "13px",
                          fontWeight: "500",
                          letterSpacing: "0.5px",
                          color: "white",
                          padding: "5px 10px",
                          cursor: "pointer",
                          textTransform: "none",
                          whiteSpace: "nowrap",
                          marginLeft: "8px",
                          "&:hover": {
                            background: "#283248",
                          },
                        }}
                        onClick={handleCreditUse}
                      >
                        Use Credit
                      </Button>
                    </div>
                    <div className="w-full flex mt-1">
                      <InfoOutlinedIcon
                        className="text-blue mr-2"
                        style={{ fontSize: "16px" }}
                      />
                      <span className="text-[10.5px] text-blue ">
                        This credit facility is available exclusively on Garrari
                        Bikes purchase. Limited time offer to avail. By paying
                        through Bikes Credit, you agree to the{" "}
                        <Link
                          to="#"
                          className="text-[goldenrod] underline underline-offset-2 cursor-pointer"
                        >
                          Credit Terms & Conditions
                        </Link>{" "}
                        and for further information{" "}
                        <Link
                          to="#"
                          className="text-[goldenrod] underline underline-offset-2 cursor-pointer"
                        >
                          Learn more
                        </Link>
                      </span>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        )
      ) : (
        creditData?.creditDetails && (
          <div className="my-1 bg-white-1 shadow-md rounded-md p-1">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className="shadow-md rounded-md"
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                isExpanded={expanded === "panel1"}
                sx={{
                  height: "50px",
                }}
              >
                <Typography className="w-full flex items-center justify-between">
                  <span className="text-[14px] text-black-1">
                    Pay using Line of Credit
                  </span>
                  <div className="flex flex-col items-end justify-center">
                    <span className="text-[11px] text-black-1">
                      use Credit <br />
                    </span>
                    <span className="text-[13px] text-peach tracking-wide">
                      <FormattedNumber value={parseInt(useCreditAmount)} />
                    </span>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="w-full flex-col">
                    <div className="w-full flex items-center justify-between bg-gray-100 rounded-md p-2 mb-3">
                      <div className="flex flex-col justify-between">
                        <div className="flex items-center">
                          {getBadgeIcon()}
                          <div className="flex flex-col ml-2">
                            <span className="text-[13px] text-black-1/80">
                              Credit Balance
                            </span>
                            <span className="text-[14px] text-black-1 font-bold">
                              <FormattedNumber
                                value={parseInt(
                                  creditData?.creditDetails?.availableCredit
                                )}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col mt-5">
                          {parseInt(creditData?.creditDetails?.spentAmount) !==
                            0 && (
                            <span className="text-[10.5px] text-black-1/80">
                              Due Date:&nbsp; {getFormattedDueDate(Date.now())}
                            </span>
                          )}

                          {parseInt(creditData?.creditDetails?.spentAmount) !==
                          0 ? (
                            <span className="text-[10.5px] text-green">
                              You will get 2% cashback by paying before{" "}
                              {formatDateAfter30Days(Date.now())}
                            </span>
                          ) : (
                            <span className="text-[10.5px] text-green">
                              You have no outstanding dues.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        <div className="w-[60px] h-[60px] mb-2">
                          <CircularProgressbar
                            value={calculateSpentPercentage(
                              creditData?.creditDetails?.Credit_Limit,
                              creditData?.creditDetails?.spentAmount
                            )}
                            text={`${calculateSpentPercentage(
                              creditData?.creditDetails?.Credit_Limit,
                              creditData?.creditDetails?.spentAmount
                            )}%`}
                            styles={buildStyles({
                              strokeLinecap: "round",
                              textSize: "25px",
                              pathTransitionDuration: 0.5,
                              pathColor: "#283248",
                              textColor: "#283248",
                              trailColor: "#d6d6d6",
                            })}
                          />
                        </div>
                        <span className="text-[12px] text-blue">
                          Credit Balance Status
                        </span>
                      </div>
                    </div>
                    <div className="w-full  rounded-md flex items-center justify-between pb-2 relative">
                      <TextField
                        type="text"
                        variant="outlined"
                        placeholder="Enter Amount to pay using Credit Line"
                        value={creditInput}
                        onChange={handleCreditInputChange}
                        InputProps={{
                          inputProps: {
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          },
                        }}
                        onKeyPress={(event) => {
                          if (!/^\d$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        sx={{
                          width: "80%",
                          "& .MuiOutlinedInput-root": {
                            fontSize: "14px",
                            padding: "6px",
                            height: "34px",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 0.1)",
                            },
                          },
                          "& input": {
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "rgba(0, 0, 0, 0.8)",
                          },
                          "&:hover": {
                            outline: "none",
                          },
                        }}
                      />
                      <Button
                        variant="contained"
                        sx={{
                          height: "34px",
                          background: "#283248",
                          borderRadius: "4px",
                          fontSize: "13px",
                          fontWeight: "500",
                          letterSpacing: "0.5px",
                          color: "white",
                          padding: "5px 10px",
                          cursor: "pointer",
                          textTransform: "none",
                          whiteSpace: "nowrap",
                          marginLeft: "8px",
                          "&:hover": {
                            background: "#283248",
                          },
                        }}
                        onClick={handleCreditUse}
                      >
                        Use Credit
                      </Button>
                    </div>
                    <div className="w-full flex mt-1">
                      <InfoOutlinedIcon
                        className="text-blue mr-2"
                        style={{ fontSize: "16px" }}
                      />
                      <span className="text-[10.5px] text-blue ">
                        By paying through Line of credit, you agree to the{" "}
                        <Link
                          to="#"
                          className="text-[goldenrod] underline underline-offset-2 cursor-pointer"
                        >
                          LOC Terms & Conditions
                        </Link>{" "}
                        and for further information{" "}
                        <Link
                          to="#"
                          className="text-[goldenrod] underline underline-offset-2 cursor-pointer"
                        >
                          Learn more
                        </Link>
                      </span>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        )
      )}
      <div className="my-1 bg-white-1 shadow-md rounded-md p-1">
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className="shadow-md rounded-md"
        >
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            isExpanded={expanded === "panel2"}
            sx={{
              height: "50px",
            }}
          >
            <Typography className="w-full flex items-center justify-between">
              <span className="text-[14px] text-black-1">Add Coupon</span>
              {parseInt(selectedCoupon?.couponDiscountTaken) > 0 && (
                <span className="text-[11.5px] text-black-1">
                  You saved&nbsp;
                  <b className="text-peach">
                    <FormattedNumber
                      value={parseInt(selectedCoupon?.couponDiscountTaken)}
                    />
                  </b>
                </span>
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="w-full flex-col">
                <div className="w-full h-[38px] border-2 border-black-1/10 rounded-md flex items-center justify-between py-2 px-2 relative">
                  <TextField
                    type="text"
                    variant="outlined"
                    placeholder="CCNEW"
                    className="border-none outline-none w-[80%] text-[14px] font-medium text-black-1/80"
                    value={couponInput}
                    fullWidth
                    onChange={(e) => {
                      const newValue = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase();
                      setCouponInput(newValue);
                    }}
                    error={errors["Coupon"]}
                    helperText={errors["Coupon"]}
                    sx={{
                      border: "none",
                      outline: "none",
                      width: "80%",
                      height: "34px",
                      fontSize: "14px",
                      fontWeight: "500",
                      padding: "0",
                      color: "rgba(0,0,0,0.8)",
                      "& .MuiOutlinedInput-root": {
                        fontSize: "14px",
                        padding: "6px",
                        height: "34px",
                        border: "none",
                        outline: "none",
                        "& fieldset": {
                          border: "none",
                        },
                      },
                      "& input": {
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(0, 0, 0, 0.8)",
                        border: "none",
                        outline: "none",
                        padding: "0",
                      },
                      "&:hover": {
                        outline: "none",
                      },
                    }}
                  />
                  <span
                    className="text-[14px] text-peach font-medium cursor-pointer"
                    onClick={handleCheckCoupon}
                  >
                    Check
                  </span>
                </div>
                <div className="w-full min-h-[150px] flex flex-col py-3">
                  {couponList &&
                    couponList?.map((data) => {
                      if (data?.code === validCoupon?.code) {
                        return (
                          <div className="w-full flex relative my-1">
                            <div
                              className={`w-full h-auto flex border-[2px] p-3 rounded-md relative ${
                                selectedCoupon?.couponCode === data?.code
                                  ? "border-green"
                                  : "border-black-1/10"
                              }`}
                            >
                              <div className="w-[35px] h-full mr-2">
                                <LazyLoadImage
                                  src={ccLogo}
                                  alt="cc-logo"
                                  className="w-[30px] h-[30px] object-contain"
                                  effect="blur"
                                  wrapperProps={{
                                    style: { transitionDelay: "1s" },
                                  }}
                                />
                              </div>
                              <div className="w-[calc(100%-35px)] h-full flex items-center justify-between">
                                <div className="w-[calc(100%-90px)] h-full flex flex-col">
                                  <span className="text-[15px] text-black-1 font-bold tracking-wide">
                                    {data?.code}
                                  </span>
                                  <span className="text-[11px] text-black-1/70 font-medium tracking-wide">
                                    Expires: <br />{" "}
                                    <b className="text-[12px] text-black-1 font-bold">
                                      23.02.2025
                                    </b>
                                  </span>
                                  <span className="text-[10px] text-green font-semibold tracking-wide">
                                    Terms & Conditions Apply
                                  </span>
                                </div>
                                <div className="w-[90px] h-full flex flex-col items-center justify-center">
                                  <span className="text-[12px] text-black-1 font-semibold mb-[2px]">
                                    Save&nbsp;
                                    <b>
                                      <FormattedNumber
                                        value={parseInt(
                                          handleSave(data?.discount_percentage)
                                        )}
                                      />
                                    </b>
                                  </span>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      height: "20px",
                                      fontSize: "10px",
                                      fontWeight: "500",
                                      background:
                                        selectedCoupon?.couponCode ===
                                        data?.code
                                          ? "#28a447"
                                          : "#283248",
                                      textTransform: "none",
                                      fontFamily:
                                        'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                                      padding: "5px",
                                      borderRadius: "4px",
                                      letterSpacing: "1px",
                                      color: "white",
                                      "&:hover": {
                                        background:
                                          selectedCoupon?.couponCode ===
                                          data?.code
                                            ? "#28a447"
                                            : "#283248",
                                      },
                                    }}
                                    onClick={() =>
                                      handleApplyCoupon(data?.code)
                                    }
                                  >
                                    {selectedCoupon?.couponCode === data?.code
                                      ? "Applied"
                                      : "Apply"}
                                  </Button>
                                </div>
                              </div>
                            </div>
                            {errors[data?.code] && (
                              <span className="absolute font-sans top-[110%] left-0 text-peach font-medium text-[10px]">
                                {errors[data?.code]}
                              </span>
                            )}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
                <div className="w-full flex items-center justify-between pt-2 border-t-2 border-black-1/10">
                  <div className="flex flex-col">
                    <span className="text-[14px] text-black-1 font-semibold">
                      Maximum Savings:
                    </span>
                    <span className="text-[13px] text-black-1/90 font-bold">
                      <FormattedNumber
                        value={parseInt(selectedCoupon?.couponDiscountTaken)}
                      />
                    </span>
                  </div>
                  <Button
                    variant="contained"
                    sx={{
                      width: "35%",
                      height: "32px",
                      background: "#283248",
                      borderRadius: "30px",
                      fontSize: "13px",
                      fontWeight: "500",
                      letterSpacing: "0.5px",
                      color: "white",
                      padding: "5px 10px",
                      cursor: "pointer",
                      textTransform: "none",
                      "&:hover": {
                        background: "#283248",
                      },
                    }}
                    onClick={() => setExpanded(null)}
                  >
                    Save Now
                  </Button>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="my-1 bg-white-1 shadow-md rounded-md p-1">
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          className="shadow-md rounded-md"
        >
          <AccordionSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
            isExpanded={expanded === "panel3"}
            sx={{
              height: "50px",
            }}
          >
            <Typography className="w-full flex items-center justify-between">
              <span className="text-[14px] text-black-1">Wallet</span>
              <div className="flex flex-col items-end justify-center">
                <span className="text-[11px] text-black-1">
                  Balance <br />
                </span>
                <span className="text-[13px] text-peach tracking-wide">
                  <FormattedNumber value={parseInt(walletAmount)} />
                </span>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="w-full flex-col">
                <div className="w-full  rounded-md flex items-center justify-between pb-2 px-2 relative">
                  <TextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter Amount to pay from wallet"
                    value={walletInput}
                    onChange={handleWalletInputChange}
                    fullWidth
                    InputProps={{
                      inputProps: {
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      },
                    }}
                    onKeyPress={(event) => {
                      if (!/^\d$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        fontSize: "14px",
                        padding: "6px",
                        height: "34px",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 0.1)",
                        },
                      },
                      "& input": {
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(0, 0, 0, 0.8)",
                      },
                      "&:hover": {
                        outline: "none",
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      height: "34px",
                      background: "#283248",
                      borderRadius: "4px",
                      fontSize: "13px",
                      fontWeight: "500",
                      letterSpacing: "0.5px",
                      color: "white",
                      padding: "5px 10px",
                      cursor: "pointer",
                      textTransform: "none",
                      marginLeft: "8px",
                      "&:hover": {
                        background: "#283248",
                      },
                    }}
                    onClick={handleWalletUse}
                  >
                    Pay
                  </Button>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      {!isMobile && (
        <div className=" h-[50px] flex items-center justify-between my-1 bg-white-1 shadow-md rounded-md p-3">
          <span className="text-[13.5px] sm:text-[15px] text-peach font-semibold tracking-wide">
            Total Amount:{" "}
            <FormattedNumber
              value={parseInt(
                calculateAmountToPay(
                  cart,
                  selectedCoupon?.couponDiscountTaken,
                  useWalletAmount,
                  useCreditAmount,
                  setUseWalletAmount,
                  setUseCreditAmount,
                  isDoorToDoorChecked
                )
              )}
            />
          </span>
          <Button
            variant="contained"
            sx={{
              width: "auto",
              height: "32px",
              background: "#283248",
              borderRadius: "30px",
              fontSize: "13px",
              fontWeight: "500",
              letterSpacing: "0.5px",
              color: "white",
              padding: "5px 10px",
              cursor: "pointer",
              textTransform: "none",
              whiteSpace: "nowrap",
              "&:hover": {
                background: "#283248",
              },
            }}
            onClick={() => {
              setGeneratePaymentUrl(true);
              setOpenPaymentModel(true);
            }}
          >
            Proceed to Payment{" "}
            <EastIcon
              className="text-white-1 ml-1"
              style={{ fontSize: "16px" }}
            />
          </Button>
        </div>
      )}
    </div>
  );
};

export default PaymentAccordion;
