import React from "react";
import frameIcon from "../../assets/bulkOrder-frame.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";

const BuildCartState = ({ type, state, level1, level2 }) => {
  return (
    <div className="w-fit absolute top-0 left-0 flex flex-col items-start">
      {/* Frame */}
      <img
        src={frameIcon}
        alt="Frame"
        className="absolute -top-[3px] -left-[11.5px] w-[30px] h-[30px]"
      />

      {/* Text */}
      {type === "MOQ" ? (
        <div className="mt-[17px] ml-[15px]">
          <span className="text-xs font-bold text-black-1 ml-2 whitespace-nowrap flex items-center">
            {state === "done" ? (
              <TaskAltOutlinedIcon
                style={{ fontSize: "14px" }}
                className="text-green"
              />
            ) : (
              <InfoOutlinedIcon
                style={{ fontSize: "14px" }}
                className="text-blue"
              />
            )}
            &nbsp;
            {level1}
          </span>
          <p className="text-xs text-gray-500 ml-[26px] mt-1 whitespace-nowrap">
            {level2}
          </p>
        </div>
      ) : (
        <div className="mt-[17px] ml-[15px]">
          <span className="text-xs font-bold text-black-1 ml-2 whitespace-nowrap flex items-center">
            {state === "done" ? (
              <span className="text-[16px]">🎉</span>
            ) : (
              <div className="w-[14px] h-[14px] flex justify-center items-center rounded-full border-[1px] border-green">
                <div className="w-[9px] h-[9px] bg-green rounded-full"></div>
              </div>
            )}
            &nbsp;
            {level1}
          </span>
          <p className="text-xs text-gray-500 ml-[26px] mt-1 whitespace-nowrap">
            {level2}
          </p>
        </div>
      )}
    </div>
  );
};

export default BuildCartState;
