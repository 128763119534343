import React, { useState } from "react";
import BulkOrderBreadcrum from "./BulkOrderBreadcrum";

const BulkOrderCart = ({ step,setStep }) => {
  const [currentStep, setCurrentStep] = useState(1);
  return (
   <div className="w-full">
    <div className="w-full sticky top-0 z-[800]">
        <BulkOrderBreadcrum
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setStep={setStep}
          step={step}
        />
        <div></div>
      </div>
   </div>
  );
};

export default BulkOrderCart;
