import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import gsap from "gsap";

function GarrariTechnicalData({ productDesc }) {
  const [GarrariTechnicalDatas, setGarrariTechnicalDatas] = useState([]);
  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );

  useEffect(() => {
    if (productDesc && productDesc[0]?.descriptions) {
      const descriptions = productDesc[0]?.descriptions;

      // Fixed fields
      const general = [
        {
          key: "Bar Code",
          value: selectedVariant?.Bar_Code || "N/A",
        },
        {
          key: "Model Number",
          value: selectedVariant?.Model_Number || "N/A",
        },
        {
          key: "Type",
          value:
            descriptions.find((item) => item.key === "Type")?.value || "N/A",
        },
        {
          key: "Speed",
          value:
            descriptions.find((item) => item.key === "Speed")?.value || "N/A",
        },
      ];
      const fixedFields = [
        {
          key: "Manufacturer",
          value:
            productDesc[0]?.Manufacturer?.split(" ")
              .map(
                (word) =>
                  word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()
              )
              .join(" ") || "N/A",
        },
        {
          key: "Warranty",
          value: productDesc[0]?.Warranty || "N/A",
        },
        {
          key: "Country of Origin",
          value: "India",
        },
        {
          key: "Imported/Marketed By",
          value:
            productDesc[0]?.["Imported By"] ||
            "Radkaat Ventures Private Limited",
        },
        {
          key: "Customer Support",
          value: "Spokes@cyclecircle.one",
        },
      ];

      // Categorize descriptions dynamically
      const categoryMapping = {
        Frameset: ["Geometry", "Frame Material", "Decals"],
        Cockpit: [
          "Handlebar",
          "Stem",
          "Headset",
          "Grips",
          "Saddle",
          "Seatpost",
          "Pedals",
        ],
        Drivetrain: ["Cogset", "Crankset", "Chain", "Bottom Bracket"],
        Brakeset: ["Front", "Rear"],
        Suspension: ["Fork"],
        Wheelset: ["Rims", "Tyres & Tube", "Front Hub", "Rear Hub"],
      };

      const categorizedData = Object.keys(categoryMapping).map((category) => {
        const items = descriptions
          .filter((desc) => categoryMapping[category].includes(desc.key))
          .map((desc) => ({ key: desc.key, value: desc.value }));
        return { category, items };
      });

      // Add fixed fields as a separate category
      const finalData = [
        { category: "General", items: general },
        ...categorizedData,
        {
          category: "Additional Details",
          items: fixedFields,
        },
      ];

      setGarrariTechnicalDatas(finalData);
    }
  }, [productDesc, selectedVariant]);

  useEffect(() => {
    // GSAP animation for each card
    const cards = document.querySelectorAll(".technical-card");
    cards.forEach((card, index) => {
      const direction =
        index % 4 === 0
          ? "left"
          : index % 4 === 1
          ? "right"
          : index % 4 === 2
          ? "top"
          : "bottom";
      gsap.from(card, {
        x: direction === "left" ? -100 : direction === "right" ? 100 : 0,
        y: direction === "top" ? -100 : direction === "bottom" ? 100 : 0,
        opacity: 0,
        duration: 1,
        delay: index * 0.1, // Stagger the animation
      });
    });
  }, [GarrariTechnicalDatas]);

  return (
    <div className="p-4 md:p-8 md:pt-2 bg-white-1 min-h-screen">
      <div className="relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {GarrariTechnicalDatas.map((section, index) => (
            <div
              key={index}
              className={`bg-white-1 shadow-lg rounded-lg p-4 border border-gray-200 technical-card ${
                section.category === "Suspension" ? "h-auto" : "min-h-[200px]" // Adjust height for "Suspension" category
              }`}
              style={{ minHeight: "fit-content" }} // Dynamic height based on content
            >
              <h2 className="text-lg font-semibold text-blue mb-3">
                {section.category}
              </h2>
              <table className="w-full text-sm text-left border-collapse">
                <tbody>
                  {section.items.map((item, idx) => (
                    <tr
                      key={idx}
                      className={`${
                        idx % 2 === 0 ? "bg-gray-50" : "bg-white-1"
                      }`}
                    >
                      <td className="py-2 px-3 font-medium text-gray-700 border-b">
                        {item.key}
                      </td>
                      <td className="py-2 px-3 text-gray-600 border-b">
                        {item.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GarrariTechnicalData;
