import React, { useEffect, useState } from "react";
import "./MobileBasicDetail.css";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import { IMAGE_BASE } from "../../config";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import MobileFavShare from "./MobileFavShare";
import commingSoonLogo from "../../icons/badges/comming-soon.png";
import { selectUser } from "../../features/auth/authSlice";

function MobileBasicDetail({ selectProduct }) {
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState();
  const userDetail = useSelector(selectUser);
  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );
  useEffect(() => {
    if (selectProduct && selectProduct.length > 0) {
      const firstProduct = selectProduct[0] || [];
      setSelectedProduct(firstProduct);
      dispatch(
        setBreadcrumb({
          verticalID: firstProduct.Vertical_ID,
          verticalName: firstProduct.verticalName,
          categoryID: firstProduct.CategoryID,
          categoryName: firstProduct.categoryName,
          subCategoryID: firstProduct.sc_id,
          subCategoryName: firstProduct.subcategoryName,
          productID: firstProduct.prod_ID,
          productName: firstProduct.prod_name,
        })
      );
    }
  }, [selectProduct, dispatch]);

  const fullStars = isNaN(selectedProduct?.net_rating)
    ? 0
    : Math.floor(selectedProduct?.net_rating && selectedProduct?.net_rating);
  const hasHalfStar = isNaN(selectedProduct?.net_rating)
    ? 0
    : selectedProduct?.net_rating % 1 !== 0;

  const formatedBrandName = selectedProduct?.Manufacturer?.toUpperCase();
  return (
    <div className="mobile-basic-detail-container">
      <div className="mobile-basic-fav-share-container">
        <MobileFavShare selectedProduct={selectedProduct} />
      </div>
      <div className="mobile-basic-detail-brands-logo-container">
        {selectedProduct && selectedProduct?.manufacture_images ? (
          <img
            loading="lazy"
            src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
              selectedProduct && selectedProduct?.manufacture_images
            }`}
            alt=""
            className="mobile-basic-details-brand-logo"
          />
        ) : (
          <span className="mobile-basic-details-brand-name">
            {formatedBrandName}
          </span>
        )}
      </div>
      {/* {selectedProduct?.prod_ID?.startsWith("9") &&
        userDetail?.UserID === 1 && (
          <span
            className="product-container-exclusive-m"
            style={{
              color: "white",
              background: "var(--color-peach)",
              position: "absolute",
              right: "40%",
              padding: "1px",
              fontSize: "12px",
            }}
          >
            CCEXCLUSIVE
          </span>
        )} */}
      {/* {selectedProduct?.Manufacturer === "ESI" && (
        <img
          loading="lazy"
          src={commingSoonLogo}
          alt=""
          className="product-container-comming-soon"
          style={{ right: "40%" }}
        />
      )} */}
      <span className="text-black-1 text-[16px] font-bold tracking-wide">
        {selectedVariant?.prod_name && (
          <span className="mr-1">{selectedVariant?.prod_name + ","}</span>
        )}
        {selectedVariant && selectedVariant?.Tech_Data !== null ? (
          <span>{selectedVariant?.Tech_Data}</span>
        ) : (
          selectedProduct &&
          selectedProduct?.product_Tech &&
          selectedProduct?.product_Tech?.map((tech, index) => (
            <span key={index}>{tech}</span>
          ))
        )}
      </span>
      {/* <div className="mobile-basic-details-product-ratings-container">
        <span className="mobile-basic-details-product-ratings">
          {parseInt(selectedProduct?.net_rating).toFixed(1)}
        </span>
        <StarRoundedIcon />
      </div> */}
    </div>
  );
}

export default MobileBasicDetail;
