import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import BulkOrderProductSideBar from "./BulkOrderProductSideBar";
import { formatWithCommasWithDecimal } from "../../utils/utils";
import SearchIcon from "@mui/icons-material/Search";
import { IMAGE_BASE } from "../../config";
import toast from "react-hot-toast";

const BulkOrderTable = ({ filteredProducts, searchQuery, setSearchQuery }) => {
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  return (
    <TableContainer
      component={Paper}
      className="shadow-md border border-gray-300"
    >
      <Table className="min-w-full">
        {/* Table Header */}
        <TableHead>
          <TableRow
            style={{
              backgroundColor: "#283248",
              color: "white",
            }}
          >
            <TableCell
              className="font-bold w-[100px]"
              style={{
                color: "white",
                padding: "10px",
              }}
              align="center"
            >
              <Typography
                fontWeight="800"
                letterSpacing="1px"
                whiteSpace="nowrap"
              >
                HSN Code
              </Typography>
            </TableCell>
            <TableCell
              className="font-bold w-[100px]"
              style={{
                color: "white",
                padding: "10px",
              }}
              align="center"
            >
              <Typography
                fontWeight="800"
                letterSpacing="1px"
                whiteSpace="nowrap"
              >
                Brand
              </Typography>
            </TableCell>
            <TableCell
              className="font-bold w-[150px]"
              style={{
                color: "white",

                padding: "10px",
              }}
              align="center"
            >
              <Typography
                fontWeight="800"
                letterSpacing="1px"
                whiteSpace="nowrap"
              >
                Image
              </Typography>
            </TableCell>
            <TableCell
              className="font-bold w-[380px]"
              style={{
                color: "white",
                padding: "10px",
                textAlign: "center",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                fontWeight="800"
                letterSpacing="1px"
                whiteSpace="nowrap"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
              >
                Brand
              </Typography>
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  marginLeft: "20px",
                }}
              >
                <SearchIcon
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "8px",
                    transform: "translateY(-50%)",
                    fontSize: "16px",
                    color: "#ccc",
                  }}
                />
                <input
                  type="text"
                  placeholder="Search for product name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{
                    padding: "4px 8px 4px 28px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    height: "25px",
                    color: "black",
                    outline: "none",
                    fontSize: "12px",
                  }}
                />
              </div>
            </TableCell>
            <TableCell
              className="font-bold w-[100px]"
              style={{
                color: "white",

                padding: "10px",
              }}
              align="center"
            >
              <Typography
                fontWeight="800"
                letterSpacing="1px"
                whiteSpace="nowrap"
              >
                Wholesale Dealer Price
              </Typography>
            </TableCell>
            <TableCell
              className="font-bold w-[100px]"
              style={{
                color: "white",

                padding: "10px",
              }}
              align="center"
            >
              <Typography
                fontWeight="800"
                letterSpacing="1px"
                whiteSpace="nowrap"
              >
                MRP
              </Typography>
            </TableCell>
            <TableCell
              className="font-bold w-[200px]"
              style={{
                color: "white",

                padding: "10px",
              }}
              align="center"
            >
              <Typography
                fontWeight="800"
                letterSpacing="1px"
                whiteSpace="nowrap"
              >
                Quantity
              </Typography>
            </TableCell>
            <TableCell
              className="font-bold w-[300px]"
              style={{
                color: "white",

                padding: "10px",
              }}
              align="center"
            >
              <Typography
                fontWeight="800"
                letterSpacing="1px"
                whiteSpace="nowrap"
              >
                Total
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        {/* Table Body */}
        <TableBody>
          {filteredProducts?.map((data) => (
            <ProductRow data={data} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BulkOrderTable;

export const ProductRow = ({ data }) => {
  const [currentQuantity, setCurrentQuantity] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const resetQuantity = (qty) => setCurrentQuantity(qty);

  // Handler to increase quantity in multiples
  const increaseQuantity = () => {
    if (currentQuantity + data?.quantity <= data?.Available_quantity) {
      setCurrentQuantity(currentQuantity + data?.quantity);
    } else {
      toast.error(
        `Cannot exceed the maximum available quantity of ${data?.Available_quantity}`
      );
    }
  };

  // Handler to decrease quantity in multiples
  const decreaseQuantity = () => {
    if (currentQuantity - data?.quantity >= 0) {
      setCurrentQuantity(currentQuantity - data?.quantity);
    } else {
      toast.error(`Minimum quantity is ${data?.quantity}`);
    }
  };

  const calculateNetTotal = (data) => {
    const total = parseInt(data?.Bulk_NDP_Price) * currentQuantity;
    const gstAmount = (total * data?.GST) / 100;
    return total + gstAmount;
  };

  const openSidebar = (SKU_ID) => {
    setSelectedProduct(SKU_ID);
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
    setSelectedProduct(null);
  };
  return (
    <TableRow key={data?.SKU_ID}>
      <TableCell className="border border-gray-300 w-[100px]" align="center">
        {data?.HSN_code}
      </TableCell>
      <TableCell className="border border-gray-300 w-[100px]" align="center">
        <img
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${data?.manufacture_images}`}
          alt="Product"
          width={50}
        />
      </TableCell>
      <TableCell className="border border-gray-300 w-[150px]" align="center">
        <img
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
            data?.Image?.length && data?.Image[0]
          }`}
          alt="Product"
          width={100}
        />
      </TableCell>
      <TableCell
        className="border border-gray-300 w-[350px]"
        align="start"
        onClick={() => openSidebar(data?.SKU_ID)}
      >
        {/* Product Name */}
        {data?.prod_name}

        {/* Color Ball */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: "5px",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              marginRight: "8px",
              fontSize: "12px",
              color: "black",
            }}
          >
            Color:
          </Typography>
          <Box
            sx={{
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: "black",
              border: "1px solid #ccc",
            }}
          ></Box>
        </Box>
      </TableCell>

      <TableCell className="border border-gray-300 w-[100px]" align="center">
        ₹{formatWithCommasWithDecimal(parseInt(data?.Bulk_NDP_Price))}
      </TableCell>
      <TableCell className="border border-gray-300 w-[100px]" align="center">
        ₹{formatWithCommasWithDecimal(parseInt(data?.MRP_price))}
      </TableCell>
      <TableCell
        className="border border-gray-300"
        align="center"
        sx={{ position: "relative", minWidth: "200px" }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button
            variant="contained"
            size="small"
            sx={{
              width: "30px",
              height: "30px",
              minWidth: "unset",
              fontSize: "25px",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px 0 0 5px",
              justifyContent: "center",
              backgroundColor:
                currentQuantity > 0 ? "var(--color-peach)" : "#e09eaa",
              color: "#fff",
              "&:hover": {
                backgroundColor: currentQuantity > 0 ? "#a83232" : "#e09eaa",
              },
              cursor: currentQuantity > 0 ? "pointer" : "not-allowed",
            }}
            onClick={currentQuantity > 0 ? decreaseQuantity : null}
            // disabled={currentQuantity <= 0}
          >
            -
          </Button>

          {/* Quantity Display */}
          <Box
            display="flex"
            alignItems="center"
            backgroundColor="#e3e3e3"
            height="30px"
            boxShadow="0px 2px 2px 0px rgba(0, 0, 0, 0.14)"
          >
            <Typography
              variant="subtitle1"
              fontSize="18px"
              sx={{ margin: "0 15px" }}
            >
              {currentQuantity}
            </Typography>
          </Box>

          <Button
            variant="contained"
            size="small"
            sx={{
              width: "30px",
              height: "30px",
              minWidth: "unset",
              fontSize: "25px",
              borderRadius: "0 5px 5px 0",
              backgroundColor:
                currentQuantity < data?.Available_quantity
                  ? "var(--color-green)"
                  : "#a2deb1",
              color: "#fff",
              "&:hover": {
                backgroundColor:
                  currentQuantity < data?.Available_quantity
                    ? "darkgreen"
                    : "#a2deb1",
              },
              cursor:
                currentQuantity < data?.Available_quantity
                  ? "pointer"
                  : "not-allowed",
            }}
            onClick={
              currentQuantity < data?.Available_quantity
                ? increaseQuantity
                : null
            }
            // disabled={currentQuantity >= data?.Available_quantity}
          >
            +
          </Button>

          <IconButton
            sx={{
              color: "var(--color-peach)",
              marginLeft: "0",

              "&:hover": {
                color: "#a83232",
              },
            }}
            aria-label="Reset quantity"
            onClick={() => resetQuantity(0)}
          >
            <RefreshIcon style={{ fontSize: "15px" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            fontSize: "10px",
            color: "gray",
            position: "absolute",
            bottom: "10px",
            left: "10px",
          }}
        >
          <b>Note:</b> Available with Bundle of {data?.quantity} items
        </Box>
      </TableCell>

      <TableCell className="border border-gray-300 w-[300px]" align="center">
        <Box display="flex" flexDirection="column" gap={1}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography align="start" fontSize="15px">
              Wholesale Dealer Price:
            </Typography>
            <Typography align="end" fontSize="15px">
              ₹
              {formatWithCommasWithDecimal(
                parseInt(data?.Bulk_NDP_Price) * data?.quantity
              )}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography align="start" fontSize="15px">
              GST:
            </Typography>
            <Typography align="end" fontSize="15px">
              {data?.GST}%
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight="bold" align="start" fontSize="16px">
              Net Total:
            </Typography>
            <Typography fontWeight="bold" align="end" fontSize="16px">
              ₹{formatWithCommasWithDecimal(calculateNetTotal(data))}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <BulkOrderProductSideBar
        isOpen={isSidebarOpen}
        onClose={closeSidebar}
        product={data}
        currentQuantity={currentQuantity}
      />
    </TableRow>
  );
};
