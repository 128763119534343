import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductDescriptionDetailAsync,
  selectProductDescription,
} from "../../../features/productDesc/productDescSlice";
import { IMAGE_BASE } from "../../../config";
import noProductLogo from "../../../img/no-products-logo.png";

const GarrariBrandDesc = ({ productDesc }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(null);

  const productDescription = useSelector(selectProductDescription)?.data;

  useEffect(() => {
    if (productDesc && productDesc?.length > 0) {
      dispatch(fetchProductDescriptionDetailAsync(productDesc[0]?.prod_ID));
    }
  }, [dispatch, productDesc]);

  useEffect(() => {
    if (productDescription?.descriptions?.length > 0) {
      setActiveTab(productDescription?.descriptions[0]?.key);
    }
  }, [productDescription]);

  useEffect(() => {
    if (activeTab) {
      const tl = gsap.timeline();
      tl.fromTo(
        ".tab-content",
        { opacity: 0, x: 50 },
        { opacity: 1, x: 0, duration: 0.5, ease: "power2.out" }
      );
      return () => {
        gsap.to(".tab-content", { opacity: 0, x: -50, duration: 0.3 });
      };
    }
  }, [activeTab]);

  // Auto-switch tabs every 10 seconds
  useEffect(() => {
    if (productDescription?.descriptions?.length > 0) {
      const interval = setInterval(() => {
        setActiveTab((currentTab) => {
          const currentIndex = productDescription?.descriptions?.findIndex(
            (desc) => desc?.key === currentTab
          );
          const nextIndex =
            (currentIndex + 1) % productDescription?.descriptions?.length;
          return productDescription?.descriptions[nextIndex]?.key;
        });
      }, 20000);

      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [productDescription]);

  const { descriptions } = productDescription || {};

  //   console.log(productDescription);

  return (
    <div className="w-full flex flex-col">
      {productDescription && productDescription !== undefined ? (
        <div className="w-full flex flex-col h-auto p-0">
          <div className="w-full relative pb-5 mb-5 border-b-2 border-gray-200">
            <h1 className="text-2xl font-extrabold text-gray-800 uppercase">
              <span
                className="block text-transparent bg-clip-text bg-gradient-to-b from-peach/40 via-peach/90 to-peach text-3xl opacity-80 transform translate-x-[-100%] animate-slide-left-2"
                style={{ animationDelay: "0.5s" }}
              >
                {productDesc?.[0]?.prod_name || "Unknown Product"}
              </span>
            </h1>
            <p className="mt-[2px] !text-[16px] text-gray-600">
              {productDescription?.intro ||
                "No introduction available for this product."}
            </p>
          </div>

          <div className="w-full flex items-start relative">
            {/* Left Section */}
            <div className="w-full pl-4 sm:w-2/5 flex flex-col space-y-0">
              {descriptions?.length > 0 && (
                <div className="relative space-y-4">
                  {/* Sliding Indicator */}
                  <div
                    className="absolute top-[1.2rem] left-0 w-1 bg-green/80 transition-transform duration-300"
                    style={{
                      height: "50px",
                      transform: `translateY(${
                        descriptions.findIndex(
                          (spec) => spec?.key === activeTab
                        ) * 72
                      }px)`,
                    }}
                  ></div>
                  {descriptions.map((spec) => (
                    <div key={spec?.key} className="flex flex-col">
                      <button
                        className={`w-fit flex items-center space-x-3 pl-4 p-2 relative ${
                          activeTab === spec?.key
                            ? "text-green/80"
                            : "text-black-1"
                        }`}
                        onClick={() => setActiveTab(spec?.key)}
                      >
                        <img
                          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_SUBCATEGORY_IMAGE/${spec?.icon}`}
                          alt={spec?.key}
                          className={`w-10 h-10 bg-white-1 rounded-full ${
                            activeTab === spec?.key
                              ? "border-2 border-green/80"
                              : "border-[1px] border-gray-200"
                          }`}
                        />
                        <span className="text-base font-[Raleway]">
                          {spec?.key}
                        </span>
                      </button>
                      <div
                        className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
                          activeTab === spec?.key ? "" : "max-h-0"
                        }`}
                      >
                        <ul className="pl-16 mt-2 text-[13px] space-y-1">
                          {spec?.value?.map((keyPoint, index) => {
                            const [boldText, ...rest] = keyPoint.split(":");
                            return (
                              <li
                                key={index}
                                className="relative text-gray-600 before:content-[''] before:absolute before:rotate-90 before:top-[8px] before:left-[-15px] before:w-2 before:h-[2px] before:bg-green before:transform before:-translate-y-1/2"
                              >
                                <span className="font-bold text-black-1">
                                  {boldText}:
                                </span>
                                {rest.join(":")}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Right Section */}
            <div className="w-full sm:w-3/5 max-md:pl-3 flex items-center justify-center mt-6 md:mt-0">
              {descriptions?.length > 0 &&
                activeTab &&
                descriptions.map(
                  (spec) =>
                    activeTab === spec?.key && (
                      <div key={spec?.key} className="text-center tab-content">
                        <img
                          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCT_DESCRIPTION_IMAGE/${spec?.image}`}
                          alt={spec?.key}
                          className="w-full h-auto max-h-[500px] object-contain mb-4 rounded-lg"
                        />
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      ) : (
        <div className="min-h-[300px] flex flex-col items-center justify-center text-gray-500 text-center">
          <img
            src={noProductLogo}
            alt="no-product-logo"
            className="w-[150px] h-[150px] mb-3"
          />
          <span className="text-black-1/70 font-semibold tracking-wider">
            No image or description available for this product.
          </span>
        </div>
      )}
    </div>
  );
};

export default GarrariBrandDesc;
