import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchDealersPrductDetails,
  fetchKeySpecificationForBikes,
  fetchPrductDetails,
  fetchProductComparision,
  fetchProductDescriptionDetail,
} from "./productDescAPI";

const initialState = {
  productDetails: [],
  status: "idle",
  selectedProd_ID: null,
  selectedVariant: [],
  prouductComparison:[],
  productDescription:[],
  keySpecification:[],
};

export const fetchProductDetailsAsync = createAsyncThunk(
  "productDetail/fetchProductDetails",
  async (prod_ID) => {
    const response = await fetchPrductDetails(prod_ID);

    return response?.data;
  }
);
export const fetchDealerProductDetailsAsync = createAsyncThunk(
  "productDetail/fetchDealerProductDetails",
  async (prod_ID) => {
    const response = await fetchDealersPrductDetails(prod_ID);

    return response?.data;
  }
);

export const fetchProductComparisionAsync = createAsyncThunk(
  "productDetail/fetchProductComparision",
  async (prod_ID) => {
    const response = await fetchProductComparision(prod_ID);

    return response?.data;
  }
);

export const fetchProductDescriptionDetailAsync = createAsyncThunk(
  "productDetail/fetchProductDescriptionDetail",
  async (prod_ID) => {
    const response = await fetchProductDescriptionDetail(prod_ID);

    return response?.data;
  }
);

export const fetchKeySpecificationForBikesAsync = createAsyncThunk(
  "productDetail/fetchKeySpecificationForBikes",
  async (prod_ID) => {
    const response = await fetchKeySpecificationForBikes(prod_ID);

    return response?.data;
  }
);

export const setSelectedProd_ID = (prod_ID) => (dispatch) => {
  dispatch(productDetailSlice.actions.setSelectedProd_ID(prod_ID));
};

export const productDetailSlice = createSlice({
  name: "productDetail",
  initialState,
  reducers: {
    setSelectedProd_ID: (state, action) => {
      state.selectedProd_ID = action.payload;
    },
    setSelectedVariant: (state, action) => {
      state.selectedVariant = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductDetailsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productDetails = action.payload;
      })
      .addCase(fetchDealerProductDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDealerProductDetailsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productDetails = action.payload;
      })
      .addCase(fetchProductComparisionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductComparisionAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.prouductComparison = action.payload;
      })
      .addCase(fetchProductDescriptionDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductDescriptionDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productDescription = action.payload;
      })
      .addCase(fetchKeySpecificationForBikesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchKeySpecificationForBikesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.keySpecification = action.payload;
      });
  },
});

export const selectProductDetails = (state) =>
  state.productDetail.productDetails;

export const selectProductComparision = (state) =>
  state.productDetail.prouductComparison;
export const selectProductDescription = (state) =>
  state.productDetail.productDescription;
export const selectKeySpecification = (state) =>
  state.productDetail.keySpecification;

export const { setSelectedVariant } = productDetailSlice.actions;

export default productDetailSlice.reducer;
