import React, { useState } from "react";
import "./AdminDashboard.css";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import { useNavigate } from "react-router";
import AdminPreOrder from "./AdminPreOrder";
import AdminOrders from "./AdminOrders";
import AdminCarts from "./AdminCarts";
import AdminToggle from "./AdminToggle";
import AdminShipping from "./AdminShipping";
import AdminLOC from "./AdminLOC";
import adminLogo from "../../assets/adminLogo.svg";
import preorderLogo from "../../assets/Icon-preorder.svg";
import orderLogo from "../../assets/Icon-orders.svg";
import shippingLogo from "../../assets/shippingLogo.svg";
import lineOfCreditLogo from "../../assets/Icon-LOC1.svg";
import couponLogo from "../../assets/coupon-icon.svg";
import saleLogo from "../../assets/sale.svg";
import cartLogo from "../../assets/cartLogo.svg";
import backLogo from "../../assets/backLogo.svg";
import AdminInventory from "./AdminInventory";
import inventoryIcon from "../../assets/inventory.svg";
import collectionIcon from "../../assets/collections.svg";
import toggleIcon from "../../assets/product-toggle.svg";
import bulkOrderIcon from "../../assets/bulkOrder-icon.svg";
import AdminCollections from "./AdminCollections";
import AdminCoupons from "./AdminCoupons";
import AdminSale from "./AdminSale";
import AdminProductToggle from "./AdminProductToggle";
import AdminBulkOrder from "./AdminBulkOrder";

function AdminDashboard() {
  const navigate = useNavigate();
  const [nav, setNav] = useState("orders");

  const renderContent = () => {
    switch (nav) {
      case "pre-orders":
        return <AdminPreOrder />;
      case "orders":
        return <AdminOrders />;
      case "carts":
        return <AdminCarts />;
      case "toggle":
        return <AdminToggle />;
      case "credit":
        return <AdminLOC />;
      case "shipping":
        return <AdminShipping />;
      case "inventory":
        return <AdminInventory />;
        case "collection":
        return <AdminCollections />;
        case "coupon":
        return <AdminCoupons />;
        case "sale":
        return <AdminSale />;
        case "productToggle":
        return <AdminProductToggle />;
        case "bulkOrder":
          return <AdminBulkOrder/>
      default:
        return <AdminPreOrder />;
    }
  };

  return (
    <div className="admin-dashboard-preorder-container">
      <div className="admin-dashboard-left-container">
        <img
          loading="lazy"
          src={cyclecircleLogo}
          alt=""
          className="admin-dashboard-cc-logo"
          onClick={() => navigate("/")}
        />
        <span
          className="admin-dashboard-back-to-home"
          onClick={() => navigate("/")}
        >
          <img
            loading="lazy"
            src={backLogo}
            alt="order-logo"
            width={25}
            height={25}
            className="mr-2"
          />{" "}
          Back to home
        </span>
        <div className="admin-dashboard-navigator">
          <span className="admin-dashboard-heading">NAVIGATION</span>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("pre-orders")}
            style={{
              background: nav === "pre-orders" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <img
              loading="lazy"
              src={preorderLogo}
              alt="preorder-logo"
              width={25}
              height={25}
              className="mr-2"
            />
            <span className="admin-dashboard-navigator-name">Pre-Orders</span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("orders")}
            style={{
              background: nav === "orders" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <img
              loading="lazy"
              src={orderLogo}
              alt="order-logo"
              width={25}
              height={25}
              className="mr-2"
            />
            <span className="admin-dashboard-navigator-name">Orders</span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("carts")}
            style={{
              background: nav === "carts" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <img
              loading="lazy"
              src={cartLogo}
              alt="cart-logo"
              width={25}
              height={25}
              className="mr-2"
            />
            <span className="admin-dashboard-navigator-name">Carts</span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("toggle")}
            style={{
              background: nav === "toggle" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <img
              loading="lazy"
              src={adminLogo}
              alt="admin-logo"
              width={25}
              height={25}
              className="mr-2"
            />
            <span className="admin-dashboard-navigator-name">
              Switch to Admin
            </span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("shipping")}
            style={{
              background: nav === "shipping" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <img
              loading="lazy"
              src={shippingLogo}
              alt="shipping-logo"
              width={25}
              height={25}
              className="mr-2"
            />
            <span className="admin-dashboard-navigator-name">
              Free Shipping
            </span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("credit")}
            style={{
              background: nav === "credit" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <img
              loading="lazy"
              src={lineOfCreditLogo}
              alt="loc-logo"
              width={25}
              height={25}
              className="mr-2"
            />
            <span className="admin-dashboard-navigator-name">
              Line of Credit
            </span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("inventory")}
            style={{
              background: nav === "inventory" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <img
              loading="lazy"
              src={inventoryIcon}
              alt="loc-logo"
              width={25}
              height={25}
              className="mr-2"
            />
            <span className="admin-dashboard-navigator-name">
              All Inventory
            </span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("collection")}
            style={{
              background: nav === "collection" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <img
              loading="lazy"
              src={collectionIcon}
              alt="loc-logo"
              width={25}
              height={25}
              className="mr-2"
            />
            <span className="admin-dashboard-navigator-name">
              Collections
            </span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("coupon")}
            style={{
              background: nav === "coupon" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <img
              loading="lazy"
              src={couponLogo}
              alt="loc-logo"
              width={25}
              height={25}
              className="mr-2"
            />
            <span className="admin-dashboard-navigator-name">
              Coupons
            </span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("sale")}
            style={{
              background: nav === "sale" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <img
              loading="lazy"
              src={saleLogo}
              alt="loc-logo"
              width={25}
              height={25}
              className="mr-2"
            />
            <span className="admin-dashboard-navigator-name">
              Sale
            </span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("productToggle")}
            style={{
              background: nav === "productToggle" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <img
              loading="lazy"
              src={toggleIcon}
              alt="loc-logo"
              width={25}
              height={25}
              className="mr-2"
            />
            <span className="admin-dashboard-navigator-name">
              Product Visibility
            </span>
          </div>
          <div
            className="admin-dashboard-navigate"
            onClick={() => setNav("bulkOrder")}
            style={{
              background: nav === "bulkOrder" ? "rgb(228, 219, 219)" : "white",
            }}
          >
            <img
              loading="lazy"
              src={bulkOrderIcon}
              alt="loc-logo"
              width={25}
              height={25}
              className="mr-2"
            />
            <span className="admin-dashboard-navigator-name">
              Bulk Order
            </span>
          </div>
        </div>
      </div>
      <div className="admin-dashboard-right-container">{renderContent()}</div>
    </div>
  );
}

export default AdminDashboard;
