import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_BASE } from "../../config";
import noProductLogo from "../../img/no-products-logo.png";
import {
  fetchProductDescriptionDetailAsync,
  selectProductDescription,
} from "../../features/productDesc/productDescSlice";

const GarrariBrandDescMobile = ({ productDesc }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(null);
  const tabRef = useRef(null);

  const productDescription = useSelector(selectProductDescription)?.data;

  useEffect(() => {
    if (productDesc && productDesc?.length > 0) {
      dispatch(fetchProductDescriptionDetailAsync(productDesc[0]?.prod_ID));
    }
  }, [dispatch, productDesc]);

  useEffect(() => {
    if (productDescription?.descriptions?.length > 0) {
      setActiveTab(productDescription?.descriptions[0]?.key);
    }
  }, [productDescription]);

  useEffect(() => {
    if (activeTab) {
      const tl = gsap.timeline();
      tl.fromTo(
        ".tab-content",
        { opacity: 0, x: 50 },
        { opacity: 1, x: 0, duration: 0.5, ease: "power2.out" }
      );
      return () => {
        gsap.to(".tab-content", { opacity: 0, x: -50, duration: 0.3 });
      };
    }
  }, [activeTab]);

  useEffect(() => {
    if (productDescription?.descriptions?.length > 0) {
      const interval = setInterval(() => {
        setActiveTab((currentTab) => {
          const currentIndex = productDescription?.descriptions?.findIndex(
            (desc) => desc?.key === currentTab
          );
          const nextIndex =
            (currentIndex + 1) % productDescription?.descriptions?.length;
          return productDescription?.descriptions[nextIndex]?.key;
        });
      }, 20000);

      return () => clearInterval(interval);
    }
  }, [productDescription]);

  useEffect(() => {
    if (tabRef.current && activeTab) {
      const activeTabElement = document.querySelector(
        `[data-tab-key="${activeTab}"]`
      );
      if (activeTabElement) {
        const parentElement = tabRef.current;
        const parentRect = parentElement.getBoundingClientRect();
        const activeRect = activeTabElement.getBoundingClientRect();

        if (
          activeRect.right > parentRect.right ||
          activeRect.left < parentRect.left
        ) {
          activeTabElement.scrollIntoView({
            behavior: "smooth",
            inline: "center",
          });
        }
      }
    }
  }, [activeTab]);

  const { descriptions } = productDescription || {};

  return (
    <div className="w-full flex flex-col px-4 py-6 bg-gray-50">
      {productDescription && productDescription !== undefined ? (
        <div className="flex flex-col">
          {/* Header Section */}
          <div className="text-center pb-4 border-b border-gray-200">
            <h1 className="text-xl font-extrabold text-gray-800 uppercase block text-transparent bg-clip-text bg-gradient-to-b from-peach/40 via-peach/90 to-peach opacity-80 transform translate-x-[-100%] animate-slide-left-2">
              {productDesc?.[0]?.prod_name || "Unknown Product"}
            </h1>
            <p className="mt-2 text-sm text-gray-600">
              {productDescription?.intro ||
                "No introduction available for this product."}
            </p>
          </div>

          {/* Tabs and Content Section */}
          <div className="flex flex-col mt-4 space-y-6">
            {/* Tabs */}
            <div
              ref={tabRef}
              className="flex overflow-x-auto space-x-4 pb-2 scroll-smooth"
            >
              {descriptions?.map((spec) => (
                <button
                  key={spec?.key}
                  data-tab-key={spec?.key}
                  className={`flex-none px-4 py-2 text-sm rounded-full whitespace-nowrap shadow-md transition-colors duration-300 ${
                    activeTab === spec?.key
                      ? "bg-green-600 text-white-1"
                      : "bg-white text-gray-800 border border-gray-200"
                  }`}
                  onClick={() => setActiveTab(spec?.key)}
                >
                  {spec?.key}
                </button>
              ))}
            </div>

            {/* Content */}
            <div className="tab-content flex flex-col items-center">
              {descriptions?.length > 0 &&
                activeTab &&
                descriptions.map(
                  (spec) =>
                    activeTab === spec?.key && (
                      <div key={spec?.key} className="text-center">
                        <img
                          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCT_DESCRIPTION_IMAGE/${spec?.image}`}
                          alt={spec?.key}
                          className="w-full h-auto max-h-60 object-contain rounded-md mb-4"
                        />
                        <ul className="space-y-2">
                          {spec?.value?.map((keyPoint, index) => {
                            const [boldText, ...rest] = keyPoint.split(":");
                            return (
                              <li
                                key={index}
                                className="text-sm text-gray-600 flex items-start space-x-2"
                              >
                                <span className="border-2 border-green mr-1 mt-2 bg-green rounded-full"></span>
                                <span className="text-start">
                                  <span className="font-bold text-gray-800">
                                    {boldText}:
                                  </span>{" "}
                                  {rest.join(":")}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      ) : (
        <div className="min-h-[300px] flex flex-col items-center justify-center text-gray-500 text-center">
          <img
            src={noProductLogo}
            alt="no-product-logo"
            className="w-[100px] h-[100px] mb-3"
          />
          <span className="text-black-1/70 font-semibold tracking-wider">
            No image or description available for this product.
          </span>
        </div>
      )}
    </div>
  );
};

export default GarrariBrandDescMobile;
