import React, { useEffect, useState } from "react";
import "./SideFilterComponent.css";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

import ShippingInfoMode from "./ShippingInfoMode";
import PriceRangeSlider from "./PriceRangeSlider";
import { connect, useSelector } from "react-redux";
import {
  setShippingMode,
  setPriceRange,
  setRatings,
  setDealerMargins,
  setShopByOffers,
  clearAllFilters,
} from "../../../store/slices/listingPageFilterSlice";
import { useDispatch } from "react-redux";
import {
  setRating,
  setDealerMargin,
  setShopByOffer,
  setOutOfStock,
  setMinPrice,
  setMaxPrice,
  setShipping,
  selectOutOfStock,
} from "../../../store/slices/allFilterSlice";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function AllFilterComponent({
  shippingMode,
  priceRange,
  ratings,
  dealerMargins,
  shopByOffers,
  setShippingMode,
  setPriceRange,
  setRatings,
  setDealerMargins,
  setShopByOffers,
  clearAllFilters,
}) {
  const [showShippingInfo, setShowShippingInfo] = useState(false);
  const [showPriceRange, setShowPriceRange] = useState(true);
  const [showCustomerRatings, setShowCustomerRatings] = useState(true);
  const [showDealerMargin, setShowDealerMargin] = useState(true);
  const [showByOffer, setShowByOffer] = useState(true);
  const outOfStock = useSelector(selectOutOfStock);

  const dispatch = useDispatch();

  useEffect(() => {
    if (ratings) {
      dispatch(setRating(parseInt(ratings)));
    }
    if (dealerMargins) {
      dispatch(setDealerMargin(parseInt(dealerMargins)));
    }
    if (shopByOffers) {
      dispatch(setShopByOffer(shopByOffers));
    }
    if (priceRange) {
      const prices = priceRange
        .replace(/₹/g, "") // Remove "₹" from the string
        .split(" - ");

      // Convert the values to numbers
      const minPrice = parseInt(prices[0], 10);
      const maxPrice = parseInt(prices[1], 10);
      dispatch(setMinPrice(minPrice));
      dispatch(setMaxPrice(maxPrice));
    }
    if (shippingMode) {
      dispatch(setShipping(shippingMode));
    }
  }, [
    dispatch,
    ratings,
    dealerMargins,
    shopByOffers,
    priceRange,
    shippingMode,
  ]);

  const openShippingInfo = () => {
    setShowShippingInfo((prev) => !prev);
    setTimeout(() => {
      setShowShippingInfo(false);
    }, 4000);
  };

  const handleRatingChange = (e) => {
    if (e.target.value === ratings) {
      setRatings(null);
    } else {
      setRatings(e.target.value);
    }
  };

  const handleOutOfStock = () => {
    // Toggle outOfStock value and dispatch the action
    const newOutOfStock = !outOfStock;
    dispatch(setOutOfStock(newOutOfStock));
  };
  

  const handleDealerMarginChange = (e) => {
    if (e.target.value === dealerMargins) {
      setDealerMargins(null);
      dispatch(setDealerMargin(0));
    } else {
      setDealerMargins(e.target.value);
    }
  };

  const handleShopByOffers = (e) => {
    if (e.target.value === shopByOffers) {
      setShopByOffers(null);
      dispatch(setShopByOffer(""));
    } else {
      setShopByOffers(e.target.value);
    }
  };

  return (
    <>
      <div
        className="info-shippingInfoMode-container"
        style={{ opacity: showShippingInfo ? 1 : 0 }}
      >
        {showShippingInfo && <ShippingInfoMode onClose={openShippingInfo} />}
      </div>
      <div className="allFilterComponent-container">
        <div className="allFilterComponent-scroll-div">
          <div className="allFilterComponent-price-range-container">
            <div className="allFilterComponent-price-range-heading-container">
              <span className="allFilterComponent-price-range-heading">
                Price Range
              </span>
              {showPriceRange && (
                <RemoveIcon onClick={() => setShowPriceRange(false)} />
              )}
              {!showPriceRange && (
                <AddIcon onClick={() => setShowPriceRange(true)} />
              )}
            </div>
            {showPriceRange && (
              <div className="allFilterComponent-price-range-filter-select-container">
                <PriceRangeSlider />
              </div>
            )}
          </div>
          <div className="allFilterComponent-customer-ratings">
            {showCustomerRatings && (
              <div className="w-full py-0">
                <div className="allFilterComponent-customer-rating-div-row flex items-center">
                  <input
                    type="checkbox"
                    checked={outOfStock === false}
                    onChange={handleOutOfStock}
                    className="mr-2"
                  />
                  <span className="!font-semibold">Exclude Out of Stock</span>
                </div>
              </div>
            )}
          </div>
          <div className="allFilterComponent-customer-ratings">
            <div className="allFilterComponent-customer-ratings-heading-container">
              <span className="allFilterComponent-customer-ratings-heading">
                Customer Rating
              </span>
              {showCustomerRatings && (
                <RemoveIcon onClick={() => setShowCustomerRatings(false)} />
              )}
              {!showCustomerRatings && (
                <AddIcon onClick={() => setShowCustomerRatings(true)} />
              )}
            </div>
            {showCustomerRatings && (
              <div className="allFilterComponent-customer-ratings-div-container">
                {[4, 3, 2, 1].map((value) => (
                  <div
                    key={value}
                    className="allFilterComponent-customer-rating-div-row"
                  >
                    <input
                      type="radio"
                      name="ratings"
                      value={value}
                      checked={parseInt(ratings) === value}
                      onClick={handleRatingChange}
                    />
                    <div className="input-star-div-container">
                      {value}
                      <StarRoundedIcon />
                    </div>
                    <span style={{ fontWeight: "600" }}> & Above</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="allFilterComponent-dealer-margin-container">
            <div className="allFilterComponent-dealer-margin-heading-container">
              <span className="allFilterComponent-dealer-margin-heading">
                Dealer Margin
              </span>
              {showDealerMargin && (
                <RemoveIcon onClick={() => setShowDealerMargin(false)} />
              )}
              {!showDealerMargin && (
                <AddIcon onClick={() => setShowDealerMargin(true)} />
              )}
            </div>
            {showDealerMargin && (
              <div className="allFilterComponent-dealer-margin-div-container">
                {[50, 40, 30, 20, 10].map((value) => (
                  <div
                    key={value}
                    className="allFilterComponent-dealer-margin-div-row"
                  >
                    <input
                      type="radio"
                      name="dealerMargins"
                      value={value}
                      checked={parseInt(dealerMargins) === value}
                      onClick={handleDealerMarginChange}
                    />
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      {value}% &nbsp; & Above{" "}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            className="allFilterComponent-dealer-margin-container"
            style={{ border: "none" }}
          >
            <div className="allFilterComponent-dealer-margin-heading-container">
              <span className="allFilterComponent-dealer-margin-heading">
                Shop By Offer
              </span>
              {showByOffer && (
                <RemoveIcon onClick={() => setShowByOffer(false)} />
              )}
              {!showByOffer && <AddIcon onClick={() => setShowByOffer(true)} />}
            </div>
            {showByOffer && (
              <div className="allFilterComponent-dealer-margin-div-container">
                <div className="allFilterComponent-dealer-margin-div-row">
                  <input
                    type="radio"
                    name="showByOffers"
                    value="Exclusive"
                    checked={shopByOffers === "Exclusive"}
                    onClick={handleShopByOffers}
                  />
                  <span style={{ fontWeight: "600" }}> Exclusive</span>
                </div>
                <div className="allFilterComponent-dealer-margin-div-row">
                  <input
                    type="radio"
                    name="showByOffers"
                    value="New Launch"
                    checked={shopByOffers === "New Launch"}
                    onClick={handleShopByOffers}
                  />
                  <span style={{ fontWeight: "600" }}> New Launch</span>
                </div>
                <div className="allFilterComponent-dealer-margin-div-row">
                  <input
                    type="radio"
                    name="showByOffers"
                    value="Price Drop"
                    checked={shopByOffers === "Price Drop"}
                    onClick={handleShopByOffers}
                  />
                  <span style={{ fontWeight: "600" }}> Price Drop</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  shippingMode: state.listingPageFilter.shippingMode,
  priceRange: state.listingPageFilter.priceRange,
  ratings: state.listingPageFilter.ratings,
  dealerMargins: state.listingPageFilter.dealerMargins,
  shopByOffers: state.listingPageFilter.shopByOffers,
});

const mapDispatchToProps = {
  setShippingMode,
  setPriceRange,
  setRatings,
  setDealerMargins,
  setShopByOffers,
  clearAllFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllFilterComponent);
