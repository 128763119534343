import React, { useState } from "react";
import { useNavigate } from "react-router";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import {
  Drawer,
  IconButton,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";
import adminLogo from "../../assets/adminLogo.svg";
import preorderLogo from "../../assets/Icon-preorder.svg";
import orderLogo from "../../assets/Icon-orders.svg";
import shippingLogo from "../../assets/shippingLogo.svg";
import lineOfCreditLogo from "../../assets/Icon-LOC1.svg";
import toggleIcon from "../../assets/product-toggle.svg";
import cartLogo from "../../assets/cartLogo.svg";
import AdminLOC from "../../components/AdminDashboard/AdminLOC";
import AdminShipping from "../../components/AdminDashboard/AdminShipping";
import AdminToggle from "../../components/AdminDashboard/AdminToggle";
import AdminPreOrder from "../../components/AdminDashboard/AdminPreOrder";
import AdminOrders from "../../components/AdminDashboard/AdminOrders";
import AdminCarts from "../../components/AdminDashboard/AdminCarts";
import AdminInventory from "../../components/AdminDashboard/AdminInventory";
import inventoryIcon from "../../assets/inventory.svg";
import collectionIcon from "../../assets/collections.svg";
import AdminCollections from "../../components/AdminDashboard/AdminCollections";
import AdminCoupons from "../../components/AdminDashboard/AdminCoupons";
import couponIcon from "../../assets/coupon-icon.svg";
import AdminSale from "../../components/AdminDashboard/AdminSale";
import saleIcon from "../../assets/sale.svg";
import bulkOrderIcon from "../../assets/bulkOrder-icon.svg";
import AdminProductToggle from "../../components/AdminDashboard/AdminProductToggle";
import AdminBulkOrder from "../../components/AdminDashboard/AdminBulkOrder";

function MobileAdminDashboard() {
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const [nav, setNav] = useState("orders");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const renderContent = () => {
    switch (nav) {
      case "pre-orders":
        return <AdminPreOrder />;
      case "orders":
        return <AdminOrders />;
      case "carts":
        return <AdminCarts />;
      case "toggle":
        return <AdminToggle />;
      case "credit":
        return <AdminLOC />;
      case "shipping":
        return <AdminShipping />;
      case "inventory":
        return <AdminInventory />;
      case "collection":
        return <AdminCollections />;
      case "coupon":
        return <AdminCoupons />;
      case "sale":
        return <AdminSale />;
      case "productToggle":
        return <AdminProductToggle />;
      case "bulkOrder":
        return <AdminBulkOrder />;
      default:
        return <AdminPreOrder />;
    }
  };
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="w-full min-h-screen flex flex-col relative">
      <AppBar
        position="fixed"
        className="bg-blue z-10 w-full h-14 flex items-center justify-between"
      >
        <Toolbar className="w-full flex items-center justify-between bg-blue">
          <img
            loading="lazy"
            src={cyclecircleLogo}
            alt="cc-logo"
            className="h-9 cursor-pointer"
            onClick={() => navigate("/")}
          />
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            className="ml-auto"
          >
            <MenuIcon style={{ fontSize: "30px" }} />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer (Sidebar) */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        sx={{ "& .MuiDrawer-paper": { width: "55vw" } }}
      >
        <div className="p-2 bg-gray-200">
          <Avatar sx={{ width: 56, height: 56, margin: "5px auto" }}>
            <img
              loading="lazy"
              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
                userDetail?.UserImage || ""
              }`}
              alt={userDetail?.UserImage || ""}
              className="w-full"
            />
          </Avatar>
          <h3 className="text-center my-2">
            {userDetail?.FirstName}&nbsp;{userDetail?.LastName}
          </h3>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            onClick={() => {
              setNav("pre-orders");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "pre-orders" ? "var(--color-blue)" : "inherit",
              color: nav === "pre-orders" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "pre-orders" ? "white" : "inherit",
              }}
            >
              <img
                loading="lazy"
                src={preorderLogo}
                alt="preorder-logo"
                width={25}
                height={25}
                className="mr-2"
                style={{ filter: nav === "pre-orders" ? "invert(100%)" : "" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Pre-Orders"
              className="text-lg font-medium"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("orders");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "orders" ? "var(--color-blue)" : "inherit",
              color: nav === "orders" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "orders" ? "white" : "inherit",
              }}
            >
              <img
                loading="lazy"
                src={orderLogo}
                alt="order-logo"
                width={25}
                height={25}
                className="mr-2"
                style={{ filter: nav === "orders" ? "invert(100%)" : "" }}
              />
            </ListItemIcon>
            <ListItemText primary="Orders" className="text-lg font-medium" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("carts");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "carts" ? "var(--color-blue)" : "inherit",
              color: nav === "carts" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "carts" ? "white" : "inherit",
              }}
            >
              <img
                loading="lazy"
                src={cartLogo}
                alt="cart-logo"
                width={25}
                height={25}
                className="mr-2"
                style={{ filter: nav === "carts" ? "invert(100%)" : "" }}
              />
            </ListItemIcon>
            <ListItemText primary="Carts" className="text-lg font-medium" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("toggle");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "toggle" ? "var(--color-blue)" : "inherit",
              color: nav === "toggle" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "toggle" ? "white" : "inherit",
              }}
            >
              <img
                loading="lazy"
                src={adminLogo}
                alt="admin-logo"
                width={25}
                height={25}
                className="mr-2"
                style={{ filter: nav === "toggle" ? "invert(100%)" : "" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Switch to Admin"
              className="text-lg font-medium"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("shipping");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "shipping" ? "var(--color-blue)" : "inherit",
              color: nav === "shipping" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "shipping" ? "white" : "inherit",
              }}
            >
              <img
                loading="lazy"
                src={shippingLogo}
                alt="shipping-logo"
                width={25}
                height={25}
                className="mr-2"
                style={{ filter: nav === "shipping" ? "invert(100%)" : "" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Free Shipping"
              className="text-lg font-medium"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("credit");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "credit" ? "var(--color-blue)" : "inherit",
              color: nav === "credit" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "credit" ? "white" : "inherit",
              }}
            >
              <img
                loading="lazy"
                src={lineOfCreditLogo}
                alt="loc-logo"
                width={25}
                height={25}
                className="mr-2"
                style={{ filter: nav === "credit" ? "invert(100%)" : "" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Line of Credit"
              className="text-lg font-medium"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("inventory");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "inventory" ? "var(--color-blue)" : "inherit",
              color: nav === "inventory" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "inventory" ? "white" : "inherit",
              }}
            >
              <img
                loading="lazy"
                src={inventoryIcon}
                alt="loc-logo"
                width={25}
                height={25}
                className="mr-2"
                style={{ filter: nav === "inventory" ? "invert(100%)" : "" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="All Inventory"
              className="text-lg font-medium"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("collection");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "collection" ? "var(--color-blue)" : "inherit",
              color: nav === "collection" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "collection" ? "white" : "inherit",
              }}
            >
              <img
                loading="lazy"
                src={collectionIcon}
                alt="loc-logo"
                width={25}
                height={25}
                className="mr-2"
                style={{ filter: nav === "collection" ? "invert(100%)" : "" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Collections"
              className="text-lg font-medium"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("coupon");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "coupon" ? "var(--color-blue)" : "inherit",
              color: nav === "coupon" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "coupon" ? "white" : "inherit",
              }}
            >
              <img
                loading="lazy"
                src={couponIcon}
                alt="loc-logo"
                width={25}
                height={25}
                className="mr-2"
                style={{ filter: nav === "coupon" ? "invert(100%)" : "" }}
              />
            </ListItemIcon>
            <ListItemText primary="Coupons" className="text-lg font-medium" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("sale");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor: nav === "sale" ? "var(--color-blue)" : "inherit",
              color: nav === "sale" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "sale" ? "white" : "inherit",
              }}
            >
              <img
                loading="lazy"
                src={saleIcon}
                alt="loc-logo"
                width={25}
                height={25}
                className="mr-2"
                style={{ filter: nav === "sale" ? "invert(100%)" : "" }}
              />
            </ListItemIcon>
            <ListItemText primary="Sale" className="text-lg font-medium" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("productToggle");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "productToggle" ? "var(--color-blue)" : "inherit",
              color: nav === "productToggle" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "productToggle" ? "white" : "inherit",
              }}
            >
              <img
                loading="lazy"
                src={toggleIcon}
                alt="loc-logo"
                width={25}
                height={25}
                className="mr-2"
                style={{
                  filter: nav === "productToggle" ? "invert(100%)" : "",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Product Visibility"
              className="text-lg font-medium"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("bulkOrder");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "bulkOrder" ? "var(--color-blue)" : "inherit",
              color: nav === "bulkOrder" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "bulkOrder" ? "white" : "inherit",
              }}
            >
              <img
                loading="lazy"
                src={bulkOrderIcon}
                alt="loc-logo"
                width={25}
                height={25}
                className="mr-2"
                style={{
                  filter: nav === "bulkOrder" ? "invert(100%)" : "",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Bulk Order"
              className="text-lg font-medium"
            />
          </ListItem>
        </List>
      </Drawer>

      <div className="flex-grow p-3 mt-14">
        <h3 className="text-lg font-bold text-blue mb-3">Admin {nav}</h3>
        {renderContent()}
      </div>
    </div>
  );
}

export default MobileAdminDashboard;
