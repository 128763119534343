import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  Typography,
  Divider,
  Box,
  Button,
  Grid,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { formatWithCommasWithDecimal } from "../../utils/utils";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { IMAGE_BASE } from "../../config";

const BulkOrderProductSideBar = ({ isOpen, onClose, product,currentQuantity }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [quantity] = useState(1); // Quantity is fixed in Stage 5


  const calculateNetTotal = (data) => {
    const total = parseInt(data?.Bulk_NDP_Price) * currentQuantity;
    const gstAmount = (total * data?.GST) / 100;
    return total + gstAmount;
  };

  const calculatePriceWithGST = (price) => {
    const total = parseInt(price);
    const gstAmount = (total * product?.GST) / 100;
    return total + gstAmount;
  };

  const products = {
    name: product?.prod_name,
    brandLogo: `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${product?.manufacture_images}`,
    img: [
      `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
        product?.Image?.length && product?.Image[0]
      }`,
      `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
        product?.Image?.length && product?.Image[1]
      }`,
      `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
        product?.Image?.length && product?.Image[2]
      }`,
      `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
        product?.Image?.length && product?.Image[3]
      }`,
    ],
    qty: currentQuantity,
    wholesale_price: parseInt(product?.Bulk_NDP_Price),
    gst: product?.GST,
    wholesale_price_with_gst: calculatePriceWithGST(product?.Bulk_NDP_Price),
    total: calculateNetTotal(product),
  };

  const handleImageNavigation = (direction) => {
    if (direction === "prev") {
      setCurrentImageIndex((prev) =>
        prev === 0 ? products.img.length - 1 : prev - 1
      );
    } else {
      setCurrentImageIndex((prev) =>
        prev === products.img.length - 1 ? 0 : prev + 1
      );
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "450px" },
          height:"calc(100vh - 65px)",
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        },
      }}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Grayed-out overlay
          backdropFilter: "blur(4px)", // Optional blur effect
          bottom: "64px", // Leaves a 65px gap from the bottom
        },
      }}
    >
      {/* Stage 1: Back Button */}
      <Box
        display="flex"
        alignItems="center"
        py={1}
        borderBottom="1px solid #e0e0e0"
        width="100%"
      >
        <IconButton onClick={onClose}>
          <ArrowBackIosIcon style={{ fontSize: "15px" }} />
        </IconButton>
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ fontSize: "15px", letterSpacing: "0.5px", cursor: "pointer" }}
          onClick={onClose}
        >
          Back
        </Typography>
      </Box>

      {/* Stage 2: Product Name and Brand Logo */}
      <Box p={2} width="100%">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h6"
            fontWeight="bold"
            fontSize="15px"
            lineHeight="1.5"
          >
            {products.name}
          </Typography>
          <img
            src={products.brandLogo}
            alt="Brand Logo"
            style={{
              height: "40px",
              width: "80px",
              marginLeft: "5px",
              objectFit: "contain",
            }}
          />
        </Box>
      </Box>

      <Divider />

      {/* Stage 3: Image Carousel */}
      <Box width="100%">
        <Box position="relative" display="flex" justifyContent="center">
          <IconButton
            onClick={() => handleImageNavigation("prev")}
            sx={{
              position: "absolute",
              left: "0",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
            }}
          >
            <WestIcon
              sx={{
                background: "#283248",
                color: "white",
                fontSize: "30px",
                padding: "5px",
              }}
            />
          </IconButton>

          <img
            src={products.img[currentImageIndex]}
            alt="Product"
            style={{
              height: "220px",
              width: "100%",
              objectFit: "contain",
              borderRadius: "4px",
            }}
          />

          <IconButton
            onClick={() => handleImageNavigation("next")}
            sx={{
              position: "absolute",
              right: "0",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
            }}
          >
            <EastIcon
              sx={{
                background: "#283248",
                color: "white",
                fontSize: "30px",
                padding: "5px",
              }}
            />
          </IconButton>
        </Box>

        {/* Thumbnails */}
        <Box display="flex" justifyContent="center" sx={{ overflowX: "auto" }}>
          {products.img.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => setCurrentImageIndex(index)}
              style={{
                height: "80px",
                width: "80px",
                marginRight: index !== products.img.length - 1 ? "20px" : "0",
                border:
                  currentImageIndex === index
                    ? "2px solid #1976d2"
                    : "1px solid #e0e0e0",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            />
          ))}
        </Box>
      </Box>

      {/* Stage 4: Pricing Details */}
      <Box
        p={1}
        sx={{ border: "1px solid #e0e0e0", borderRadius: "4px" }}
        width="100%"
      >
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          mb={1}
          fontSize="14px"
          letterSpacing="0.5px"
        >
          Pricing Details
        </Typography>
        <Divider />
        <Box pt={1}>
          {[
            { label: "QTY", value: `${products.qty} units` },
            {
              label: "Wholesale Price",
              value: `₹${formatWithCommasWithDecimal(
                products.wholesale_price
              )}`,
            },
            { label: "GST", value: `${products.gst}%` },
            {
              label: "Wholesale Price (Incl. GST)",
              value: `₹${formatWithCommasWithDecimal(
                products.wholesale_price_with_gst
              )}`,
            },
            {
              label: "Total",
              value: `₹${formatWithCommasWithDecimal(products.total)}`,
              isBold: true,
            },
          ].map((item, index) => (
            <Grid container key={index} alignItems="center" mb={1}>
              {/* Left Column: Label */}
              <Grid item xs={5}>
                <Typography
                  variant="body2"
                  fontWeight={item.isBold ? "bold" : "normal"}
                >
                  {item.label}
                </Typography>
              </Grid>

              {/* Center Column: Dash */}
              <Grid item xs={2} display="flex" justifyContent="center">
                <Typography variant="body2" textAlign="center">
                  -
                </Typography>
              </Grid>

              {/* Right Column: Value */}
              <Grid item xs={5}>
                <Typography
                  variant="body2"
                  fontWeight={item.isBold ? "bold" : "normal"}
                  textAlign="right"
                >
                  {item.value}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Box>
      </Box>

      {/* Stage 5: Quantity and Add to Cart */}
      <Box width="100%">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          mb={2}
        >
          <Button
            variant="outlined"
            disabled
            sx={{
              color: "#9e9e9e",
              borderColor: "#9e9e9e",
              textTransform: "none",
              cursor: "not-allowed",
              marginBottom: "8px",
            }}
          >
            Quantity: Eg: {products.qty}
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#28a745",
              textTransform: "none",
              "&:hover": { backgroundColor: "#218838" },
            }}
          >
            Add to Cart
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default BulkOrderProductSideBar;
