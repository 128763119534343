import React from "react";
import "./ProductPrice.css";
import bracketLogo from "../../img/navigation/Bracket.svg";
import discountLogo from "../../img/navigation/Discount.svg";
import InfoIcon from '@mui/icons-material/Info';
import { connect, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
  selectIsOverlayVisible,
  toggleOverlay,
} from "../../store/slices/headerSlice";
import {
  calculateUpdatedDiscountPercentage,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import { Tooltip } from "@mui/material";

function ProductPrice({ selectProduct, isOverlayVisible, toggleOverlay }) {
  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );
  const userDetail = useSelector(selectUser);

  return (
    <div className="productPrice-container">
      <div className="productPrice-left-container">
        <div className="productPrice-mrp-price-container">
          <span className="productPrice-mrp-price-heading">Dealer Price</span>
          <div className="productPrice-mrp-price-main-container">
            <div className="productPrice-ndp-offer-price-container">
              {Object.keys(userDetail).length > 0 ? (
                <span
                  className="productPrice-mrp-price-span"
                  style={{
                    fontSize: selectedVariant?.Offer_price !== null && "14px",
                    textDecoration:
                      selectedVariant?.Offer_price !== null && "line-through",
                    fontWeight: selectedVariant?.Offer_price !== null && "500",
                  }}
                >
                  ₹
                  {selectedVariant &&
                    selectedVariant?.NDP_Price &&
                    formatWithCommasWithDecimal(
                      parseInt(selectedVariant?.NDP_Price)
                    )}
                </span>
              ) : (
                <span
                  className="product-price-login-to-see-price"
                  onClick={toggleOverlay}
                >
                  Login to see Price
                </span>
              )}

              {Object.keys(userDetail).length > 0 &&
                selectedVariant &&
                selectedVariant?.Offer_price !== null && (
                  <span className="productPrice-offer-price-span">
                    ₹
                    {formatWithCommasWithDecimal(
                      parseInt(selectedVariant?.Offer_price)
                    )}
                  </span>
                )}
            </div>

            <span className="productPrice-mrp-price-inclusive">
              (Excluded GST)
            </span>
          </div>
        </div>
        <div className="productPrice-ndp-price-container">
          <span className="productPrice-ndp-price-heading">MRP</span>
          <span className="productPrice-ndp-price-span">
            ₹
            {formatWithCommasWithDecimal(
              parseInt(selectedVariant && selectedVariant?.MRP_price)
            )}{" "}
          </span>
        </div>
        {selectProduct &&
          selectProduct[0]?.Manufacturer === "GARRARI BIKES" && (
            <span className="text-gray-600 text-[12px] mt-3 flex items-center">
              <Tooltip
                  title="Shipping charges will be collected by the delivery agent upon delivery."
                  arrow
                  placement="top-start"
                >
                  <InfoIcon
                    sx={{
                      fontSize: "16px",
                      color: "gray",
                      marginLeft: "4px",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>&nbsp;Shipping charges applicable
            </span>
          )}
      </div>
      <div className="productPrice-divider-container">
        <img
          loading="lazy"
          src={bracketLogo}
          alt=""
          className="productPrice-divider-img"
        />
      </div>
      {calculateUpdatedDiscountPercentage(
        selectedVariant && selectedVariant?.MRP_price,
        selectedVariant && selectedVariant?.NDP_Price,
        selectProduct && selectProduct[0]?.GST
      ) > 0 && (
        <div className="productPrice-right-container">
          <div className="productPrice-right-margin-container">
            {Object.keys(userDetail).length > 0 ? (
              <>
                <img
                  loading="lazy"
                  src={discountLogo}
                  alt=""
                  className="productPrice-right-margin-logo"
                />
                <span
                  className="productPrice-right-margin-span"
                  style={{
                    fontSize:
                      selectedVariant?.Offer_price !== null ? "10px" : "",
                    textDecoration:
                      selectedVariant?.Offer_price !== null
                        ? "line-through"
                        : "",
                    fontWeight:
                      selectedVariant?.Offer_price !== null ? "500" : "",
                  }}
                >
                  {calculateUpdatedDiscountPercentage(
                    selectedVariant?.MRP_price,
                    parseInt(selectedVariant?.NDP_Price),
                    selectProduct && selectProduct[0]?.GST
                    
                  )}
                </span>
                {selectedVariant.Offer_price !== null && (
                  <span className="productPrice-right-offer-span">
                    {calculateUpdatedDiscountPercentage(
                      selectedVariant?.MRP_price,
                      parseInt(selectedVariant?.Offer_price),
                      selectProduct && selectProduct[0]?.GST
                    )}
                  </span>
                )}
              </>
            ) : (
              <div className="product-price-not-visible-container">
                <VisibilityOffOutlinedIcon onClick={toggleOverlay} />
              </div>
            )}
            % Dealer Margin
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductPrice);
