import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  Select,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import {
  fetchAlldealerProductSaleAsync,
  fetchAlldealerSKUSaleAsync,
  selectAllProductSale,
  selectAllSkuSale,
  updateDeliveredStatusAsync,
} from "../../features/admin/adminSlice";
import toast from "react-hot-toast";
import { formatWithCommasWithDecimal } from "../../utils/utils";

const AdminSale = () => {
  const dispatch = useDispatch();
  const productSale = useSelector(selectAllProductSale);
  const skuSale = useSelector(selectAllSkuSale);
  const userDetail = useSelector(selectUser);
  const [open, setOpen] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const [tab, setTab] = useState(0);
  const [status, setStatus] = useState("");
  const [deliveredDate, setDeliveredDate] = useState("");

  useEffect(() => {
    handleOpen();
    dispatch(fetchAlldealerProductSaleAsync()).then(() => {
      dispatch(fetchAlldealerSKUSaleAsync());
      handleClose();
    });
  }, [dispatch]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleStatusUpdate = (invoice) => {
    setSelectedInvoice(invoice);
    setShowUpdateForm(true);
    setStatus("");
    setDeliveredDate("");

    // Scroll to the top smoothly
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleOpen();
    const updateData = {
      Invoice_Number: selectedInvoice,
      Status: status,
      delivered: status === "SUCCESS" ? deliveredDate : null,
    };

    dispatch(updateDeliveredStatusAsync(updateData)).then(() => {
      dispatch(fetchAlldealerProductSaleAsync()).then(() => {
        dispatch(fetchAlldealerSKUSaleAsync());
      });
      setShowUpdateForm(false);
      toast.success("Update delivered status successfully");
      handleClose();
    });
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="w-full min-h-screen bg-gray-50 relative flex flex-col">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="w-full h-14 bg-white-1 flex items-center justify-between sticky top-0 py-1 px-5
       shadow-md z-10 max-sm:hidden"
      >
        <span className="text-lg font-bold tracking-wider text-gray-800">
          Sale
        </span>
        <img
          loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="w-10 h-10 rounded-full border border-gray-300"
        />
      </div>
      <>
        <div className="w-full max-md:px-2 max-md:py-3 px-4 md:px-6 lg:px-8 py-5 flex-grow">
          {showUpdateForm && (
            <form onSubmit={handleSubmit} className="p-4">
              <h3 className="text-lg text-black-1 font-bold mb-4">
                Update Delivered Status
              </h3>
              <div className="flex flex-col gap-4 w-full lg:w-[400px]">
                <TextField
                  value={selectedInvoice}
                  label="Invoice No."
                  disabled
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "4px",
                      fontWeight: "600",
                      background: "white",
                    },
                  }}
                />
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  displayEmpty
                  required
                  sx={{
                    "& .MuiSelect-select": { padding: "8px" },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value="SUCCESS">Success</MenuItem>
                  <MenuItem value="PENDING">Pending</MenuItem>
                  <MenuItem value="PENDING">Failed</MenuItem>
                </Select>
                {status === "SUCCESS" && (
                  <TextField
                    type="date"
                    value={deliveredDate}
                    onChange={(e) => setDeliveredDate(e.target.value)}
                    required
                    label="Delivered Date"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    height: "40px",
                    fontSize: "15px",
                    letterSpacing: "0.5px",
                    fontFamily: "inherit",
                    fontWeight: "600",
                    backgroundColor: "var(--color-green)",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "var(--color-green-hover)",
                    },
                  }}
                >
                  Submit
                </Button>
              </div>
            </form>
          )}
          <Box sx={{ width: "100%", bgcolor: "" }}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              centered
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "var(--color-green)",
                },
                "& .MuiTab-root": { color: "gray" },
                "& .Mui-selected": { color: "var(--color-green) !important" },
              }}
            >
              <Tab label="Product" />
              <Tab label="SKU" />
            </Tabs>
            <Box sx={{ p: 2 }}>
              {tab === 0 && (
                <div className="w-full overflow-x-auto pt-[100px] sm:px-5">
                  <h2 className="text-[16px] tracking-wide p-3 text-black-1/80 bg-white-1 rounded-t-md">
                    All Product Sale
                  </h2>
                  <TableContainer component={Paper} className="shadow-md">
                    <Table>
                      <TableHead className="bg-gray-100">
                        <TableRow>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            S.No
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Invoice Number
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Order Number
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Manufacture Name
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Total Items
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Total NDP Price
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Delivered Date
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            All SKU
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {productSale?.map((sale, index) => (
                          <TableRow key={index} hover>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                              {sale?.Invoice_Number}
                            </TableCell>
                            <TableCell align="center">
                              {sale?.OrderNumber}
                            </TableCell>

                            <TableCell align="center">
                              {sale?.manufacture_name || "N/A"}
                            </TableCell>
                            <TableCell align="center">
                              {sale?.total_items || "N/A"}
                            </TableCell>
                            <TableCell align="center">
                              {(sale?.total_NDP_price &&
                                formatWithCommasWithDecimal(
                                  parseInt(sale?.total_NDP_price)
                                )) ||
                                "N/A"}
                            </TableCell>
                            <TableCell align="center">
                              {sale?.Status || "N/A"}{" "}
                              <Button
                                variant="contained"
                                onClick={() =>
                                  handleStatusUpdate(sale?.Invoice_Number)
                                }
                                sx={{
                                  height: "30px",
                                  fontSize: "13px",
                                  letterSpacing: "0.5px",
                                  fontFamily: "inherit",
                                  fontWeight: "600",
                                  whiteSpace:"nowrap",
                                  backgroundColor: "var(--color-green)",
                                  textTransform: "none",
                                  "&:hover": {
                                    backgroundColor: "var(--color-green-hover)",
                                  },
                                }}
                              >
                                Update Status
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              {sale?.delivered || "N/A"}
                            </TableCell>
                            <TableCell align="center">
                              {sale?.SKU ? (
                                <div className="flex flex-col items-center">
                                  {sale?.SKU.split(",").map((sku, idx) => (
                                    <span key={idx} className="text-sm">
                                      {sku}
                                    </span>
                                  ))}
                                </div>
                              ) : (
                                "N/A"
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
              {tab === 1 && (
                <div className="w-full overflow-x-auto pt-[100px] sm:px-5">
                  <h2 className="text-[16px] tracking-wide p-3 text-black-1/80 bg-white-1 rounded-t-md">
                    All SKU Sale
                  </h2>
                  <TableContainer component={Paper} className="shadow-md">
                    <Table>
                      <TableHead className="bg-gray-100">
                        <TableRow>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            S.No
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            SKU ID
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Product Name
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Available Qty
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Sold Quantity
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Total Orders
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {skuSale?.map((sale, index) => (
                          <TableRow key={index} hover>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{sale?.SKU_ID}</TableCell>
                            <TableCell align="center">
                              {sale?.prod_name}
                            </TableCell>

                            <TableCell align="center">
                              {sale?.Available_qty || "N/A"}
                            </TableCell>
                            <TableCell align="center">
                              {sale?.Sold_quantity || "N/A"}
                            </TableCell>
                            <TableCell align="center">
                              {sale?.total_orders || "N/A"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </Box>
          </Box>
        </div>
      </>
    </div>
  );
};

export default AdminSale;
