import React, { useState, useEffect, useRef } from "react";
import "./BrandSelect.css";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { useSelector } from "react-redux";
import { selectAllFrameMaterials } from "../../../../features/listingPage/listingPageSlice";

function AgeSelect({ onSelect, closeFrameMaterialSelect, previouslySelectedFrameMaterial }) {
  const frameMaterials = useSelector(selectAllFrameMaterials);
  const [selectedFrameMaterials, setSelectedFrameMaterials] = useState(
    previouslySelectedFrameMaterial || []
  );
  const [searchQuery, setSearchQuery] = useState("");

  const handleCheckboxChange = (frameMaterial) => {
    setSelectedFrameMaterials((prevselectedFrameMaterials) =>
      prevselectedFrameMaterials.includes(frameMaterial)
        ? prevselectedFrameMaterials.filter((b) => b !== frameMaterial)
        : [...prevselectedFrameMaterials, frameMaterial]
    );
  };

  const handleMobileCheckboxChange = (frameMaterial) => {
    setSelectedFrameMaterials((prevselectedFrameMaterials) => {
      const updatedFrameMaterials = prevselectedFrameMaterials.includes(frameMaterial)
        ? prevselectedFrameMaterials.filter((b) => b !== frameMaterial)
        : [...prevselectedFrameMaterials, frameMaterial];
      onSelect({ frameMaterials: updatedFrameMaterials });
      return updatedFrameMaterials;
    });
  };

  const handleApplyClick = () => {
    onSelect({ frameMaterials: selectedFrameMaterials });
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Create a ref to the component
  const frameMaterialSelectRef = useRef(null);

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const handleClickOutside = (event) => {
    if (
      frameMaterialSelectRef.current &&
      !frameMaterialSelectRef.current.contains(event.target)
    ) {
      // Click occurred outside of the BrandSelect component
      closeFrameMaterialSelect();
    }
  };

  const filteredFrameMaterials = frameMaterials?.filter((data) =>
    data.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div ref={frameMaterialSelectRef} className="brand-select-container">
      <div className="brand-select-btn-container">
        <span>{selectedFrameMaterials.length} selected</span>
        <div className="brand-select-btn-contain">
          <button className="brand-select-btn-apply" onClick={handleApplyClick}>
            Apply
          </button>
          <button
            className="brand-select-btn-clear"
            onClick={() => setSelectedFrameMaterials([])}
          >
            Clear
          </button>
        </div>
      </div>
      <div className="brand-select-search-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
        <SearchSharpIcon className="brand-select-search-icon" />
      </div>
      <div className="brand-select-option-container">
        {filteredFrameMaterials.map((data, index) => (
          <div
            className={`brand-name-checkbox${
              selectedFrameMaterials.includes(data) ? "-selected" : ""
            }`}
            key={index}
          >
            <input
              type="checkbox"
              checked={selectedFrameMaterials.includes(data)}
              onChange={() => handleCheckboxChange(data)}
              // onClick={handleApplyClick}
            />
            <span>{data}</span>
          </div>
        ))}
      </div>
      <div className="mobile-brand-select-option-container">
        {filteredFrameMaterials.map((data, index) => (
          <div
            className={`brand-name-checkbox${
              selectedFrameMaterials.includes(data) ? "-selected" : ""
            }`}
            key={index}
          >
            <input
              type="checkbox"
              checked={selectedFrameMaterials.includes(data)}
              onChange={() => handleMobileCheckboxChange(data)}
              // onClick={handleApplyClick}
            />
            <span className="brand-name-span-d">{data}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AgeSelect;
