import React, { useState, useEffect } from "react";
import "./TechnicalData.css";
import { useSelector } from "react-redux";
import { Slide } from "react-reveal";
import pdfLocal from "../../img/banners/samplepdf.pdf";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { scrollModePlugin } from "@react-pdf-viewer/scroll-mode";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { ProgressBar } from "@react-pdf-viewer/core";
import { IMAGE_BASE } from "../../config";
import FeedIcon from "@mui/icons-material/Feed";

function TechnicalData({ productDesc }) {
  // console.log(productDesc);
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const newplugin = defaultLayoutPlugin();
  const scrollPlugin = scrollModePlugin();
  const [technicalDatas, setTechnicalDatas] = useState([]);
  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );

  useEffect(() => {
    const fetchPdf = async () => {
      if (productDesc && productDesc[0]?.manual) {
        const url = `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUAL/${productDesc[0]?.manual}`;

        try {
          // Fetch the PDF file
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Failed to fetch PDF from URL");
          }
          const blob = await response.blob();

          // Create a Blob URL for the fetched PDF
          const blobUrl = URL.createObjectURL(blob);
          setPdfUrl(blobUrl);
        } catch (error) {
          // If there's an error fetching the PDF, set the URL to the local PDF
          console.error("Error fetching PDF:", error);
          setPdfUrl(pdfLocal);
        }
      }
    };
    fetchPdf();
  }, [productDesc]);

  const renderLoader = (percentages) => (
    <div style={{ width: "240px" }}>
      <ProgressBar progress={Math.round(percentages)} />
    </div>
  );

  useEffect(() => {
    scrollPlugin.switchScrollMode("Horizontal");
  }, []);

  useEffect(() => {
    if (productDesc && productDesc[0]?.skuDetails[0] && selectedVariant) {
      const variantDetail = [
        selectedVariant?.Bar_Code && {
          key: "Bar Code",
          value:
            selectedVariant?.Bar_Code?.charAt(0)?.toUpperCase() +
            selectedVariant?.Bar_Code?.slice(1)?.toLowerCase(),
        },
        selectedVariant?.Model_Number && {
          key: "Model Number",
          value:
            selectedVariant?.Model_Number?.charAt(0)?.toUpperCase() +
            selectedVariant?.Model_Number?.slice(1)?.toLowerCase(),
        },
      ].filter(Boolean);

      const descriptionData = productDesc[0]?.descriptions.map((data) => ({
        key: data.key,
        value: data.value,
      }));

      const importedBy = {
        key: "Imported/Marketed By",
        value: productDesc[0]?.["Imported By"] || "",
      };
      const customerSupport = {
        key: "Customer Support",
        value: productDesc[0]?.["Customer Support"] || "",
      };

      setTechnicalDatas([
        ...variantDetail,
        ...descriptionData,
        productDesc[0]?.["Imported By"] && importedBy,
        productDesc[0]?.["Customer Support"] && customerSupport,
      ]);
    } else {
      setTechnicalDatas([]);
    }
  }, [productDesc, selectedVariant]);

  return (
    <div className="compatibility-container">
      {/* <div className="compatibility-main-container">
        <div
          className="discover-click-here"
          onClick={() => setOpenPdf(!openPdf)}
        >
          {openPdf ? (
            <div className="close-guide-div">
              <span className="close-guide">Close Guide</span>
            </div>
          ) : (
            <div className="view-guide-div">
              <FeedIcon />
              <span className="view-guide">View Guide</span>
            </div>
          )}
        </div>
      </div> */}
      {/* {openPdf && (
        <Slide bottom>
          <div className="discover-pdf-open-container">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                fileUrl={pdfUrl || pdfLocal} // Use pdfUrl if available, otherwise use pdfLocal
                renderLoader={renderLoader}
                theme={{ theme: "dark" }}
                plugins={[newplugin, scrollPlugin]}
              />
            </Worker>
          </div>
        </Slide>
      )} */}
      <table className="compatibility-table-container">
        <tbody>
          <tr className="compatibility-container-row-odd">
            <td className="compatibility-container-data-key">Product Name:</td>
            <td className="compatibility-container-data tracking-wide">
              {productDesc &&
                productDesc[0]?.prod_name}
            </td>
          </tr>
          {technicalDatas && technicalDatas?.length > 0 ? (
            technicalDatas.map((data, index) => (
              <tr
                className="compatibility-container-row"
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "white" : "#F5F8FE",
                }}
              >
                {data?.key && (
                  <td className="compatibility-container-data-key">
                    {data?.key}:
                  </td>
                )}
                {data?.key && (
                  <td className="compatibility-container-data">
                    {data?.value}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <p>No technical data available.</p>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TechnicalData;