// Import React and required libraries
import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { formatWithCommasWithDecimal } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductComparisionAsync,
  selectProductComparision,
} from "../../features/productDesc/productDescSlice";
import { IMAGE_BASE } from "../../config";
import { useNavigate } from "react-router";
import { selectUser } from "../../features/auth/authSlice";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

gsap.registerPlugin(ScrollTrigger);

const Compare = ({ productDesc }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const rowsRef = useRef([]);
  const userDetail = useSelector(selectUser);
  const [viewMode, setViewMode] = useState("differences");
  const comparisionCycles = useSelector(selectProductComparision)?.comparisons;

  useEffect(() => {
    dispatch(
      fetchProductComparisionAsync(productDesc && productDesc[0]?.prod_ID)
    );
  }, [dispatch, productDesc]);

  useEffect(() => {
    const tl = gsap.timeline({ defaults: { duration: 1, ease: "power3.out" } });
    tl.fromTo(tableRef.current, { opacity: 0, y: 50 }, { opacity: 1, y: 0 });
    rowsRef.current.forEach((row, index) => {
      tl.fromTo(
        row,
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0 },
        `-=${0.8 - index * 0.2}`
      );
    });
  }, []);

  // Define grouped headers with fields
  const groupedFields = {
    "Basic Info": ["dealer price", "mrp", "type"],
    Cockpit: ["headset", "grips", "stem", "fork", "saddle", "pedals"],
    Frameset: [
      "frame material",
      "speed",
      "cogset",
      "crankset",
      "chain",
      "bottom bracket",
    ],
    Wheels: ["rims", "tyres & tube", "front hub", "rear hub", "brakes"],
  };

  const filterFields = (fields) =>
    fields.filter((field) => {
      const values = comparisionCycles?.map((cycle) => cycle[field]);
      return viewMode === "differences"
        ? new Set(values).size > 1 // Show only if values differ
        : true; // Show all specifications
    });

  const handleNavigate = (prod_ID) => {
    navigate(`/product/${prod_ID}`);
  };

  const handleToggleChange = (event, newViewMode) => {
    if (newViewMode) {
      setViewMode(newViewMode);
    }
  };

  return (
    <div className="w-full flex flex-col items-start mx-auto px-0 py-2">
      <div className="md:w-full max-w-[100vw] lg:w-[85%] flex justify-end items-center mb-2">
        {/* Toggle Button Group */}
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={handleToggleChange}
          aria-label="View Mode"
          className="flex justify-end mb-1"
        >
          <ToggleButton
            value="differences"
            aria-label="Show Differences"
            sx={{
              textTransform: "none",
              padding: "8px 16px",
              borderRadius: "0",
            }}
          >
            Show Differences
          </ToggleButton>
          <ToggleButton
            value="all"
            aria-label="Show All"
            sx={{
              textTransform: "none",
              padding: "8px 16px",
              borderRadius: "0",
            }}
          >
            Show All Specifications
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="md:w-full max-w-[100vw] max-sm:overflow-x-auto lg:w-[85%] shadow-lg">
        <table
          ref={tableRef}
          className="min-w-full border-separate border-spacing-0 text-sm bg-white-1"
        >
          <thead className="bg-white-1 z-100 md:sticky md:top-[160px]">
            <tr className="border-2 bg-white-1 border-[#3643ba]">
              <th className="p-2 pl-4 border font-bold text-3xl text-[#3643ba] bg-gradient-to-t from-[#14465b] to-[#166574] text-transparent bg-clip-text">
                Compare Bikes
              </th>
              {comparisionCycles?.map((cycle, index) => (
                <th
                  key={index}
                  className={`p-2 pl-4 text-center bg-white-1 ${
                    cycle?.prod_ID === productDesc[0]?.prod_ID
                      ? "bg-gray-50 border border-l-2 border-r-2 border-t-2 border-[#3643ba] border-b-gray-300"
                      : "bg-white-1 border border-gray-300"
                  }`}
                >
                  <img
                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                      cycle?.image &&
                      cycle?.image?.length > 0 &&
                      cycle?.image[0]
                    }`}
                    alt={cycle?.prod_name}
                    className="mx-auto mb-4 w-[180px] h-[140px] object-contain rounded-lg"
                  />
                  <p
                    className={`${
                      cycle?.prod_ID === productDesc[0]?.prod_ID
                        ? "text-[#3643ba] bg-gradient-to-t from-[#3643ba] to-[#0f1ea5] text-transparent bg-clip-text"
                        : "text-black-1"
                    } font-bold`}
                  >
                    {cycle?.prod_name}
                  </p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object?.entries(groupedFields).map(([header, fields]) => {
              const filteredFields = filterFields(fields);
              return filteredFields.length > 0 ? (
                <React.Fragment key={header}>
                  {/* Group header row */}
                  <tr>
                    <td
                      colSpan={comparisionCycles?.length + 1}
                      className="bg-gray-100 text-[#3643ba] font-bold text-lg px-2 py-0 text-left"
                    >
                      {header}
                    </td>
                  </tr>
                  {/* Field rows for the group */}
                  {filteredFields?.map((field, rowIndex) => (
                    <tr
                      key={rowIndex}
                      ref={(el) => (rowsRef.current[rowIndex] = el)}
                      className="hover:shadow-sm"
                    >
                      <td className="w-1/4 p-4 pl-6 capitalize border font-bold text-gray-800">
                        {field.replace(/_/g, " ")}
                      </td>
                      {comparisionCycles?.map((cycle, colIndex) => {
                        const isCurrentProduct =
                          cycle?.prod_ID === productDesc[0]?.prod_ID;
                        const matchesCurrent =
                          cycle[field] === comparisionCycles[0]?.[field];

                        return (
                          <td
                            key={colIndex}
                            className={`relative p-4 text-center ${
                              isCurrentProduct
                                ? "bg-gray-50 border border-l-2 border-r-2 border-[#3643ba] border-y-gray-300"
                                : "border border-gray-300"
                            } `}
                          >
                            <div className="inline-flex items-center gap-2">
                              {field === "dealer price" ? (
                                Object?.keys(userDetail)?.length > 0 ? (
                                  <span
                                    className={`text-xl font-bold ${
                                      matchesCurrent
                                        ? "text-green"
                                        : "text-peach"
                                    }`}
                                  >
                                    ₹
                                    {formatWithCommasWithDecimal(
                                      parseInt(cycle[field])
                                    )}
                                  </span>
                                ) : (
                                  <div className="relative group">
                                    <span
                                      className={`blur-sm ${
                                        matchesCurrent
                                          ? "text-green"
                                          : "text-peach"
                                      } select-none cursor-pointer`}
                                    >
                                      ₹
                                      {formatWithCommasWithDecimal(
                                        parseInt(cycle[field])
                                      )}
                                    </span>
                                    <div className="absolute left-[50%] top-0 mt-0 hidden w-max p-2 bg-black-1 text-white-1 text-[10px] rounded shadow-lg group-hover:block">
                                      Login to see price
                                    </div>
                                  </div>
                                )
                              ) : field === "mrp" ? (
                                <span
                                  className={`${
                                    matchesCurrent ? "text-green" : "text-peach"
                                  }`}
                                >
                                  ₹
                                  {formatWithCommasWithDecimal(
                                    parseInt(cycle[field])
                                  )}
                                </span>
                              ) : field === "reviews" ? (
                                <span
                                  className={`${
                                    matchesCurrent ? "text-green" : "text-peach"
                                  }`}
                                >
                                  ★ {cycle[field]}
                                </span>
                              ) : (
                                <span
                                  className={`${
                                    matchesCurrent ? "text-green" : "text-peach"
                                  }`}
                                >
                                  {cycle[field]}
                                </span>
                              )}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </React.Fragment>
              ) : null;
            })}
            <tr>
              <td className="border"></td>
              {comparisionCycles?.map((cycle, index) => (
                <td
                  key={index}
                  className={`p-4 ${
                    cycle?.prod_ID === productDesc[0]?.prod_ID
                      ? "bg-gray-50 border-2 border-t border-[#3643ba] border-t-gray-300"
                      : "border border-gray-300"
                  }`}
                >
                  <button
                    onClick={() => handleNavigate(cycle?.prod_ID)}
                    className="w-full bg-[#3643ba] text-white-1 whitespace-nowrap py-2 rounded hover:bg-[#2c38a1] px-3"
                  >
                    View Product
                  </button>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Compare;
