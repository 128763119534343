import React, { useEffect, useRef, useState } from "react";
import {
  calculateDealerPrice,
  calculateGST,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
  handleCurrentPrice,
} from "../utils/utils";
import { useNavigate } from "react-router";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import CloseIcon from "@mui/icons-material/Close";
import {
  handleChangeQtyOfProductCarts,
  handleRemoveFromCarts,
} from "../utils/dispatch";
import { useDispatch } from "react-redux";
import { IMAGE_BASE } from "../config";
import { FormattedNumber } from "../utils/numberAnimation";

const CartProductCard = ({
  data,
  index,
  userDetail,
  handleOpen,
  handleClose,
  setUseCreditAmount,
  setUseWalletAmount,
  setSelectedCoupon,
}) => {
  const dispatch = useDispatch();
  const listElementRef = useRef();
  const wrapperRef = useRef();
  const backgroundRef = useRef();

  const dragStartXRef = useRef(0);
  const leftRef = useRef(0);
  const draggedRef = useRef(false);

  useEffect(() => {
    window.addEventListener("mouseup", onDragEndMouse);
    window.addEventListener("touchend", onDragEndTouch);
    return () => {
      window.removeEventListener("mouseup", onDragEndMouse);
      window.removeEventListener("touchend", onDragEndTouch);
    };
  });

  function onDragStartMouse(evt) {
    onDragStart(evt.clientX);
    window.addEventListener("mousemove", onMouseMove);
  }

  function onDragStartTouch(evt) {
    const touch = evt.targetTouches[0];
    onDragStart(touch.clientX);
    window.addEventListener("touchmove", onTouchMove);
  }

  function onDragStart(clientX) {
    draggedRef.current = true;
    dragStartXRef.current = clientX;

    listElementRef.current.className = "ListItem";

    requestAnimationFrame(updatePosition);
  }

  function updatePosition() {
    if (draggedRef.current) {
      requestAnimationFrame(updatePosition);
    }

    if (listElementRef?.current?.style) {
      listElementRef.current.style.transform = `translateX(${leftRef.current}px)`;
    }
  }

  function onMouseMove(evt) {
    const left = evt.clientX - dragStartXRef.current;
    if (left < 0) {
      leftRef.current = left;
    }
  }

  function onTouchMove(evt) {
    const touch = evt.targetTouches[0];
    const left = touch.clientX - dragStartXRef.current;
    if (left < 0) {
      leftRef.current = left;
    }
  }

  function onDragEndMouse(evt) {
    window.removeEventListener("mousemove", onMouseMove);
    onDragEnd();
  }

  function onDragEndTouch(evt) {
    window.removeEventListener("touchmove", onTouchMove);
    onDragEnd();
  }

  function onDragEnd() {
    if (draggedRef.current) {
      draggedRef.current = false;
      const threshold = 0.5;

      if (
        leftRef.current <
        listElementRef.current.offsetWidth * threshold * -1
      ) {
        leftRef.current = -listElementRef.current.offsetWidth * 2;

        if (window.confirm("Do you want to remove this product?")) {
          // wrapperRef.current.style.maxHeight = 0;
          leftRef.current = 0;
          handleRemoveFromCarts(
            data,
            userDetail,
            dispatch,
            handleOpen,
            handleClose
          );
          setUseWalletAmount(0);
          setUseCreditAmount(0);
          setSelectedCoupon({
            couponCode: "",
            couponDiscountPercentage: 0,
            couponDiscountTaken: 0,
          });
        } else {
          leftRef.current = 0;
        }
      } else {
        leftRef.current = 0;
      }

      listElementRef.current.className = "BouncingListItem";
      if (listElementRef?.current?.style) {
        listElementRef.current.style.transform = `translateX(${leftRef.current}px)`;
      }
    }
  }

  return (
    <div
      className="relative overflow-hidden w-full my-[6px] shadow-sm shadow-black-1/30"
      ref={wrapperRef}
      key={index}
    >
      <div
        className=" absolute w-full h-full -z-1 flex flex-row justify-end items-center pr-5 text-white-1 bg-peach font-bold"
        ref={backgroundRef}
      >
        <DeleteOutlineOutlinedIcon
          className="text-white-1"
          style={{ fontSize: "30px" }}
        />
      </div>
      <div
        className="BouncingListItem"
        ref={listElementRef}
        onMouseDown={onDragStartMouse}
        onTouchStart={onDragStartTouch}
      >
        <CartProductCardSwiper
          data={data}
          index={index}
          userDetail={userDetail}
          dispatch={dispatch}
          handleOpen={handleOpen}
          handleClose={handleClose}
          setUseCreditAmount={setUseCreditAmount}
          setUseWalletAmount={setUseWalletAmount}
          setSelectedCoupon={setSelectedCoupon}
        />
      </div>
    </div>
  );
};

export const CartProductCardSwiper = ({
  data,
  index,
  userDetail,
  dispatch,
  handleOpen,
  handleClose,
  setUseCreditAmount,
  setUseWalletAmount,
  setSelectedCoupon,
}) => {
  const navigate = useNavigate();
  const [openAccordian, setOpenAccordian] = useState(null);
  const handleAccordian = (index) => {
    if (openAccordian !== index) {
      setOpenAccordian(index);
    } else {
      setOpenAccordian(null);
    }
  };
  return (
    <div className="w-full flex flex-col items-start justify-start bg-white-1 mx-0 px-3 relative">
      <CloseIcon
        className="absolute top-2 left-2 z-10 text-black-1/80 p-1 rounded-full bg-white-1 bg-black-1/20 shadow-sm shadow-black-1/20"
        style={{ fontSize: "20px" }}
        onClick={() => {
          handleRemoveFromCarts(
            data,
            userDetail,
            dispatch,
            handleOpen,
            handleClose
          );
          setUseWalletAmount(0);
          setUseCreditAmount(0);
          setSelectedCoupon({
            couponCode: "",
            couponDiscountPercentage: 0,
            couponDiscountTaken: 0,
          });
        }}
      />
      <div className="w-full h-[135px] flex items-start justify-start">
        <div
          className="w-[38%] h-full flex-center relative"
          onClick={() =>
            navigate(
              `/product/${
                data?.skuDetails?.prod_ID && data?.skuDetails?.prod_ID
              }`
            )
          }
        >
          <img
            loading="lazy"
            src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
              data?.images && data?.images
            }`}
            alt="product-img"
            className="object-contain"
          />
        </div>
        <div className="w-[62%] h-full flex flex-col items-start justify-between ml-4 relative pt-2">
          <div className="w-[50%] h-[22px]">
            {data && data?.skuDetails?.manufacture_images ? (
              <img
                loading="lazy"
                className="max-w-[120px] h-full object-contain"
                src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                  data && data?.skuDetails?.manufacture_images
                }`}
                alt="brand-logo"
              />
            ) : (
              <h3 className="text-16 italic font-bold">
                {data?.skuDetails?.manufacture_name &&
                  data?.skuDetails?.manufacture_name}
              </h3>
            )}
          </div>
          <span className="w-full h-[55px] text-start font-sans leading-[1] mt-1 text-[18px] text-black-2 font-bold capitalize">
            {data?.skuDetails?.prod_name &&
              (data?.skuDetails?.prod_name)
                .slice(0, 45)
                .toLowerCase()
                .charAt(0)
                .toUpperCase() +
                (data?.skuDetails?.prod_name)
                  .slice(0, 45)
                  .toLowerCase()
                  .slice(1)}
            {(data?.skuDetails?.prod_name).length > 45 && "..."}
          </span>
          <div className="flex items-center justify-between font-sans leading-[1] bg-gray-100 px-2 py-[2px] mt-1 rounded-sm font-bold shadow-sm shadow-black-1/20">
            <div className="text-[14px] font-medium text-black-2 flex items-center">
              <span>4.7</span>
              <StarRoundedIcon
                className="ml-1 text-[#daa520]"
                style={{ fontSize: "16px" }}
              />
            </div>
            <span className="text-[14px] text-black-1 mx-[2px] font-medium">
              |
            </span>
            <span className="text-[14px] ml-2 font-medium text-black-2">
              4K
            </span>
          </div>
          <div className="w-full flex flex-col">
            <span className="w-full h-[28px]  text-[16px] leading-[1] font-sans font-semibold text-blue flex items-center whitespace-nowrap">
              Dealer Price- ₹
              <b
                style={{
                  textDecoration:
                    data?.skuDetails?.Offer_price !== null && "line-through",
                  fontSize: data?.skuDetails?.Offer_price !== null && "14px",
                }}
              >
                {formatWithCommasWithDecimal(
                  parseInt(data?.skuDetails?.NDP_Price)
                )}
              </b>
              {Object.keys(userDetail).length > 0 &&
                data?.skuDetails?.Offer_price !== null && (
                  <span className="text-[18px] text-green ml-1 font-semibold">
                    ₹
                    {formatWithCommasWithDecimal(
                      parseInt(data?.skuDetails?.Offer_price)
                    )}
                  </span>
                )}
            </span>
            <span className="w-full flex items-start text-[15px] leading-[1] font-sans font-semibold text-blue">
              Product MRP- ₹
              {formatWithCommasWithDecimal(
                parseInt(data?.skuDetails?.MRP_price)
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-between mt-3 mb-1">
        <div className="flex items-center">
          <RemoveSharpIcon
            onClick={() => {
              const bundleSize = parseInt(data?.skuDetails?.Bundle);
              const currentQuantity = parseInt(data?.buy_quantity);

              if (currentQuantity === bundleSize) {
                return;
              } else {
                const newQuantity = Math.max(currentQuantity - bundleSize, 0);
                handleChangeQtyOfProductCarts(
                  data,
                  userDetail,
                  newQuantity,
                  dispatch,
                  handleOpen,
                  handleClose
                );
                setUseWalletAmount(0);
                setUseCreditAmount(0);
                setSelectedCoupon({
                  couponCode: "",
                  couponDiscountPercentage: 0,
                  couponDiscountTaken: 0,
                });
              }
            }}
            className={`rounded-full mr-2 p-[2px] ${
              data?.buy_quantity === 1
                ? "bg-black-1/30"
                : "bg-green text-white-1"
            }`}
          />

          <span className="text-[16px] font-sans leading-[1] text-black-1/80 w-[35px] h-[20px] border-[1px] border-black-1/10 text-center mx-2">
            {data?.buy_quantity}
          </span>
          <AddSharpIcon
            onClick={() => {
              const bundleSize = parseInt(data?.skuDetails?.Bundle);
              const currentQuantity = parseInt(data?.buy_quantity);

              const newQuantity = currentQuantity + bundleSize;

              handleChangeQtyOfProductCarts(
                data,
                userDetail,
                newQuantity,
                dispatch,
                handleOpen,
                handleClose
              );
              setUseWalletAmount(0);
              setUseCreditAmount(0);
              setSelectedCoupon({
                couponCode: "",
                couponDiscountPercentage: 0,
                couponDiscountTaken: 0,
              });
            }}
            className={`rounded-full ml-2 p-[2px] ${
              data?.buy_quantity === data?.skuDetails?.qty
                ? "bg-black-1/30"
                : "bg-green text-white-1"
            }`}
          />
        </div>
        <div className="flex items-center">
          <div className="flex flex-col mr-1">
            <span className="text-[18px] font-sans leading-[1] text-black-1/80">
              <FormattedNumber
                value={
                  (data?.skuDetails?.Offer_price
                    ? calculateOfferPrice(
                        parseInt(data?.skuDetails?.MRP_price),
                        parseInt(data?.skuDetails?.Offer_price),
                        data?.skuDetails?.GST
                      )
                    : calculateDealerPrice(
                        parseInt(data?.skuDetails?.MRP_price),
                        parseInt(data?.skuDetails?.NDP_Price),
                        data?.skuDetails?.GST
                      )
                ) * (data?.buy_quantity || 1)
                }
              />
            </span>
            <span className="text-[16px] font-medium text-black-1/80 font-sans leading-[1]">
              Price Details
            </span>
          </div>
          {openAccordian !== index && (
            <KeyboardArrowDownSharpIcon
              className="text-black-1/80"
              style={{ fontSize: "25px" }}
              onClick={() => handleAccordian(index)}
            />
          )}
          {openAccordian === index && (
            <KeyboardArrowUpSharpIcon
              className="accordian-carts-icon text-black-1/80"
              style={{ fontSize: "25px" }}
              onClick={() => handleAccordian(index)}
            />
          )}
        </div>
      </div>
      {openAccordian === index && (
        <div className="w-full bg-gray-200/70 rounded-md py-2 my-2">
          <div className="w-full flex flex-col items-center justify-between">
            <div className="w-full flex items-center justify-between relative p-2">
              <p className="text-[14px] text-black-1/70 font-semibold">
                Price{" "}
                <b className="font-medium text-gray-700 tracking-wide text-[10px]">
                  (per qty)
                </b>
              </p>
              <hr className="absolute w-[5px] border-[1px] border-black-1/90 left-[50%] transform -translate-x-1/2" />
              <span className="text-[15px] text-black-1/80 font-semibold">
                <FormattedNumber
                  value={
                    data?.skuDetails?.Offer_price
                      ? parseInt(data.skuDetails?.Offer_price)
                      : parseInt(data.skuDetails?.NDP_Price)
                  }
                />
              </span>
            </div>
            <div className="w-full flex items-center justify-between relative p-2">
              <p className="text-[14px] text-black-1/70 font-semibold">
                GST{" "}
                <b className="font-medium text-gray-700 tracking-wide text-[10px]">
                  (per qty)
                </b>
              </p>
              <hr className="absolute w-[5px] border-[1px] border-black-1/90 left-[50%] transform -translate-x-1/2" />
              <span className="text-[15px] text-black-1/80 font-semibold">
                <FormattedNumber
                  value={
                    data?.skuDetails?.Offer_price
                      ? calculateGST(
                          parseInt(data.skuDetails?.Offer_price),
                          parseInt(data.skuDetails?.GST)
                        )
                      : calculateGST(
                          parseInt(data.skuDetails?.NDP_Price),
                          parseInt(data.skuDetails?.GST)
                        )
                  }
                />
              </span>
            </div>
            <div className="w-full flex items-center justify-between relative p-2">
              <p className="text-[14px] text-black-1/70 font-semibold">
                Shipping To
              </p>
              <hr className="absolute w-[5px] border-[1px] border-black-1/90 left-[50%] transform -translate-x-1/2" />
              <span className="text-[15px] text-black-1/80 font-semibold">
                {userDetail?.location}-{userDetail?.pin_code}
              </span>
            </div>

            <div className="w-full flex items-center justify-between relative p-2">
              <p className="text-[14px] text-black-1/70 font-semibold">
                Total Price
              </p>
              <hr className="absolute w-[5px] border-[1px] border-black-1/90 left-[50%] transform -translate-x-1/2" />
              <span className="text-[15px] text-black-1/80 font-semibold">
                {/* ₹{formatWithCommasWithDecimal(handleCurrentPrice(data))} */}
                <FormattedNumber value={handleCurrentPrice(data)}/>

              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartProductCard;
