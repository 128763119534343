import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getCollections,
  editCollection,
  createCollection,
  addProductInCollection,
  toggleCollection,
  removeProductFromCollection,
  fetchAllProductInventory,
  fetchAllCoupon,
  toggleCoupon,
  createCoupon,
  updateDeliveredStatus,
  fetchAlldealerProductSale,
  fetchAlldealerSKUSale,
  fetchAllProductAndSKU,
  toggleProductStatus,
  toggleSKUStatus,
  fetchAllBulkOrderUser,
  addUserForBulkOrder,
} from "./adminAPI";

const initialState = {
  collectionName: [],
  collectionData: [],
  collectionProduct: [],
  coupons: [],
  productSale: [],
  skuSale: [],
  productList: [],
  bulkOrderUser:[],
  collectionId: null,
  status: "idle",
};

// Async thunks for order-related actions
export const getCollectionsAsync = createAsyncThunk(
  "admin/getCollections",
  async () => {
    try {
      const response = await getCollections();
      return response?.data;
    } catch (error) {
      throw Error("Failed to get collection");
    }
  }
);

export const fetchAllProductInventoryAsync = createAsyncThunk(
  "admin/fetchAllProductInventory",
  async () => {
    try {
      const response = await fetchAllProductInventory();
      return response?.data;
    } catch (error) {
      throw Error("Failed to get collection");
    }
  }
);

export const removeProductFromCollectionAsync = createAsyncThunk(
  "admin/removeProductFromCollection",
  async (item_id) => {
    try {
      const response = await removeProductFromCollection(item_id);
      return response?.data;
    } catch (error) {
      throw Error("Failed to get collection");
    }
  }
);

export const toggleCollectionAsync = createAsyncThunk(
  "admin/toggleCollection",
  async (collection_id) => {
    try {
      const response = await toggleCollection(collection_id);
      return response?.data;
    } catch (error) {
      throw Error("Failed to get collection");
    }
  }
);

export const editCollectionAsync = createAsyncThunk(
  "admin/editCollection",
  async (collectionData) => {
    try {
      const response = await editCollection(collectionData);
      return response?.data;
    } catch (error) {
      throw Error("Failed to get inventory");
    }
  }
);

export const createCollectionAsync = createAsyncThunk(
  "admin/createCollection",
  async (collectionData) => {
    try {
      const response = await createCollection(collectionData);
      return response?.data;
    } catch (error) {
      throw Error("Failed to get all inventory");
    }
  }
);

export const addProductInCollectionAsync = createAsyncThunk(
  "admin/addProductInCollection",
  async (collectionData) => {
    try {
      const response = await addProductInCollection(collectionData);
      return response?.data;
    } catch (error) {
      throw Error("Failed to get all inventory");
    }
  }
);

export const fetchAllCouponAsync = createAsyncThunk(
  "admin/fetchAllCoupon",
  async () => {
    try {
      const response = await fetchAllCoupon();
      return response?.data;
    } catch (error) {
      throw Error("Failed to get Coupon");
    }
  }
);

export const toggleCouponAsync = createAsyncThunk(
  "admin/toggleCoupon",
  async (id) => {
    try {
      const response = await toggleCoupon(id);
      return response?.data;
    } catch (error) {
      throw Error("Failed to toggle coupon");
    }
  }
);

export const createCouponAsync = createAsyncThunk(
  "admin/createCoupon",
  async (couponData) => {
    try {
      const response = await createCoupon(couponData);
      return response?.data;
    } catch (error) {
      throw Error("Failed to create coupon");
    }
  }
);

export const updateDeliveredStatusAsync = createAsyncThunk(
  "admin/updateDeliveredStatus",
  async (updateData) => {
    try {
      const response = await updateDeliveredStatus(updateData);
      return response?.data;
    } catch (error) {
      throw Error("Failed to update delivered status");
    }
  }
);

export const fetchAlldealerProductSaleAsync = createAsyncThunk(
  "admin/fetchAlldealerProductSale",
  async () => {
    try {
      const response = await fetchAlldealerProductSale();
      return response?.data;
    } catch (error) {
      throw Error("Failed to get product sale");
    }
  }
);

export const fetchAlldealerSKUSaleAsync = createAsyncThunk(
  "admin/fetchAlldealerSKUSale",
  async () => {
    try {
      const response = await fetchAlldealerSKUSale();
      return response?.data;
    } catch (error) {
      throw Error("Failed to get sku sale");
    }
  }
);

export const fetchAllProductAndSKUAsync = createAsyncThunk(
  "admin/fetchAllProductAndSKU",
  async () => {
    try {
      const response = await fetchAllProductAndSKU();
      return response?.data;
    } catch (error) {
      throw Error("Failed to get product and sku");
    }
  }
);

export const toggleProductStatusAsync = createAsyncThunk(
  "admin/toggleProductStatus",
  async (prod_ID) => {
    try {
      const response = await toggleProductStatus(prod_ID);
      return response?.data;
    } catch (error) {
      throw Error("Failed to toggle product status");
    }
  }
);

export const toggleSKUStatusAsync = createAsyncThunk(
  "admin/toggleSKUStatus",
  async (SKU_ID) => {
    try {
      const response = await toggleSKUStatus(SKU_ID);
      return response?.data;
    } catch (error) {
      throw Error("Failed to toggle product status");
    }
  }
);

export const fetchAllBulkOrderUserAsync = createAsyncThunk(
  "admin/fetchAllBulkOrderUser",
  async () => {
    try {
      const response = await fetchAllBulkOrderUser();
      return response?.data;
    } catch (error) {
      throw Error("Failed to fetch bulk order user");
    }
  }
);

export const addUserForBulkOrderAsync = createAsyncThunk(
  "admin/addUserForBulkOrder",
  async (bulkData) => {
    try {
      const response = await addUserForBulkOrder(bulkData);
      return response?.data;
    } catch (error) {
      throw Error("Failed to fetch bulk order user");
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetCollectionId: (state) => {
      state.collectionId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollectionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCollectionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.collectionName = action.payload;
      })
      .addCase(fetchAllProductInventoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllProductInventoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.collectionProduct = action.payload;
      })
      .addCase(toggleCollectionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleCollectionAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(removeProductFromCollectionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeProductFromCollectionAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(createCollectionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCollectionAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.collectionId = action.payload;
      })
      .addCase(createCouponAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCouponAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.coupons = action.payload;
      })
      .addCase(fetchAllCouponAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCouponAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.coupons = action.payload;
      })
      .addCase(updateDeliveredStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateDeliveredStatusAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.coupons = action.payload;
      })
      .addCase(fetchAlldealerProductSaleAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAlldealerProductSaleAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productSale = action.payload;
      })
      .addCase(fetchAlldealerSKUSaleAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAlldealerSKUSaleAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.skuSale = action.payload;
      })
      .addCase(toggleCouponAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleCouponAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.coupons = action.payload;
      })
      .addCase(addProductInCollectionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addProductInCollectionAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(editCollectionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editCollectionAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(fetchAllProductAndSKUAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllProductAndSKUAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productList = action.payload;
      })
      .addCase(toggleProductStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleProductStatusAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(toggleSKUStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleSKUStatusAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(fetchAllBulkOrderUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllBulkOrderUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.bulkOrderUser = action.payload;
      })
      .addCase(addUserForBulkOrderAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addUserForBulkOrderAsync.fulfilled, (state, action) => {
        state.status = "idle";
      });
  },
});

export const selectCollections = (state) => state.admin.collectionName;
export const selectCollectionId = (state) => state.admin.collectionId;
export const selectAllCoupons = (state) => state.admin.coupons;
export const selectAllProductSale = (state) => state.admin.productSale;
export const selectAllSkuSale = (state) => state.admin.skuSale;
export const selectAllCollectionProduct = (state) =>
  state.admin.collectionProduct;

export const selectAllProductAndSKU = (state) => state.admin.productList;
export const selectAllBulkOrderUser = (state) => state.admin.bulkOrderUser;


export const { resetCollectionId } = adminSlice.actions;

export default adminSlice.reducer;
