import React, { useEffect, useState } from "react";
import "./AboutBrands.css";
import { useSelector } from "react-redux";
import { selectProductDetails } from "../../features/productDesc/productDescSlice";
import Funn from "./BrandsDetails/Funn";
import Cratoni from "./BrandsDetails/Cratoni";
import Moon from "./BrandsDetails/Moon";
import Granite from "./BrandsDetails/Granite";
import Proride from "./BrandsDetails/Proride";
import BikePro from "./BrandsDetails/BikePro";
import Dass from "./BrandsDetails/Dass";
import SovereignSaddles from "./BrandsDetails/SovereignSaddles";
import MasterChains from "./BrandsDetails/MasterChains";
import Katour from "./BrandsDetails/Katour";
import GarrariBrand from "./BrandsDetails/GarrariBrand";

function AboutBrands() {
  const productSelect = useSelector(selectProductDetails);
  const [componentToRender, setComponentToRender] = useState(null);
  useEffect(() => {
    if (
      productSelect &&
      productSelect.products &&
      productSelect.products.length > 0
    ) {
      const manufacturer = productSelect.products[0].Manufacturer;

      switch (manufacturer) {
        case "FUNN":
          setComponentToRender(<Funn />);
          break;
        case "CRATONI":
          setComponentToRender(<Cratoni />);
          break;
        case "GRANITE":
          setComponentToRender(<Granite />);
          break;
        case "MOON SPORTS":
          setComponentToRender(<Moon />);
          break;
        case "PRORIDE":
          setComponentToRender(<Proride />);
          break;
        case "BIKEPRO":
          setComponentToRender(<BikePro />);
          break;
        case "DASS":
          setComponentToRender(<Dass />);
          break;
        case "SOVEREIGN":
          setComponentToRender(<SovereignSaddles />);
          break;
        case "MASTER":
          setComponentToRender(<MasterChains />);
          break;
          case "KATOUR":
          setComponentToRender(<Katour />);
          break;
          case "GARRARI BIKES":
          setComponentToRender(<GarrariBrand />);
          break;
        default:
          setComponentToRender(null);
      }
    }
  }, [productSelect]);

  return (
    <div className="about_containers">
      {/* <h3 className="about_heading">About Brands</h3> */}
      {componentToRender}
    </div>
  );
}

export default AboutBrands;
