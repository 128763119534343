import React, { memo, useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Tabs,
  Tab,
  Switch,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  fetchAllProductAndSKUAsync,
  selectAllProductAndSKU,
  toggleProductStatusAsync,
  toggleSKUStatusAsync,
} from "../../features/admin/adminSlice";
import toast from "react-hot-toast";

const AdminProductToggle = memo(() => {
  const dispatch = useDispatch();
  const [skuList, setSKUList] = useState([]);
  const [productList, setProductList] = useState([]);
  const proList = useSelector(selectAllProductAndSKU);
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [filteredSKUList, setFilteredSKUList] = useState([]);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  useEffect(() => {
    if (proList) {
      setSKUList(proList?.SKU || []);
      setProductList(proList?.products || []);
      setFilteredSKUList(proList?.SKU || []);
      setFilteredProductList(proList?.products || []);
    }
  }, [proList]);
  const userDetail = useSelector(selectUser);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    handleOpen();
    dispatch(fetchAllProductAndSKUAsync()).then(() => {
      handleClose();
    });
  }, [dispatch]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearch(value);
    if (tab === 0) {
      setFilteredProductList(
        productList?.filter(
          (product) =>
            product?.prod_ID.toLowerCase().includes(value) ||
            product?.prod_name.toLowerCase().includes(value)
        )
      );
    } else {
      setFilteredSKUList(
        skuList?.filter(
          (sku) =>
            sku?.SKU_ID.toLowerCase().includes(value) ||
            sku?.prod_name.toLowerCase().includes(value)
        )
      );
    }
  };

  const handleStatusFilterChange = (e) => {
    const status = e?.target?.value;
    setStatusFilter(status);

    if (tab === 0) {
      setFilteredProductList(
        productList.filter(
          (product) =>
            (search === "" ||
              product?.prod_ID?.toLowerCase().includes(search) ||
              product?.prod_name?.toLowerCase().includes(search)) &&
            (status === "all" || product?.Active === status)
        )
      );
    } else {
      setFilteredSKUList(
        skuList.filter(
          (sku) =>
            (search === "" ||
              sku?.SKU_ID?.toLowerCase().includes(search) ||
              sku?.prod_name?.toLowerCase().includes(search)) &&
            (status === "all" || sku?.Active === status)
        )
      );
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleToggleProductStatus = async (prod_ID) => {
    handleOpen();
    try {
      await dispatch(toggleProductStatusAsync(prod_ID)).then(() => {
        dispatch(fetchAllProductAndSKUAsync());
        handleClose();
        toast.success("Product status updated successfully!");
      });
    } catch (error) {
      toast.error("Failed to update product status.");
    }
  };

  const handleToggleSKUStatus = async (SKU_ID) => {
    handleOpen();
    try {
      await dispatch(toggleSKUStatusAsync(SKU_ID)).then(() => {
        dispatch(fetchAllProductAndSKUAsync());
        handleClose();
        toast.success("SKU status updated successfully!");
      });
    } catch (error) {
      toast.error("Failed to update SKU status.");
    }
  };

  return (
    <div className="w-full min-h-screen bg-gray-50 relative flex flex-col">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="w-full h-14 bg-white-1 flex items-center justify-between sticky top-0 py-1 px-5
       shadow-md z-10 max-sm:hidden"
      >
        <span className="text-lg font-bold tracking-wider text-gray-800">
          Product Visibility
        </span>
        <img
          loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="w-10 h-10 rounded-full border border-gray-300"
        />
      </div>
      <>
        <div className="w-full max-md:px-2 max-md:py-3 px-4 md:px-6 lg:px-8 py-5 flex-grow">
          <Box sx={{ width: "100%", bgcolor: "" }}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              centered
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "var(--color-green)",
                },
                "& .MuiTab-root": { color: "gray" },
                "& .Mui-selected": { color: "var(--color-green) !important" },
              }}
            >
              <Tab label="Product" />
              <Tab label="SKU" />
            </Tabs>
            <Box sx={{ p: 2 }}>
              <div className="w-full flex max-sm:flex-col justify-between items-center max-sm:items-start mb-4">
                <TextField
                  sx={{
                    height: "50px",
                  }}
                  className="max-sm:mb-2 max-sm:w-full"
                  label="Search by ID or Name"
                  variant="outlined"
                  size="small"
                  value={search}
                  onChange={handleSearch}
                  required
                />
                <FormControl
                  sx={{ width: "200px", height: "50px" }}
                  className=" max-sm:w-full"
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={statusFilter}
                    onChange={handleStatusFilterChange}
                    label="Status"
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="true">Active</MenuItem>
                    <MenuItem value="false">Inactive</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {tab === 0 && (
                <div className="w-full overflow-x-auto pt-[100px] sm:px-5">
                  <h2 className="text-[16px] tracking-wide p-3 text-black-1/80 bg-white-1 rounded-t-md">
                    All Product
                  </h2>
                  <TableContainer component={Paper} className="shadow-md">
                    <Table>
                      <TableHead className="bg-gray-100">
                        <TableRow>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            S.No
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Product ID
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Product Name
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredProductList?.map((product, index) => (
                          <TableRow key={index} hover>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                              {product?.prod_ID}
                            </TableCell>
                            <TableCell align="center">
                              {product?.prod_name}
                            </TableCell>

                            <TableCell align="center">
                              <Switch
                                checked={product?.Active === "true"}
                                onChange={() =>
                                  handleToggleProductStatus(product?.prod_ID)
                                }
                                color="success"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
              {tab === 1 && (
                <div className="w-full overflow-x-auto pt-[100px] sm:px-5">
                  <h2 className="text-[16px] tracking-wide p-3 text-black-1/80 bg-white-1 rounded-t-md">
                    All SKU
                  </h2>
                  <TableContainer component={Paper} className="shadow-md">
                    <Table>
                      <TableHead className="bg-gray-100">
                        <TableRow>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            S.No
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            SKU ID
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Product Name
                          </TableCell>
                          <TableCell
                            align="center"
                            className="font-semibold"
                            sx={{
                              textAlign: "center",
                              fontWeight: "700",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredSKUList?.map((sku, index) => (
                          <TableRow key={index} hover>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{sku?.SKU_ID}</TableCell>
                            <TableCell align="center">
                              {sku?.prod_name}
                            </TableCell>

                            <TableCell align="center">
                              <Switch
                                checked={sku?.Active === "true"}
                                onChange={() =>
                                  handleToggleSKUStatus(sku?.SKU_ID)
                                }
                                color="success"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </Box>
          </Box>
        </div>
      </>
    </div>
  );
});

export default AdminProductToggle;
