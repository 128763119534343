import React, { useEffect, useState } from "react";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import FavShare from "./FavShare";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import { IMAGE_BASE } from "../../config";
import commingSoonLogo from "../../icons/badges/comming-soon.png";
import { selectUser } from "../../features/auth/authSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function ProductBasicDetail({ selectProduct }) {
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState();
  const userDetail = useSelector(selectUser);
  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );
  useEffect(() => {
    if (selectProduct && selectProduct.length > 0) {
      const firstProduct = selectProduct[0] || [];
      setSelectedProduct(firstProduct);
      dispatch(
        setBreadcrumb({
          verticalID: firstProduct.Vertical_ID,
          verticalName: firstProduct.verticalName,
          categoryID: firstProduct.CategoryID,
          categoryName: firstProduct.categoryName,
          subCategoryID: firstProduct.sc_id,
          subCategoryName: firstProduct.subcategoryName,
          productID: firstProduct.prod_ID,
          productName: firstProduct.prod_name,
        })
      );
    }
  }, [selectProduct, dispatch]);

  const fullStars = isNaN(selectedProduct?.net_rating)
    ? 0
    : Math.floor(selectedProduct?.net_rating && selectedProduct?.net_rating);
  const hasHalfStar = isNaN(selectedProduct?.net_rating)
    ? 0
    : selectedProduct?.net_rating % 1 !== 0;

  const formatedBrandName = selectedProduct?.Manufacturer?.toUpperCase();

  return (
    <>
      <div className="product-container">
        <div className="product-container-detail">
          {selectedProduct && selectedProduct?.manufacture_images ? (
            <LazyLoadImage
              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                selectedProduct && selectedProduct?.manufacture_images
              }`}
              alt=""
              className="product-container-detail-img"
              effect="blur"
              wrapperProps={{
                // If you need to, you can tweak the effect transition using the wrapper style.
                style: { transitionDelay: "1s" },
              }}
            />
          ) : (
            <p className="product-category">{formatedBrandName}</p>
          )}
          <span className="text-black-1 text-[18px] font-bold tracking-wide">
            {selectedVariant?.prod_name && (
              <span className="mr-1">
                {selectedVariant?.prod_name + ","}
              </span>
            )}
            {selectedVariant && selectedVariant?.Tech_Data !== null ? (
              <span>
                {selectedVariant?.Tech_Data}
              </span>
            ) : (
              selectedProduct &&
              selectedProduct?.product_Tech &&
              selectedProduct?.product_Tech?.map((tech, index) => (
                <span key={index}>
                  {tech}
                </span>
              ))
            )}
          </span>

          <div className="product-rating">
            {[...Array(fullStars)].map((_, index) => (
              <StarRoundedIcon key={index} />
            ))}
            {hasHalfStar && <StarHalfRoundedIcon />}
            {[...Array(5 - fullStars - (hasHalfStar ? 1 : 0))].map(
              (_, index) => (
                <StarOutlineRoundedIcon key={index} />
              )
            )}
            <p>({parseInt(selectedProduct?.net_rating).toFixed(1)})</p>
          </div>
          <div className="mobile-product-rating">
            <p>{parseInt(selectedProduct?.net_rating).toFixed(1)}</p>
            <StarRoundedIcon />
          </div>
        </div>
        <FavShare selectedProduct={selectedProduct} />
      </div>
    </>
  );
}
export default ProductBasicDetail;
