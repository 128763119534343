// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brands: [],
  materials: [],
  age: [],
  height: [],
  frameSize: [],
  frameMaterial: [],
  wheelSize: [],
  brakeType: [],
  shipping: "",
  rating: 0,
  dealerMargin: 0,
  shopByOffer: "",
  outOfStock: false,
  minPrice: 0,
  maxPrice: 0,
  sortPrice: "",
  sortRating: "",
};

const allFilterSlice = createSlice({
  name: "allFilter",
  initialState,
  reducers: {
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setMaterials: (state, action) => {
      state.materials = action.payload;
    },
    setAge: (state, action) => {
      state.age = action.payload;
    },
    setHeight: (state, action) => {
      state.height = action.payload;
    },
    setFrameSize: (state, action) => {
      state.frameSize = action.payload;
    },
    setFrameMaterial: (state, action) => {
      state.frameMaterial = action.payload;
    },
    setWheelSize: (state, action) => {
      state.wheelSize = action.payload;
    },
    setBrakeType: (state, action) => {
      state.brakeType = action.payload;
    },
    setRating: (state, action) => {
      state.rating = action.payload;
    },
    setDealerMargin: (state, action) => {
      state.dealerMargin = action.payload;
    },
    setShopByOffer: (state, action) => {
      state.shopByOffer = action.payload;
    },
    setOutOfStock: (state, action) => {
      state.outOfStock = action.payload;
    },
    setShipping: (state, action) => {
      state.shipping = action.payload;
    },
    setMinPrice: (state, action) => {
      state.minPrice = action.payload;
    },
    setMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
    },
    setSortPrice: (state, action) => {
      state.sortPrice = action.payload;
    },
    setSortRating: (state, action) => {
      state.sortRating = action.payload;
    },
  },
});

export const {
  setBrands,
  setMaterials,
  setFrameSize,
  setAge,
  setHeight,
  setFrameMaterial,
  setWheelSize,
  setBrakeType,
  setRating,
  setDealerMargin,
  setShopByOffer,
  setOutOfStock,
  setShipping,
  setMinPrice,
  setMaxPrice,
  setSortPrice,
  setSortRating,
} = allFilterSlice.actions;
export const selectAllFilter = (state) => state.allFilter;
export const selectOutOfStock = (state) => state.allFilter.outOfStock;

export default allFilterSlice.reducer;
