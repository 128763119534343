import { API_BASE_URL } from "../../config";

export function fetchBulkOrderData() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/bulkOrder`);
    const data = await response.json();

    resolve({ data });
  });
}

export function getBulkOrderUserOTP(phoneNumber) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}bulkOrder_user/login/${phoneNumber}`
    );
    const data = await response.json();

    resolve({ data });
  });
}

export function verifyBulkOrderOTP(otpData) {
  const PhoneNumber = otpData?.PhoneNumber;
  const OTP = otpData?.OTP;
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}bulkOrder_user/verify_otp/${PhoneNumber}/${OTP}`
    );
    const data = await response.json();

    resolve({ data });
  });
}
