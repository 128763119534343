import React, { useState } from "react";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {
  Badge,
  Button,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Table,
  Box,
  Modal,
} from "@mui/material";
import { formatWithCommasWithoutDecimal } from "../../utils/utils";

const BulkOrderHeader = ({ setStep,bulkData }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Sample data for the table
  const discountData = [
    {
      brand: "All BikePro",
      brandLogo:
        "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_MANUFACTURE_IMAGE/DASS.png",
      discountSlab: "5% for orders above 10 units",
      addedAmount: 32000,
      minAmount: 75000,
      discountAdded: 83000,
      discountMin: 200000,
    },
    {
      brand: "Sovereign",
      brandLogo:
        "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_MANUFACTURE_IMAGE/DASS.png",
      discountSlab: "7% for orders above 20 units",
      addedAmount: 32000,
      minAmount: 75000,
      discountAdded: 83000,
      discountMin: 200000,
    },
    {
      brand: "Moon Master",
      brandLogo:
        "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_MANUFACTURE_IMAGE/DASS.png",
      discountSlab: "10% for orders above 50 units",
      addedAmount: 32000,
      minAmount: 75000,
      discountAdded: 83000,
      discountMin: 200000,
    },
    {
      brand: "Proride",
      brandLogo:
        "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_MANUFACTURE_IMAGE/DASS.png",
      discountSlab: "12% for orders above 100 units",
      addedAmount: 32000,
      minAmount: 75000,
      discountAdded: 83000,
      discountMin: 200000,
    },
    {
      brand: "Moon Master",
      brandLogo:
        "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_MANUFACTURE_IMAGE/DASS.png",
      discountSlab: "10% for orders above 50 units",
      addedAmount: 32000,
      minAmount: 75000,
      discountAdded: 83000,
      discountMin: 200000,
    },
    {
      brand: "Proride",
      brandLogo:
        "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_MANUFACTURE_IMAGE/DASS.png",
      discountSlab: "12% for orders above 100 units",
      addedAmount: 32000,
      minAmount: 75000,
      discountAdded: 83000,
      discountMin: 200000,
    },
  ];

  return (
    <div className="relative w-full">
      {/* Header */}
      <div className="w-full flex items-center justify-between bg-[#e3e3e3] py-[10px] px-8">
        {/* Logo */}
        <img
          src="https://beta.cyclecircle.one/static/media/Cycle-Circle-Logo-Orange-1.18b0a8525b53bce0ebd4.png"
          alt="website-logo"
          className="h-[50px] object-contain"
        />

        <div className="flex items-center">
          <Button
            variant="contained"
            onClick={handleOpen}
            style={{
              background: "linear-gradient(to bottom, #495f98, #283248)",
              color: "#fff",
              padding: "10px 20px",
              borderRadius: "25px",
              fontWeight: "bold",
              height: "35px",
              fontSize: "14px",
              textTransform: "capitalize",
              letterSpacing: "0.5px",
              marginRight: "30px",
            }}
          >
            View My Discount
          </Button>

          {/* Modal */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="slab-discount-modal"
            aria-describedby="slab-discount-details"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "75%",
                maxWidth: "650px",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: "10px",
                p: "20px",
              }}
            >
              <h2
                id="slab-discount-modal"
                className="text-lg text-black-1 font-bold mb-4"
              >
                Slab Discount
              </h2>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: "#283248",
                      }}
                    >
                      <TableCell
                        align="center"
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          borderTopLeftRadius: "10px",
                          padding: "12px",
                        }}
                      >
                        Brands
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          padding: "12px",
                        }}
                      >
                        MOQ Slab
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          borderTopRightRadius: "10px",
                          padding: "12px",
                        }}
                      >
                        Additional Discount Slab
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {discountData.map((row, index) => (
                      <TableRow key={index}>
                        {/* Brands */}
                        <TableCell
                          align="center"
                          sx={{
                            padding: "10px",
                          }}
                        >
                          <img
                            src={row.brandLogo}
                            alt={row.brand}
                            style={{
                              width: "100%",
                              height: "35px",
                              objectFit: "contain",
                            }}
                          />
                        </TableCell>

                        {/* MOQ Slab */}
                        <TableCell
                          align="start"
                          sx={{
                            padding: "10px",
                          }}
                        >
                          <div className="bg-green-100 rounded-md p-2">
                            <span className="font-bold text-sm">
                              ₹{formatWithCommasWithoutDecimal(row.addedAmount)} / ₹
                              {formatWithCommasWithoutDecimal(row.minAmount)}
                            </span>
                            <div
                              style={{
                                height: "3px",
                                backgroundColor: "white",
                                borderRadius: "5px",
                                marginTop: "5px",
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  width: `${Math.min(
                                    (row.addedAmount / row.minAmount) * 100,
                                    100
                                  )}%`,
                                  height: "100%",
                                  backgroundColor: "green",
                                }}
                              ></div>
                            </div>
                            <span className="text-xs text-gray-600">
                              Add more items to place order
                            </span>
                          </div>
                        </TableCell>

                        {/* Additional Discount Slab */}
                        <TableCell
                          align="start"
                          sx={{
                            padding: "10px",
                          }}
                        >
                          <div className="bg-green-100 rounded-md p-2">
                            <span className="font-bold text-sm">
                              ₹{formatWithCommasWithoutDecimal(row.discountAdded)} / ₹
                              {formatWithCommasWithoutDecimal(row.discountMin)}
                            </span>
                            <div
                              style={{
                                height: "3px",
                                backgroundColor: "white",
                                borderRadius: "5px",
                                marginTop: "5px",
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  width: `${Math.min(
                                    (row.discountAdded / row.discountMin) * 100,
                                    100
                                  )}%`,
                                  height: "100%",
                                  backgroundColor: "green",
                                }}
                              ></div>
                            </div>
                            <span className="text-xs text-gray-600">
                              Add more items to get discount
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Pagination Section */}
              <div className="flex justify-center items-center mt-4">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    marginRight:"15px"
                  }}
                  // onClick={() => handlePagination("previous")}
                >
                  Previous
                </Button>
                <span className="text-[14px] font-medium text-white-1 bg-blue rounded-sm w-[25px] h-[25px] flex items-center justify-center">1</span>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    marginLeft:"15px"
                  }}
                  // onClick={() => handlePagination("next")}
                >
                  Next
                </Button>
              </div>
            </Box>
          </Modal>

          <Badge
            badgeContent={1}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "var(--color-green)",
                color: "#fff",
                fontSize: "12px",
              },
            }}
          >
            <ShoppingCartOutlinedIcon
              className="text-blue"
              style={{ fontSize: "28px" }}
            />
          </Badge>
        </div>
      </div>

      {/* Bottom Fixed Bar */}
      <div
        className="fixed bottom-0 h-[65px] w-full bg-blue border-t border-gray-200 flex justify-between items-center px-8 py-3 z-10"
        style={{ boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)" }}
      >
        {/* Net Total */}
        <div className="text-lg font-bold text-white-1">Net Total: ₹12,500</div>

        {/* View My Cart Button */}
        <Button
          variant="contained"
          onClick={() => setStep(1)}
          style={{
            backgroundColor: "var(--color-green)",
            color: "white",
            fontSize: "16px",
            padding: "10px 20px",
            textTransform: "none",
            height: "35px",
          }}
        >
          View My Cart
        </Button>
      </div>
    </div>
  );
};

export default BulkOrderHeader;
