import React, { useState } from "react";
import { useNavigate } from "react-router";
import HoverSlider from "../components/ListingPage/ListingProduct/HoverSlider";
import StarIcon from "@mui/icons-material/Star";
import cartOutlineLogo from "../img/navigation/cart-bookmark-outline.png";
import cartFilledLogo from "../img/navigation/cart-bookmark-filled.png";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {
  handleAddToWishlist,
  handleOpenMobileVarient,
  handleRemoveFromWishlist,
  isInWishlist,
} from "../utils/dispatch";
import { connect, useDispatch } from "react-redux";
import { IMAGE_BASE } from "../config";
import {
  calculateUpdatedDiscountPercentage,
  formatWithCommasWithDecimal,
} from "../utils/utils";
import {
  selectIsOverlayVisible,
  toggleOverlay,
} from "../store/slices/headerSlice";
import AuthenticationPage from "../components/Authentication/AuthenticationPage";
import VariantSelection from "./VariantSelection";

const ProductCard = ({
  data,
  userDetail,
  wishlist,
  handleOpen,
  handleClose,
  toggleOverlay,
  isOverlayVisible,
  qtys,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openMobileVarient, setOpenMobileVarient] = useState(null);

  const handleNavigate = (prod_id, event) => {
    event.preventDefault();
    navigate(`/product/${prod_id}`);
  };

  const handleLoginOverlay = (e) => {
    toggleOverlay();
    e.preventDefault();
  };

  return (
    <>
      <div
        className="w-full flex flex-col items-start justify-start bg-white-1 my-[6px] mx-0 px-3 shadow-sm shadow-black-1/30"
        key={data?.prod_ID}
      >
        <div className="w-full h-[165px] flex items-start justify-start">
          <div
            className="w-[38%] h-full relative"
            onClick={(event) => handleNavigate(data.prod_ID, event)}
          >
            {/* {userDetail?.UserID === 1 && (
              <span className="absolute top-[1px] -left-3 z-1 p-0 text-12 tracking-wider">
                {data?.prod_ID?.startsWith("9") ? (
                  <span className="text-white-1 bg-peach p-[3px]">
                    CC EXCLUSIVE
                  </span>
                ) : (
                  <span className="text-white-1 bg-green p-[3px]">
                    CC INCLUSIVE
                  </span>
                )}
              </span>
            )} */}
            <HoverSlider images={data?.images && data.images} />
            {/* <div className="flex items-center justify-between bg-gray-100 px-4 py-[2px] rounded-sm font-bold shadow-lg absolute bottom-[10px] left-[15px]">
              <span className="text-14 font-medium text-black-2 flex items-center">
                {parseInt(data.net_rating).toFixed(1)}
                <StarIcon
                  className="ml-1 text-[#daa520]"
                  style={{ fontSize: "18px" }}
                />
              </span>
              <span className="text-14 text-black-1 mx-[2px] font-medium">
                |
              </span>
              <span className="text-14 ml-2 font-medium text-black-2">313</span>
            </div> */}
          </div>
          <div className="w-[62%] h-full flex flex-col items-start justify-between ml-4 relative pt-3">
            <div className="absolute top-[10px] right-[10px]">
              {isInWishlist(data.prod_ID, wishlist) ? (
                <img
                  loading="lazy"
                  src={cartFilledLogo}
                  alt="wishlist-logo"
                  onClick={(e) => {
                    handleRemoveFromWishlist(
                      data,
                      userDetail,
                      dispatch,
                      handleOpen,
                      handleClose,
                      e
                    );
                  }}
                  className="w-[25px]"
                />
              ) : (
                <img
                  loading="lazy"
                  src={cartOutlineLogo}
                  alt="wishlist-logo"
                  onClick={(e) => {
                    userDetail && userDetail?.UserID
                      ? handleAddToWishlist(
                          data,
                          userDetail,
                          dispatch,
                          handleOpen,
                          handleClose,
                          e
                        )
                      : handleLoginOverlay(e);
                  }}
                  className="w-[25px]"
                />
              )}
            </div>
            <div className="w-[50%] h-[22px]">
              {data && data?.manufacture_images ? (
                <img
                  loading="lazy"
                  className="max-w-[120px] h-full object-contain"
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                    data && data?.manufacture_images
                  }`}
                  alt="brand-logo"
                />
              ) : (
                <span className="text-16 italic font-bold">
                  {data.manufacture_name && data.manufacture_name}
                </span>
              )}
            </div>
            <div className="w-full h-[55px] text-start mt-1">
              <span className="text-14 text-black-2 font-bold tracking-wide line-clamp-2">
                {data && (
                  <>
                    {data?.prod_name + ", " || ""}
                    {data?.product_Tech?.map((tech) => tech).join(" ")}
                  </>
                )}
              </span>
            </div>
            {data &&
              data?.skuDetails &&
              data?.skuDetails?.length > 0 &&
              data?.skuDetails[0]?.NDP_Price && (
                <div className="w-full h-[28px] flex items-start">
                  <span className="text-14 font-semibold text-blue flex items-center whitespace-nowrap">
                    Dealer Price-
                    {Object.keys(userDetail).length > 0 ? (
                      <b
                        style={{
                          textDecoration:
                            data?.skuDetails[0].Offer_price !== null &&
                            "line-through",
                          fontSize:
                            data?.skuDetails[0].Offer_price !== null && "3vw",
                          marginLeft: "0.5vw",
                        }}
                      >
                        ₹
                        {formatWithCommasWithDecimal(
                          parseInt(data.skuDetails[0].NDP_Price)
                        )}
                      </b>
                    ) : (
                      <b
                        className="bg-green rounded-sm px-2 py-1 text-[10px] tracking-wider text-white-1 uppercase ml-1"
                        onClick={toggleOverlay}
                      >
                        Login to see Price
                      </b>
                    )}
                  </span>
                  {Object.keys(userDetail).length > 0 &&
                    data?.skuDetails[0].Offer_price !== null && (
                      <span className="text-14 text-green ml-2 font-semibold">
                        ₹
                        {formatWithCommasWithDecimal(
                          parseInt(data.skuDetails[0].Offer_price)
                        )}
                      </span>
                    )}
                </div>
              )}
            {data &&
              data?.skuDetails &&
              data?.skuDetails?.length > 0 &&
              data?.skuDetails[0]?.MRP_price && (
                <div className="w-full h-[28px] flex items-start">
                  <span className="text-14 font-semibold text-blue">
                    Product MRP- ₹
                    {formatWithCommasWithDecimal(
                      parseInt(
                        data?.skuDetails[0] && data?.skuDetails[0].MRP_price
                      )
                    )}
                  </span>
                </div>
              )}
            <div className="w-full h-[28px] flex items-center justify-start text-peach capitalize tracking-wider text-14">
              {Object.keys(userDetail)?.length > 0 ? (
                <div className="flex-center">
                  <LocalOfferOutlinedIcon
                    style={{ fontSize: "18px", marginRight: "4px" }}
                  />{" "}
                  <span
                    className="text-14"
                    style={{
                      textDecoration:
                        data?.skuDetails[0]?.Offer_price !== null &&
                        "line-through",
                      fontSize:
                        data?.skuDetails[0]?.Offer_price !== null && "3vw",
                      margin: "0 0.8vw",
                    }}
                  >
                    {" "}
                    {calculateUpdatedDiscountPercentage(
                      data?.skuDetails[0]?.MRP_price,
                      parseInt(data?.skuDetails[0]?.NDP_Price),
                      data?.GST
                    )}
                    %
                  </span>{" "}
                  {data?.skuDetails[0]?.Offer_price !== null && (
                    <span className="flex items-center text-green mr-2">
                      {calculateUpdatedDiscountPercentage(
                        data?.skuDetails[0]?.MRP_price,
                        parseInt(data.skuDetails[0]?.Offer_price),
                        data?.GST
                      )}
                      %
                    </span>
                  )}{" "}
                </div>
              ) : (
                <div className="flex items-center">
                  <VisibilityOffOutlinedIcon
                    style={{ fontSize: "18px" }}
                    className="mr-1"
                    onClick={toggleOverlay}
                  />{" "}
                  <span>%</span>
                </div>
              )}{" "}
              Dealer Margin
            </div>
          </div>
        </div>
        <div className="w-full flex items-center justify-between py-2">
          <div
            className="w-[48%] h-[40px] rounded-[5px] border-green border-2 flex-center cursor-pointer"
            onClick={(e) => {
              userDetail && userDetail?.UserID
                ? handleOpenMobileVarient(data?.prod_ID, setOpenMobileVarient, e)
                : handleLoginOverlay(e);
            }}
          >
            <ShoppingCartOutlinedIcon
              style={{
                color: "var(--color-green)",
                fontSize: "20px",
                marginRight: "4px",
              }}
            />
            <span className="text-14 text-green font-bold">Add to cart</span>
          </div>
          <div
            className="w-[48%] h-[40px] rounded-[5px] bg-blue flex-center"
            onClick={(e) => {
              userDetail && userDetail?.UserID
                ? handleOpenMobileVarient(data?.prod_ID, setOpenMobileVarient, e)
                : handleLoginOverlay(e);
            }}
          >
            <span className="text-white-1 font-bold text-14">Buy Now</span>
          </div>
        </div>
      </div>
      {openMobileVarient !== null && (
        <VariantSelection
          openMobileVarient={openMobileVarient}
          setOpenMobileVarient={setOpenMobileVarient}
          product={data}
          qtys={qtys}
        />
      )}
      {isOverlayVisible && (
        <div className="overlay-container">
          <AuthenticationPage />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
