import React, { useEffect } from "react";
import { IMAGE_BASE } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCartsAsync,
  selectAllCarts,
  removeFromCartsAsync,
} from "../../features/cartPage/cartPageSlice";
import { selectUser } from "../../features/auth/authSlice";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useNavigate } from "react-router";
import {
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import { Button } from "@mui/material";
function MiniCart() {
  const userDetail = useSelector(selectUser);
  const dispatch = useDispatch();
  const carts = useSelector(selectAllCarts);
  const navigate = useNavigate();

  const handleCartNavigate = () => {
    navigate("/carts");
  };
  useEffect(() => {
    if (userDetail && userDetail?.UserID) {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);

  const handleRemoveFromCarts = (data) => {
    let cartData = {
      userID: userDetail?.UserID,
      SKU: data?.skuDetails?.SKU_ID,
    };
    dispatch(removeFromCartsAsync(cartData)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
    });
  };

  return (
    <>
      {carts && carts.length > 0 && (
        <div className="w-[250px] h-auto absolute top-full right-0 bg-white-1 rounded-md p-2 text-black-1 shadow-md shadow-black-1/20">
          {carts &&
            carts?.length > 0 &&
            carts?.slice(0, 2).map((data, index) => (
              <div
                className="w-full h-[100px] flex items-center relative bg-gray-100 mb-2 rounded-md p-2"
                key={index}
              >
                <CloseSharpIcon
                  className="absolute right-1 top-1 text-black-1/80"
                  style={{ fontSize: "16px" }}
                  onClick={() => handleRemoveFromCarts(data)}
                />
                <img
                  loading="lazy"
                  className="w-[30%] h-full bg-white-1 rounded-md object-contain"
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                    data?.images && data?.images
                  }`}
                  alt=""
                />
                <div className="w-[70%] h-full flex flex-col items-start justify-start ml-2">
                  {data && data?.skuDetails?.manufacture_images ? (
                    <img
                      loading="lazy"
                      className="w-auto h-[16px] object-contain mb-1"
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                        data && data?.skuDetails?.manufacture_images
                      }`}
                      alt=""
                    />
                  ) : (
                    <h3 className="text-[12px] text-black-1/90 font-semibold">
                      {data?.skuDetails?.manufacture_name &&
                        data?.skuDetails?.manufacture_name}
                    </h3>
                  )}
                  <span className="text-[11px] text-black-1/90 font-bold mb-2 tracking-wide">
                    {data?.skuDetails?.prod_name?.slice(0, 40)}
                  </span>
                  <div className="w-full flex items-center justify-between text-[12px]">
                    <span className="text-black-1/80 tracking-wide">
                      Qty: <b>{data?.buy_quantity}</b>
                    </span>
                    <span className="text-black-1/90 tracking-wide">
                      ₹
                      {formatWithCommasWithDecimal(
                        data?.skuDetails?.Offer_price
                          ? parseInt(data?.skuDetails?.Offer_price)
                          : parseInt(data?.skuDetails?.NDP_Price)
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          <Button
            variant="contained"
            sx={{
              width: "100%",
              height: "35px",
              background: "#283248",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "500",
              letterSpacing: "1px",
              color: "white",
              borderRadius: "4px",
              "&:hover": {
                background: "#283248",
              },
            }}
            onClick={handleCartNavigate}
          >
            View Cart
          </Button>
        </div>
      )}
    </>
  );
}

export default MiniCart;
