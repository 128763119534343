import React from "react";
import "./Katour.css";

const Katour = () => {
  return (
    <div className="about-katour-container">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        A Trusted Name in the Bicycle Industry
      </h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        Katour is a trusted name in the bicycle industry, operating from the
        vibrant manufacturing hub of Ludhiana. Renowned as both a manufacturer
        and importer of premium bicycle parts, Katour has established itself as
        the Original Equipment Manufacturer (OEM) for several leading bicycle
        brands in India.
      </p>
      <p className="text-gray-700 leading-relaxed mb-4">
        With a focus on quality and reliability, Katour specializes in bicycle
        spare parts, accessories, and complete bicycles, catering to a wide
        spectrum of consumer and business needs. Their extensive experience,
        spanning decades, underscores their commitment to excellence and
        innovation in the industry.
      </p>
      <p className="text-gray-700 leading-relaxed mb-4">
        Katour has forged strategic partnerships with globally recognized
        component manufacturers to bring high-quality and cost-effective generic
        bicycle parts to the Indian market. This collaboration ensures a
        seamless supply chain and access to cutting-edge technology, further
        reinforcing their position as a leader in the bicycle ecosystem.
      </p>
      <p className="text-gray-700 leading-relaxed">
        Driven by a passion for delivering exceptional service and dependable
        products, Katour continues to set benchmarks for quality and performance
        in the cycling industry. Whether it's components or complete bicycles,
        Katour remains a trusted partner for businesses and cycling enthusiasts
        alike.
      </p>
    </div>
  );
};

export default Katour;
