import { API_BASE_URL } from "../../config";
export function fetchPrductDetails(prod_ID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}product/${prod_ID}`
    );
    const data = await response.json();
    resolve({ data });
  });
}

export function fetchDealersPrductDetails(prod_ID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}dealer/product/${prod_ID}`
    );
    const data = await response.json();
    resolve({ data });
  });
}

export function fetchProductComparision(prod_ID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}comparisons/${prod_ID}`
    );
    const data = await response.json();
    resolve({ data });
  });
}

export function fetchProductDescriptionDetail(prod_ID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}fetch_dealer_product_description/${prod_ID}`
    );
    const data = await response.json();
    resolve({ data });
  });
}

export function fetchKeySpecificationForBikes(prod_ID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}fetch_special/${prod_ID}`
    );
    const data = await response.json();
    resolve({ data });
  });
}