import React, { useEffect, useState } from "react";
import BulkOrderTable from "./BulkOrderTable";
import BulkOrderNavigation from "./BulkOrderNavigation";
import BulkOrderHeader from "./BulkOrderHeader";
import BulkOrderCart from "./BulkOrderCart";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBulkOrderDataAsync,
  selectBulkOrderData,
} from "../../features/bulkOrder/bulkOrderSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const BulkOrders = () => {
  const dispatch = useDispatch();
  const bulkData = useSelector(selectBulkOrderData);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("All");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  console.log(bulkData);
  const [step, setStep] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    handleOpen();
    dispatch(fetchBulkOrderDataAsync())?.then(() => {
      handleClose();
    });
  }, [dispatch]);


  useEffect(() => {
    // If selectedFilters are not empty, filter products using selectedFilters only
    let filterData = [];
    console.log(selectedFilters)
  
    if (selectedFilters?.length > 0) {
      filterData = bulkData?.product?.filter((product) => {
        // Check if product's sc_id is in the selectedFilters
        return selectedFilters.some((filter) => filter.sc_id === product?.sc_id);
      });
    } else {
      // Otherwise, apply searchQuery and selectedBrand filtering
      filterData = bulkData?.product?.filter((product) => {
        const matchesSearch = product?.prod_name
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());
        const matchesBrand =
          selectedBrand === "All" || product?.manufacture_name === selectedBrand;
        return matchesSearch && matchesBrand;
      });
    }
  
    setFilteredProducts(filterData); // Update the filtered products based on the condition
  }, [bulkData?.product, searchQuery, selectedBrand, selectedFilters]);
  
  return (
    <>
      {/* Conditional Rendering */}
      {step === 0 ? (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div
            style={{
              position: "sticky",
              top: 0,
              zIndex: 10,
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            <BulkOrderHeader setStep={setStep} bulkData={bulkData} />
          </div>
          <BulkOrderNavigation
            bulkData={bulkData}
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
          <BulkOrderTable
            filteredProducts={filteredProducts}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </>
      ) : (
        <BulkOrderCart step={step} setStep={setStep} />
      )}
    </>
  );
};

export default BulkOrders;
