import React, { useState } from "react";
import exploreVideo from "../../../assets/highlight-garrari1.mp4";
import { useRef } from "react";
import { useNavigate } from "react-router";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import garrariLogo from "../../../assets/garrari-bikes-icon-1.jpg";
import tick from "../../../assets/Tick.png";
import manufacture from "../../../assets/Manufacture.png";
import check from "../../../assets/Check.png";
import isoIcon from "../../../assets/ISO.png";
import garrariImage1 from "../../../assets/garrari-brand1.jpg";

const GarrariBrand = () => {
  const videoRef = useRef(null);
  const [carouselItems] = useState([
    {
      type: "image",
      src: garrariImage1,
      alt: "Production Line",
    },
    {
      type: "video",
      src: exploreVideo,
      alt: "Product Video",
    },
  ]);

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(`/listing-page/${"garrari bikes"}`);
  };

  const handleCarouselChange = (index) => {
    // Pause the video if it is currently playing
    if (videoRef.current) {
      videoRef.current.pause();
    }

    // Check if the current slide is a video and play it
    if (carouselItems[index].type === "video" && videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <div className="w-full h-auto md:h-[440px] flex flex-wrap bg-white rounded-lg overflow-hidden">
      {/* Left Section */}
      <div className="w-full h-full md:w-1/2 p-6 rounded-lg shadow-md bg-gradient-to-tr from-[#17a2b8]/20 to-[#17a2b8]/10">
        <div className="flex items-center">
          <img src={garrariLogo} alt="Garrari Logo" className="w-20" />
          <div className="pl-3">
            <h2 className="text-lg font-bold text-gray-800 flex items-center">
              Garrari Bikes
              <span className="text-green-500 text-sm ml-2 flex items-center">
                Verified
                <img src={check} alt="tick" className="w-3 ml-1 mb-2 inline-block" />
              </span>
            </h2>
            <p className="text-sm text-gray-600 mt-1">
              Custom manufacturer <span className="px-1">•</span> 1yr+
              <span className="px-1">•</span> Located in Punjab
            </p>
          </div>
        </div>
        <div className="w-full mt-4 flex justify-between border-b border-gray-300 pb-2">
          <div className="text-center">
            <h3 className="text-sm font-bold text-gray-700">
              On-time delivery:
            </h3>
            <p className="text-xs text-gray-600">100.0%</p>
          </div>
          <div className="text-center">
            <h3 className="text-sm font-bold text-gray-700">Response Time:</h3>
            <p className="text-xs text-gray-600">&lt;1h</p>
          </div>
          <div className="text-center">
            <h3 className="text-sm font-bold text-gray-700">
              Products Manufactured:
            </h3>
            <p className="text-xs text-gray-600">100+</p>
          </div>
        </div>

        <div className="w-full pb-2 border-b border-black-1/20 mt-4">
          <h2 className="font-bold text-[14px] text-gray-700">
            Products we build
          </h2>
          <p className="text-gray-600 flex items-center text-[12px] mt-2">
            <b className="text-[20px] text-gray-600 pr-1 pl-2">•</b> MTB{" "}
            <b className="text-[20px] text-gray-600 pr-1 pl-10">•</b> Kids{" "}
            <b className="text-[20px] text-gray-600 pr-1 pl-10">•</b> Roadster
          </p>
        </div>

        <div className="w-full pb-2 mt-4">
          <h2 className="font-bold text-[14px] text-gray-700">
            Order with confidence
          </h2>
          <div className="w-full mt-2 flex flex-col items-start text-gray-600">
            <div className="flex items-center my-1">
              <img
                className="w-[20px] h-[20px] rounded-full bg-white-1"
                src={isoIcon}
                alt=""
              />
              <span className="text-[12px] text-gray-700 ml-1">
                ISO 9001 Certified
              </span>
            </div>
            <div className="flex items-center my-1">
              <img
                className="w-[20px] h-[20px] rounded-full bg-white-1"
                src={manufacture}
                alt=""
              />
              <span className="text-[12px] text-gray-700 ml-1">
                Verified as custom manufacturer
              </span>
            </div>
            <div className="flex items-center my-1">
              <img
                className="w-[20px] h-[20px] rounded-full bg-white-1"
                src={tick}
                alt=""
              />
              <span className="text-[12px] text-gray-700 ml-1">
                Recognized as Verified Supplier
              </span>
            </div>
          </div>
        </div>
        <div className="mt-6 w-full flex items-center justify-center">
          <button
            onClick={handleNavigation}
            className=" w-fit text-black-1 text-[12px] font-semibold py-2 px-3 border border-black-1/20 rounded-2xl bg-white-1 shadow-md hover:bg-green hover:text-white-1"
          >
            View our products
          </button>
        </div>
      </div>

      {/* Right Section (Carousel) */}
      <div className="w-full h-full md:w-1/2 max-md:mt-3 md:pl-4 rounded-lg shadow-md">
        <Carousel
          showArrows={true}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          stopOnHover={true}
          dynamicHeight={false}
          onChange={handleCarouselChange}
        >
          {carouselItems.map((item, index) =>
            item.type === "image" ? (
              <div key={index} className="w-full h-full rounded-lg">
                <img
                  src={item.src}
                  alt={item.alt}
                  className="rounded-lg w-full h-full object-cover"
                />
              </div>
            ) : (
              <div key={index} className="w-full h-full rounded-lg">
                <video
                  ref={videoRef}
                  muted
                  className="rounded-lg w-full h-full object-cover"
                >
                  <source src={item.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )
          )}
        </Carousel>
      </div>
    </div>
  );
};

export default GarrariBrand;
